<template>
  <v-container style="max-width: 80%">
    <div v-if="sessionActive && hasContacted">
      <Error
        :pageError="pageError"
        v-if="pageError"
        @on-dismiss="pageError = null"
      ></Error>

      <Headline
        class="mt-10"
        :title="$t('student', 2)"
        color="dark-gray"
        iconName="mdi-account-multiple-outline"
      >
        <v-spacer></v-spacer>
        <AutocompleteStudents
          class="mb-1"
          :placeholderName="$t('student', 1) + ' ' + $t('name')"
          :placeholderEmail="$t('student', 1) + ' ' + $t('email')"
          @select="navigateToStudent($event.primaryEmail, $event.id)"
          style="width: 75%"
        />
      </Headline>
      <v-progress-linear
        v-if="loadingStudents || loadingBookmark"
        indeterminate
        color="primary"
        height="2px"
      ></v-progress-linear>

      <v-list bg-color="background" width="fit-content" v-if="students.length">
        <v-list-item
          lines="two"
          v-for="(student, index) in students"
          :key="index"
          :value="student"
        >
          <template v-slot:prepend>
            <v-avatar>
              <v-icon size="40" color="secondary"> mdi-account-circle </v-icon>
            </v-avatar>
          </template>

          <a>
            <v-list-item-title
              @click="navigateToStudent(student.email, student.student_id)"
              class="fontcls"
            >
              {{ student.name }}</v-list-item-title
            >
            <v-list-item-subtitle>{{ student.email }}</v-list-item-subtitle>
          </a>

          <template v-slot:append>
            <v-list-item-action>
              <v-btn
                icon
                size="small"
                variant="outlined"
                style="border: None"
                :title="$t('remove_bookmark')"
                :disabled="loadingBookmark"
                @click="removeBookMark(student.student_id, index)"
              >
                <v-icon color="primary" size="x-large"
                  >mdi-bookmark-remove</v-icon
                >
              </v-btn>
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list>

      <div v-if="!students.length" class="mt-3">
        <span class="fontcls"><span v-t="'no_data_found'"></span></span>
      </div>

      <div class="d-flex justify-center">
        <LoadMore
          v-if="nextPageToken"
          :loading="loadingStudents"
          @next-page="getStudents"
        ></LoadMore>
      </div>
    </div>

    <Freemium
      v-else-if="sessionActive && !hasContacted"
      images="/supporteducator.gif"
      :title="$t('support_educator')"
      :bodyText="$t('support_edu_freemium_text')"
    ></Freemium>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import studentsAPI from "@/api/students";

import AutocompleteStudents from "@/components/autocompleteStudents";
import Headline from "@/components/headline";
import Error from "@/components/error.vue";
import LoadMore from "@/components/loadMoreButton";
import Freemium from "@/components/freemium.vue";

export default {
  middleware: "authenticated-access",
  components: {
    Headline,
    AutocompleteStudents,
    Error,
    LoadMore,
    Freemium,
  },

  data() {
    return {
      loadingStudents: false,
      students: [],
      nextPageToken: null,
      pageError: null,
      loadingBookmark: false,
    };
  },

  computed: {
    ...mapGetters({
      sessionActive: "sessionActive",
      hasContacted: "hasContacted",
    }),
  },

  methods: {
    ...mapActions({
      handleUnauthorisedError: "handleUnauthorisedError",
      signOut: "signOut",
    }),

    async getAllStudents() {
      this.loadingStudents = true;
      this.pageError = null;
      try {
        const {
          data: { students: resp = [], nextPageToken: nextPage = null },
        } = await studentsAPI.fetchAllBookmarkedStudents("my_customer");
        this.students = resp;
        this.nextPageToken = nextPage;
        return { students: resp, nextPageToken: nextPage };
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingStudents = false;
      }
    },

    async removeBookMark(studentId, index) {
      this.loadingBookmark = true;

      try {
        await studentsAPI.removeBookmarkedStudent("my_customer", studentId);
        this.students.splice(index, 1);
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingBookmark = false;
      }
    },

    getStudents: function () {
      this.loadingStudents = true;
      this.pageError = null;

      studentsAPI
        .fetchAllBookmarkedStudents("my_customer", this.nextPageToken)
        .then(
          ({
            data: { students: resp = [], nextPageToken: nextPage = null },
          }) => {
            this.students = this.students.concat(resp);
            this.nextPageToken = nextPage;
          },
        )
        .catch((error) => {
          this.setPageError(error);
        })
        .finally(() => {
          this.loadingStudents = false;
        });
    },

    navigateToStudent(primaryemail, studentId) {
      this.$router.push({
        name: "educator-primaryEmail",
        params: { primaryemail },
        query: { studentId },
      });
    },

    setPageError(error) {
      if (error.response) {
        if (error.response.data.detail) {
          var detail = error.response.data.detail;
          if (
            detail === "unauthorized_client" ||
            detail == "unknown_customer"
          ) {
            this.pageError =
              "Error. You are not authorized to use this application. Application is not installed in gsuite marketplace for this customer. If the app is already installed, please make sure to grant access to the scopes. For more details follow the following steps- \n 1. From the Admin console Home page, go to Apps and then G Suite Marketplace apps. \n 2. Click the app's name-Online Partner Drive Admin Tool \n 3. Grant Access";
            this.handleUnauthorisedError();
          } else if (
            detail === "transport_error" ||
            detail == "invalid_token" ||
            detail == "session_expired"
          ) {
            this.signOut();
          } else {
            this.pageError = detail;
          }
          return;
        }
      }
      this.pageError = "action_failed_error";
    },
  },

  watch: {
    async sessionActive(current) {
      if (current && this.hasContacted) {
        await this.getAllStudents();
      }
    },

    pageError(val) {
      if (val) {
        window.scrollTo(0, 0);
      }
    },
  },

  async created() {
    if (this.sessionActive && this.hasContacted) {
      await this.getAllStudents();
    }
  },
};
</script>
