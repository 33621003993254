<template>
  <v-container style="height: 100%; max-width: 80%">
    <div v-if="sessionActive && hasContacted">
      <Error
        :pageError="pageError"
        v-if="pageError"
        @on-dismiss="pageError = null"
      ></Error>

      <TitleTile color="primary" textColor="white">
        <span>{{ primaryEmail }}</span>
        <v-btn
          size="small"
          icon
          variant="outlined"
          style="border: None"
          :title="bookedMarked ? $t('remove_bookmark') : $t('bookmark_student')"
          :loading="loadingBookmark"
          v-if="studentId"
          color="white"
        >
          <v-icon
            color="white"
            size="28"
            v-if="bookedMarked"
            @click="removeBookMark()"
            >mdi-bookmark-check</v-icon
          >
          <v-icon color="white" size="28" v-else @click="addBookMark()"
            >mdi-bookmark-outline</v-icon
          ></v-btn
        >
      </TitleTile>

      <Headline :title="$t('course', 2)" color="dark-gray">
        <TooltipButton
          compStyle="margin-bottom: 0px; margin-left: 4px"
          iColor="dark-gray"
          iconName="mdi-refresh"
          :tooltipMsg="$t('refresh')"
          iconSize="26"
          :loading="loadingStudentCourses"
          @action-handler="getStudentCourses"
        />

        <v-tooltip
          location="bottom"
          color="black"
          class="text-white"
          v-if="filteredCourses.length"
        >
          <template v-slot:activator="{ props }">
            <v-checkbox
              v-bind="props"
              v-model="selectedAll"
              @click="selectAll"
              hide-details
              class="courseselectall ma-0"
              color="primary"
              false-icon="mdi-select-all"
              density="compact"
              :disabled="loadingStudentCourses"
            />
          </template>
          <span v-t="'select_all'"></span>
        </v-tooltip>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          :disabled="loadingStudentCourses"
          hide-details
          density="compact"
          variant="outlined"
          :placeholder="$t('search_by') + ' ' + $t('name')"
          prepend-inner-icon="mdi-magnify"
          color="secondary"
          class="autocomp mr-5"
          clearable
        />

        <Sort
          :selectedSort="selectedSorting"
          :sortingOption="sortingOption"
          :disabledAction="loadingStudentCourses"
          @selectedSorting="selectedSorting = $event"
        ></Sort>
      </Headline>

      <v-progress-linear
        v-if="loadingStudentCourses"
        indeterminate
        color="primary"
        height="2px"
      ></v-progress-linear>

      <v-list class="scrollist bg-background" id="scroll-courses">
        <v-list-item
          width="fit-content"
          v-for="(course, index) in filteredCourses"
          :key="index"
          class="pl-0"
        >
          <div
            style="width: 400px; height: fit-content"
            class="d-flex align-center"
          >
            <v-tooltip
              location="bottom"
              color="black"
              class="text-white"
              v-if="course.alternateLink"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  variant="text"
                  :href="course.alternateLink"
                  target="_blank"
                  class="courselink"
                  density="compact"
                >
                  {{ course.name }}
                </v-btn>
              </template>
              <span v-t="'open_course_in_google'"></span>
            </v-tooltip>
            <span v-else class="courselink ml-4"> {{ course.name }}</span>
          </div>

          <template v-slot:append>
            <v-list-item-action class="d-flex align-center">
              <v-progress-circular
                v-if="course.pending"
                indeterminate
                color="primary"
                size="30"
              />
              <v-checkbox
                density="compact"
                v-else
                v-model="selectedCourses"
                :value="course"
                hide-details
                :disabled="loadingStudentCourses"
              />
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list>

      <NoDataText
        :itemLoading="loadingStudentCourses"
        :nextPageToken="nextPageToken"
        :items="filteredCourses"
      ></NoDataText>

      <div class="d-flex justify-center">
        <LoadMore
          v-if="nextPageToken"
          :loading="loadingStudentCourses"
          @next-page="getStudentCoursesNextPage()"
        ></LoadMore>
      </div>

      <v-row class="align-self-end">
        <v-col class="d-flex justify-end mt-2">
          <v-btn
            height="30px"
            variant="outlined"
            class="mr-2 rounded-md"
            color="primary"
            style="background-color: white"
            to="/educator"
            ><span v-t="'back'"></span
          ></v-btn>

          <v-btn
            height="30px"
            class="rounded-md"
            color="primary"
            elevation="0"
            @click="saveChanges"
            :disabled="loadingStudentCourses"
            ><span v-t="'save'"></span
          ></v-btn>
        </v-col>
      </v-row>
    </div>

    <Freemium
      v-else-if="sessionActive && !hasContacted"
      images="/supporteducator.gif"
      :title="$t('support_educator')"
      :bodyText="$t('support_edu_freemium_text')"
    ></Freemium>
  </v-container>
</template>
<style>
.courselink span.v-btn__content {
  width: 400px !important;
  justify-content: start;
}
</style>

<style scoped>
.courseselectall .v-selection-control__input > .v-icon {
  color: #464646;
}

.autocomp {
  border-radius: 6px;
  border: 1px;
  background: white;
  width: 100px;
}

.scrollist {
  overflow-y: auto;
  max-height: 500px;
  scroll-behavior: smooth;
}

.courselink {
  text-transform: none;
  font-size: 16px;
  justify-content: start;
  width: 400px;
  height: fit-content !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #464646 !important;
}
</style>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import coursesAPI from "@/api/courses";
import studensAPI from "@/api/students";

import Error from "@/components/error.vue";
import Headline from "@/components/headline";
import TitleTile from "@/components/titleTile";
import Sort from "@/components/sort";
import LoadMore from "@/components/loadMoreButton";
import NoDataText from "@/components/noDataText.vue";
import Freemium from "@/components/freemium.vue";
import TooltipButton from "@/components/tooltipButton.vue";

export default {
  middleware: "authenticated-access",

  components: {
    Headline,
    TitleTile,
    Sort,
    Error,
    LoadMore,
    NoDataText,
    Freemium,
    TooltipButton,
  },

  data() {
    return {
      selectedSorting: 0,
      sortingOption: [
        this.$t("creation_date"),
        this.$t("creation_date_latest"),
        "A-Z",
        "Z-A",
      ],
      search: "",
      selectedAll: null,
      primaryEmail: null,
      filteredCourses: [],
      courses: [],
      selectedCourses: [],
      pageError: null,

      loadingStudentCourses: false,
      loadingBookmark: false,
      bookedMarked: false,
      studentId: null,
      nextPageToken: null,
    };
  },

  computed: {
    ...mapGetters({
      sessionActive: "sessionActive",
      hasContacted: "hasContacted",
    }),
  },

  watch: {
    async sessionActive(current) {
      if (current && this.hasContacted) {
        await this.getBookMark();
        await this.getStudentCourses();
      }
    },

    pageError(val) {
      if (val) {
        window.scrollTo(0, 0);
      }
    },

    selectedCourses: {
      handler() {
        this.selectedAll =
          this.selectedCourses.length === this.filteredCourses.length;
      },
      deep: true,
    },

    selectedSorting() {
      this.sortCourses();
    },

    search() {
      this.filterCourses();
    },

    filteredCourses: {
      handler(val) {
        this.selectedCourses = [];
        for (const course of val) {
          if (course.isTeacher) this.selectedCourses.push(course);
        }
      },
      deep: true,
    },

    courses: {
      handler() {
        var container = this.$el.querySelector("#scroll-courses");
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.sortCourses();
  },

  methods: {
    ...mapActions({
      handleUnauthorisedError: "handleUnauthorisedError",
      signOut: "signOut",
    }),

    async getBookMark() {
      this.loadingBookmark = true;

      try {
        this.pageError = null;

        const primEmail = this.$route.params.primaryemail;
        this.primaryEmail = primEmail;

        var studId = this.$route.query.studentId;

        if (studId == undefined || studId == null) {
          const response = await studensAPI.getStudent(
            "my_customer",
            primEmail,
          );
          studId = response.data.id;
        }

        this.studentId = studId;

        const bookmarkedStuResponse = await studensAPI.getBookmarkedStudent(
          "my_customer",
          this.studentId,
        );
        if (bookmarkedStuResponse.data != null) {
          this.bookedMarked = true;
        } else {
          this.bookedMarked = false;
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingBookmark = false;
      }
    },

    async getStudentCourses() {
      this.loadingStudentCourses = true;

      try {
        this.pageError = null;
        const primEmail = this.$route.params.primaryemail;
        const {
          data: { courses: resp, nextPageToken: nextPage = null },
        } = await studensAPI.getStudentCourses("my_customer", primEmail);

        const filtCourses = resp;
        this.nextPageToken = nextPage;

        const optedCourses = [];
        for (const course of resp) {
          if (course.isTeacher) optedCourses.push(course);
        }

        this.primaryEmail = primEmail;
        this.filteredCourses = filtCourses;
        this.courses = resp;
        this.selectedCourses = optedCourses;

        this.filterCourses();

        return {
          primaryEmail: primEmail,
          filteredCourses: filtCourses,
          courses: resp,
          selectedCourses: optedCourses,
        };
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingStudentCourses = false;
      }
    },

    async getStudentCoursesNextPage() {
      this.loadingStudentCourses = true;

      try {
        const primEmail = this.$route.params.primaryemail;
        const {
          data: { courses: resp = [], nextPageToken: nextPage = null },
        } = await studensAPI.getStudentCourses(
          "my_customer",
          primEmail,
          this.nextPageToken,
        );
        this.pageError = null;
        const filtCourses = resp;

        const optedCourses = [];
        for (const course of resp) {
          if (course.isTeacher) optedCourses.push(course);
        }

        this.filteredCourses = this.filteredCourses.concat(filtCourses);
        this.courses = this.courses.concat(resp);
        this.selectedCourses = this.selectedCourses.concat(optedCourses);
        this.nextPageToken = nextPage;

        this.filterCourses();
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingStudentCourses = false;
      }
    },

    async removeBookMark() {
      this.pageError = null;
      this.loadingBookmark = true;
      try {
        await studensAPI.removeBookmarkedStudent("my_customer", this.studentId);
        this.bookedMarked = false;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingBookmark = false;
      }
    },

    async addBookMark() {
      this.pageError = null;
      this.loadingBookmark = true;
      try {
        await studensAPI.addBookmarkStudent("my_customer", this.studentId);
        this.bookedMarked = true;
      } catch (error) {
        this.bookedMarked = false;
        this.setPageError(error);
      } finally {
        this.loadingBookmark = false;
      }
    },

    filterCourses: function () {
      if (this.search) {
        this.filteredCourses = _.filter(this.courses, (item) =>
          item.name.toLowerCase().includes(this.search.toLowerCase()),
        );
      } else {
        this.filteredCourses = this.courses;
      }
      this.sortCourses();
    },

    selectAll: function () {
      if (this.selectedCourses.length !== this.filteredCourses.length)
        this.selectedCourses = this.filteredCourses;
      else this.selectedCourses = [];
    },

    async addSupportEducatortoCourses(postCourses) {
      var courseAddFailed = [];
      var tempSingleCourseAddErrMsg = "";

      const checkedPromises = [];
      for (const courseId of postCourses) {
        checkedPromises.push(
          coursesAPI.addSupportEducatorToCourse("my_customer", courseId),
        );
      }
      const results = await Promise.allSettled(checkedPromises);

      for (let i = 0; i < results.length; i++) {
        const course = this.courses.find((c) => c.id === postCourses[i]);

        const { status } = results[i];
        if (status === "rejected") {
          if (course) {
            courseAddFailed.push(course.name);
            course.pending = false;
            course.isTeacher = false;

            tempSingleCourseAddErrMsg = await this.getErrMsgFromRejectedPromise(
              results[i],
            );
          }
        }

        if (status === "fulfilled") {
          if (course) {
            course.pending = false;
            course.isTeacher = true;
          }
        }
      }

      return { courseAddFailed, tempSingleCourseAddErrMsg };
    },

    getErrMsgFromRejectedPromise(scheduledResult) {
      if (
        scheduledResult &&
        scheduledResult.reason &&
        scheduledResult.reason.response &&
        scheduledResult.reason.response.data
      ) {
        return scheduledResult.reason.response.data.detail;
      } else {
        return "";
      }
    },

    async removeSupportEducatorfromCourses(deleteCourses) {
      var courseRemoveFailed = [];
      var tempSingleCourseRemoveErrMsg = "";

      const unCheckedPromises = [];
      for (const courseId of deleteCourses) {
        unCheckedPromises.push(
          coursesAPI.removeSupportEducatorFromCourse("my_customer", courseId),
        );
      }
      const results = await Promise.allSettled(unCheckedPromises);

      for (let i = 0; i < results.length; i++) {
        const course = this.courses.find((c) => c.id === deleteCourses[i]);

        const { status } = results[i];
        if (status === "rejected") {
          if (course) {
            courseRemoveFailed.push(course.name);
            course.pending = false;
            course.isTeacher = true;

            tempSingleCourseRemoveErrMsg =
              await this.getErrMsgFromRejectedPromise(results[i]);
          }
        }

        if (status === "fulfilled") {
          if (course) {
            course.pending = false;
            course.isTeacher = false;
          }
        }
      }

      return { courseRemoveFailed, tempSingleCourseRemoveErrMsg };
    },

    async saveChanges() {
      this.pageError = null;
      const postCourses = [];
      const deleteCourses = [];
      for (const course of this.courses) {
        if (course.isTeacher !== this.selectedCourses.includes(course)) {
          course.pending = true;
          if (!course.isTeacher) postCourses.push(course.id);
          else deleteCourses.push(course.id);
        }
      }
      this.filterCourses();

      // for checked courses
      var courseAddFailed = [];
      var tempSingleCourseAddErrMsg = "";
      if (postCourses.length) {
        this.loadingStudentCourses = true;
        let resp = await this.addSupportEducatortoCourses(postCourses);
        courseAddFailed = resp.courseAddFailed;
        tempSingleCourseAddErrMsg = resp.tempSingleCourseAddErrMsg;
        await this.filterCourses();
        this.loadingStudentCourses = false;
      }

      // for unchecked courses
      var courseRemoveFailed = [];
      var tempSingleCourseRemoveErrMsg = "";
      if (deleteCourses.length) {
        this.loadingStudentCourses = true;
        let remove_resp =
          await this.removeSupportEducatorfromCourses(deleteCourses);
        courseRemoveFailed = remove_resp.courseRemoveFailed;
        tempSingleCourseRemoveErrMsg = remove_resp.tempSingleCourseRemoveErrMsg;
        await this.filterCourses();
        this.loadingStudentCourses = false;
      }

      this.pageError = "";
      var errorMessage = "";

      if (courseAddFailed.length) {
        let endMessageAdd =
          courseAddFailed.length == 1 && tempSingleCourseAddErrMsg
            ? tempSingleCourseAddErrMsg + " "
            : "";
        errorMessage +=
          this.$t("add_se_fail_message") +
          ": " +
          courseAddFailed +
          ". " +
          endMessageAdd;
      }

      if (courseRemoveFailed.length) {
        let endMessageRemove =
          courseRemoveFailed.length == 1 && tempSingleCourseRemoveErrMsg
            ? tempSingleCourseRemoveErrMsg + " "
            : "";
        errorMessage +=
          this.$t("remove_se_fail_message") +
          ": " +
          courseRemoveFailed +
          ". " +
          endMessageRemove;
      }
      if (errorMessage) {
        errorMessage += this.$t("try_again");
      }
      this.pageError = errorMessage;
    },

    sortCourses: function () {
      if (this.sortingOption[this.selectedSorting] === "A-Z") {
        this.filteredCourses = _.orderBy(this.filteredCourses, "name");
      } else if (this.sortingOption[this.selectedSorting] === "Z-A") {
        this.filteredCourses = _.orderBy(this.filteredCourses, "name", "desc");
      } else if (
        this.sortingOption[this.selectedSorting] === this.$t("creation_date")
      ) {
        this.filteredCourses = _.orderBy(this.filteredCourses, "creationTime");
      } else if (
        this.sortingOption[this.selectedSorting] ===
        this.$t("creation_date_latest")
      ) {
        this.filteredCourses = _.orderBy(
          this.filteredCourses,
          "creationTime",
          "desc",
        );
      }
      this.selectedCourses = [];
    },

    setPageError(error) {
      if (error.response) {
        if (error.response.data.detail) {
          var detail = error.response.data.detail;
          if (
            detail === "unauthorized_client" ||
            detail == "unknown_customer"
          ) {
            this.pageError =
              "Error. You are not authorized to use this application. Application is not installed in gsuite marketplace for this customer. If the app is already installed, please make sure to grant access to the scopes. For more details follow the following steps- \n 1. From the Admin console Home page, go to Apps and then G Suite Marketplace apps. \n 2. Click the app's name-Online Partner Drive Admin Tool \n 3. Grant Access";
            this.handleUnauthorisedError();
          } else if (
            detail === "transport_error" ||
            detail == "invalid_token" ||
            detail == "session_expired"
          ) {
            this.signOut();
          } else {
            this.pageError = detail;
          }
          return;
        }
      }
      this.pageError = "action_failed_error";
    },
  },

  async created() {
    if (this.sessionActive && this.hasContacted) {
      await this.getBookMark();
      await this.getStudentCourses();
    }
  },
};
</script>
