<template>
  <v-container class="pa-0">
    <!-- add condition dialog  -->
    <v-dialog
      persistent
      v-model="showDialog"
      max-width="750px"
      content-class="elevation-2"
    >
      <v-card>
        <v-card-title class="text-dark-teal" v-if="showDelete">
          <span v-t="'update'" class="mr-1"></span
          ><span v-t="'condition'"></span>
        </v-card-title>

        <v-card-title class="text-dark-teal" v-else>
          <span v-t="'create'" class="mr-1"></span
          ><span v-t="'new'" class="mr-1"></span><span v-t="'condition'"></span>
        </v-card-title>

        <v-card-text>
          <div class="d-flex align-center">
            <!-- FIELD -->
            <v-select
              @update:modelValue="filterChange()"
              :items="filterFieldItems"
              item-title="displayText"
              item-value="id"
              v-model="filter"
              :label="$t('field')"
              variant="underlined"
            />

            <TooltipButton
              iColor="orange"
              iconName="mdi-information"
              :tooltipMsg="$t('lowercase_rule_for_field')"
            />

            <FilterConditionTool
              v-model="tool"
              v-if="filter.length"
              :fieldName="getFieldName(filter)"
            />
          </div>

          <div>
            <!-- OPERATION -->
            <v-select
              :disabled="!filter"
              :items="opList"
              item-value="id"
              item-title="name"
              @update:modelValue="
                operator == 'regex'
                  ? (textField = '')
                  : (textField = textField.toLowerCase())
              "
              v-model="operator"
              :label="$t('operator')"
              variant="underlined"
            />
          </div>

          <div>
            <!-- TEXT -->
            <v-text-field
              v-if="operator == 'regex'"
              :disabled="!filter"
              v-model="textField"
              :label="$t('regex_pattern')"
              variant="underlined"
            />

            <v-text-field
              v-else
              class="lowerCase"
              :disabled="!filter"
              v-model="textField"
              :rules="[() => !!textField || $t('field_required')]"
              @update:modelValue="textField = textField.toLowerCase()"
              :label="$t('text_common')"
              variant="underlined"
            />
          </div>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn
            height="30px"
            elevation="0"
            variant="outlined"
            color="primary"
            class="rounded-md"
            :disabled="operator != 'regex'"
            @click.stop="
              showRegexDialog = true;
              regexReset();
            "
          >
            <span v-t="'open_regex_studio'"></span>
          </v-btn>
          <v-spacer />

          <v-btn
            height="30px"
            elevation="0"
            variant="outlined"
            color="primary"
            class="rounded-md"
            @click="clear()"
            ><span v-t="'cancel'"></span
          ></v-btn>

          <v-btn
            height="30px"
            elevation="0"
            variant="flat"
            color="primary"
            class="rounded-md"
            v-if="showDelete"
            @click="saveCondition(conditionId)"
            :disabled="disableSave || disabledAction"
            ><span v-t="'update'"></span
          ></v-btn>

          <v-btn
            height="30px"
            elevation="0"
            variant="flat"
            color="primary"
            class="rounded-md"
            v-else
            @click="saveCondition()"
            :disabled="disableSave || disabledAction"
            ><span v-t="'add'"></span
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- regex test dialog for condition  -->
    <v-dialog persistent v-model="showRegexDialog" max-width="500">
      <v-card>
        <v-card-title class="text-dark-teal bg-active-teal">
          RegEx Studio
        </v-card-title>

        <v-card-text style="font-size: 16px">
          <span class="text-dark-gray" v-t="'regex_validate_info_1'"></span
          >(<v-icon size="x-small">mdi-refresh</v-icon>)

          <div class="d-flex mt-3 align-center">
            <v-text-field
              v-model="regex_input"
              label="Test-text"
              variant="underlined"
            />
            <v-icon size="large">mdi-arrow-right</v-icon>
            <v-text-field
              v-model="regexSample"
              readonly
              single-line
              variant="underlined"
            />

            <div class="d-flex align-center">
              <TooltipButton
                iconName="mdi-refresh"
                :tooltipMsg="$t('regex_refresh_tooltip')"
                :disabledAction="!Boolean(regex_input) || !Boolean(textField)"
                @action-handler="
                  validateRegExAction({
                    primary: textField,
                    secondary: '',
                    sample: regex_input,
                  })
                "
                :loading="regexLoading"
              />
            </div>
          </div>

          <v-text-field
            v-model="textField"
            :label="$t('regex_pattern')"
            variant="underlined"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            height="30px"
            elevation="0"
            variant="outlined"
            color="primary"
            class="rounded-md"
            @click.stop="showRegexDialog = false"
            ><span v-t="'cancel'"></span
          ></v-btn>
          <v-btn
            height="30px"
            elevation="0"
            variant="flat"
            color="primary"
            class="rounded-md"
            @click.stop="showRegexDialog = false"
            ><span v-t="'save'"></span
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-divider></v-divider>

    <!-- Display group conditions  -->
    <ConditionsList
      :filterGroupConditions="filterGroupConditions"
      :loadingSettings="loadingSettings"
      :disabledAction="disabledAction"
      @editCondition="editCondition"
      @deleteCondition="deleteCondition"
    ></ConditionsList>
  </v-container>
</template>

<script>
import ConditionsList from "@/components/conditionsList.vue";
import FilterConditionTool from "@/components/filterConditionTool.vue";
import TooltipButton from "@/components/tooltipButton";

import settingsAPI from "@/api/customerSettings";

export default {
  props: {
    filterGroupConditions: {
      type: Array,
    },

    loadingSettings: {
      type: Boolean,
      default: false,
    },

    disabledAction: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filterFieldItems: [
        { displayText: this.$t("name"), id: "name" },
        { displayText: this.$t("email"), id: "email" },
      ],

      showDialog: false,
      showDelete: false,
      stringOperators: ["===", "!==", "contains", "containsNot", "regex"],

      conditionId: "",
      filter: "",
      operator: "",
      textField: "",
      tool: null,

      showRegexDialog: false,
      regex_input: "",
      regexSample: "",
      regexLoading: false,
    };
  },

  emits: ["saveCondition", "deleteCondition"],

  components: {
    ConditionsList,
    FilterConditionTool,
    TooltipButton,
  },

  computed: {
    disableSave: function () {
      if (this.filter.length === 0) {
        return true;
      }
      if (this.operator.length === 0) {
        return true;
      }
      if (this.textField.length === 0) {
        return true;
      }
      return false;
    },

    opList: function () {
      let response = [];
      let ops = this.stringOperators;
      for (let id = 0; id < ops.length; id++) {
        response.push({
          id: ops[id],
          name: this.getOperatorName(ops[id]),
        });
      }
      return response;
    },
  },

  methods: {
    async saveCondition() {
      await this.$emit(
        "saveCondition",
        this.conditionId,
        this.filter,
        this.operator,
        this.textField,
        this.tool,
      );
      this.clear();
    },

    async deleteCondition(conditionIdVal) {
      await this.$emit("deleteCondition", conditionIdVal);
      this.clear();
    },

    newCondition() {
      this.clear();
      this.showDialog = true;
    },

    getOperatorName(fieldId) {
      return (
        {
          "===": this.$t("equals"),
          "!==": this.$t("not_equals"),
          contains: this.$t("contains"),
          containsNot: this.$t("does_not_contain"),
          regex: this.$t("regex_match"),
        }[fieldId] || this.$t("error_occured")
      );
    },

    getFieldName(fieldId) {
      return (
        {
          // Group-field
          email: this.$t("group") + " " + this.$t("email"),
          name: this.$t("group") + " " + this.$t("name"),
        }[fieldId] ||
        (fieldId.includes(".") ? "User attributes" : this.$t("error_occured"))
      );
    },

    clear() {
      this.conditionId = "";
      this.filter = "";
      this.operator = "";
      this.textField = "";
      this.tool = null;
      this.showDialog = false;
      this.showDelete = false;
    },

    editCondition(cId) {
      const condition = this.filterGroupConditions.find((f) => f.id === cId);

      if (condition) {
        this.conditionId = cId;

        this.filter = condition.filter;
        this.operator = condition.operator;
        this.textField = condition.textField;
        this.tool = condition.tool;
        this.showDelete = true;
        this.showDialog = true;
      }
    },

    filterChange() {
      if (this.operator && !this.stringOperators.includes(this.operator)) {
        this.operator = "";
      }
      this.tool = null;
    },

    regexReset() {
      this.regexLoading = false;
      this.regexSample = "";
    },

    async validateRegExAction(postBody) {
      try {
        this.regexLoading = true;
        let resp = await settingsAPI.validateRegEx("my_customer", postBody);

        this.regexSample = resp.validation;
      } catch (error) {
        console.log(error);
      } finally {
        this.regexLoading = false;
      }
    },
  },
};
</script>
