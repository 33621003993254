<template>
  <v-container style="max-width: 80%">
    <div v-if="sessionActive && hasContacted">
      <SnackBar
        :text="snackbarText"
        :color="snackBarColor"
        v-if="snackbar"
        @on-dismiss="
          snackbar = false;
          snackbarText = '';
        "
      ></SnackBar>

      <Error
        :pageError="pageError"
        v-if="pageError"
        @on-dismiss="pageError = null"
      ></Error>

      <manage
        :title="$t('support_educator')"
        :list="educators"
        :groupsList="educatorsGroups"
        :usersNextPageToken="edusNextPageToken"
        :groupsNextPageToken="eduGroupsNextPageToken"
        :loadingItems="loadingItems"
        :loadingGroups="loadingGroups"
        @add-item="addEducatorHandler"
        @add-group="addGroupHandler"
        @remove-item="removeEducator"
        @remove-group="removeEducatorGroup"
        @fetch-users="getAllSupportEducators"
        @fetch-groups="getAllSupportEducatorsGroups"
        @get-searched-users="getSearchedEducators"
        @get-searched-groups="getSearchedEducatorGroups"
        @get-user="getEducator"
        @get-group="getEducatorGroup"
        @sort-users="sortEducators"
        @sort-groups="sortEducatorGroups"
      />
    </div>

    <Freemium
      v-else-if="sessionActive && !hasContacted"
      images="/manageusers.gif"
      :title="$t('manage_users_freemium_title')"
      :bodyText="$t('manage_users_freemium_text')"
    ></Freemium>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import manage from "@/components/manage";
import roleAssignmentsAPI from "@/api/roleAssignments";

import Error from "@/components/error.vue";
import SnackBar from "@/components/snackBar.vue";
import Freemium from "@/components/freemium.vue";

export default {
  middleware: "authenticated-access",
  components: {
    manage,
    Error,
    SnackBar,
    Freemium,
  },

  data() {
    return {
      educators: [],
      educatorsGroups: [],
      pageError: null,
      loadingItems: false,
      loadingGroups: false,
      edusNextPageToken: null,
      eduGroupsNextPageToken: null,

      snackbar: false,
      snackbarText: "",
      snackBarColor: "black",
    };
  },

  computed: {
    ...mapGetters({
      sessionActive: "sessionActive",
      hasContacted: "hasContacted",
    }),
  },

  methods: {
    ...mapActions({
      handleUnauthorisedError: "handleUnauthorisedError",
      signOut: "signOut",
    }),

    async getAllSupportEducators(sortUserBy) {
      this.loadingItems = true;
      try {
        const response = await roleAssignmentsAPI.getUsers(
          "my_customer",
          "Support educator",
          this.edusNextPageToken,
          sortUserBy,
        );
        this.pageError = null;
        const educatorsResp = response.data.role_assignments;

        if (this.edusNextPageToken == null) {
          this.educators = educatorsResp;
        } else {
          this.educators = this.educators.concat(educatorsResp);
        }
        this.edusNextPageToken = response.data.next_page_token;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingItems = false;
      }
    },

    async getAllSupportEducatorsGroups(sortGroupBy) {
      this.loadingGroups = true;
      try {
        const response = await roleAssignmentsAPI.getGroups(
          "my_customer",
          "Support educator",
          this.eduGroupsNextPageToken,
          sortGroupBy,
        );
        this.pageError = null;
        const educatorsGroup = response.data.role_assignments;

        if (this.eduGroupsNextPageToken == null) {
          this.educatorsGroups = educatorsGroup;
        } else {
          this.educatorsGroups = this.educatorsGroups.concat(educatorsGroup);
        }

        this.eduGroupsNextPageToken = response.data.next_page_token;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingGroups = false;
      }
    },

    async addEducatorHandler(userId, email, sortGroupBy) {
      this.loadingItems = true;

      try {
        const response = await roleAssignmentsAPI.createUser(
          "my_customer",
          userId,
          email,
          "Support educator",
        );
        if (response.status === 200) {
          this.pageError = null;
          this.edusNextPageToken = null;
          await this.getAllSupportEducators(sortGroupBy);
          this.snackbarText = this.$t("user_successfully_added");
          this.snackBarColor = "#4BB543";
          this.snackbar = true;
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingItems = false;
      }
    },

    async addGroupHandler(group_id, group_email, sortGroupBy) {
      this.loadingGroups = true;

      try {
        const response = await roleAssignmentsAPI.createGroup(
          "my_customer",
          group_id,
          group_email,
          "Support educator",
        );
        if (response.status === 200) {
          this.pageError = null;

          this.eduGroupsNextPageToken = null;
          await this.getAllSupportEducatorsGroups(sortGroupBy);
          this.snackbarText = this.$t("group_successfully_added");
          this.snackBarColor = "#4BB543";
          this.snackbar = true;
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingGroups = false;
      }
    },

    async removeEducator(id, sortUserBy) {
      this.loadingItems = true;

      try {
        const response = await roleAssignmentsAPI.removeUser("my_customer", id);
        if (response.status === 200) {
          this.pageError = null;

          if (!this.edusNextPageToken && this.educators.length <= 1) {
            this.edusNextPageToken = null;
            await this.getAllSupportEducators(sortUserBy);
          } else {
            this.educators = this.educators.filter((item) => item.id !== id);
          }

          this.snackbarText = this.$t("support_educator_deleted");
          this.snackBarColor = "#4BB543";
          this.snackbar = true;
        }
      } catch (error) {
        var detail = await this.setPageError(error);
        if (detail == "se_email_not_found") {
          this.pageError = "se_email_not_found";

          if (!this.edusNextPageToken && this.educators.length <= 1) {
            this.edusNextPageToken = null;
            await this.getAllSupportEducators(sortUserBy);
          } else {
            this.educators = this.educators.filter((item) => item.id !== id);
          }
        }
      } finally {
        this.loadingItems = false;
      }
    },

    async removeEducatorGroup(id, sortGroupBy) {
      this.loadingGroups = true;

      try {
        const response = await roleAssignmentsAPI.removeGroup(
          "my_customer",
          id,
        );
        if (response.status === 200) {
          this.pageError = null;

          if (
            !this.eduGroupsNextPageToken &&
            this.educatorsGroups.length <= 1
          ) {
            this.eduGroupsNextPageToken = null;
            await this.getAllSupportEducatorsGroups(sortGroupBy);
          } else {
            this.educatorsGroups = this.educatorsGroups.filter(
              (item) => item.id !== id,
            );
          }
          this.snackbarText = this.$t("support_educator_group_deleted");
          this.snackBarColor = "#4BB543";
          this.snackbar = true;
        }
      } catch (error) {
        var detail = await this.setPageError(error);
        if (detail == "se_email_not_found") {
          this.pageError = "se_email_not_found";

          if (
            !this.eduGroupsNextPageToken &&
            this.educatorsGroups.length <= 1
          ) {
            this.eduGroupsNextPageToken = null;
            await this.getAllSupportEducatorsGroups(sortGroupBy);
          } else {
            this.educatorsGroups = this.educatorsGroups.filter(
              (item) => item.id !== id,
            );
          }
        }
      } finally {
        this.loadingGroups = false;
      }
    },

    async getEducator(userId) {
      this.loadingItems = true;
      try {
        const response = await roleAssignmentsAPI.getUser(
          "my_customer",
          userId,
          "Support educator",
        );

        if (response.data != null) {
          this.edusNextPageToken = null;
          this.educators = [
            {
              id: response.data.id,
              user_id: response.data.user_id,
              role: response.data.role,
              email: response.data.email,
            },
          ];
        } else {
          this.snackbarText = this.$t("se_email_not_found");
          this.snackBarColor = "#D22B2B";
          this.snackbar = true;
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingItems = false;
      }
    },

    async getSearchedEducators(sortUserBy) {
      this.edusNextPageToken = null;
      await this.getAllSupportEducators(sortUserBy);
    },

    async getEducatorGroup(groupId) {
      this.loadingGroups = true;
      try {
        const response = await roleAssignmentsAPI.getGroup(
          "my_customer",
          groupId,
          "Support educator",
        );

        if (response.data != null) {
          this.eduGroupsNextPageToken = null;
          this.educatorsGroups = [
            {
              id: response.data.id,
              group_id: response.data.group_id,
              email: response.data.email,
              role: response.data.role,
            },
          ];
        } else {
          this.snackbarText = this.$t("se_email_not_found");
          this.snackBarColor = "#D22B2B";
          this.snackbar = true;
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingGroups = false;
      }
    },

    async getSearchedEducatorGroups(sortGroupBy) {
      this.eduGroupsNextPageToken = null;
      await this.getAllSupportEducatorsGroups(sortGroupBy);
    },

    async sortEducators(sortUserBy) {
      this.edusNextPageToken = null;
      await this.getAllSupportEducators(sortUserBy);
    },

    async sortEducatorGroups(sortGroupBy) {
      this.eduGroupsNextPageToken = null;
      await this.getAllSupportEducatorsGroups(sortGroupBy);
    },

    setPageError(error) {
      if (error.response) {
        if (error.response.data.detail) {
          var detail = error.response.data.detail;
          if (
            detail === "unauthorized_client" ||
            detail == "unknown_customer"
          ) {
            this.pageError =
              "Error. You are not authorized to use this application. Application is not installed in gsuite marketplace for this customer. If the app is already installed, please make sure to grant access to the scopes. For more details follow the following steps- \n 1. From the Admin console Home page, go to Apps and then G Suite Marketplace apps. \n 2. Click the app's name-Online Partner Drive Admin Tool \n 3. Grant Access";
            this.handleUnauthorisedError();
          } else if (
            detail === "transport_error" ||
            detail == "invalid_token" ||
            detail == "session_expired"
          ) {
            this.signOut();
          } else if (detail == "email_not_found") {
            return "se_email_not_found";
          } else {
            this.pageError = detail;
          }
          return;
        }
      }
      this.pageError = "action_failed_error";
    },
  },

  watch: {
    async sessionActive(current) {
      if (current && this.hasContacted) {
        await this.getAllSupportEducators("email");
        await this.getAllSupportEducatorsGroups("email");
      }
    },
  },

  async created() {
    if (this.sessionActive && this.hasContacted) {
      await this.getAllSupportEducators("email");
      await this.getAllSupportEducatorsGroups("email");
    }
  },
};
</script>
