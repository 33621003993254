<template>
  <v-dialog
    v-model="dialog"
    content-class="elevation-2"
    max-width="750px"
    persistent
  >
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        variant="text"
        :icon="fab"
        size="small"
        v-if="icon"
        class="ml-2 mb-0 mt-1"
        :disabled="disabledBtn"
      >
        <v-icon :size="height" color="primary">{{ icon }}</v-icon>
      </v-btn>

      <v-btn
        v-bind="props"
        elevation="0"
        :variant="buttonOutlined ? 'outlined' : 'elevated'"
        v-else
        class="mr-2 rounded-md"
        :height="height"
        :color="color"
        :disabled="disabledBtn"
      >
        <v-icon size="small">{{ buttonIcon }}</v-icon
        ><span>{{ buttonName }}</span>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-dark-teal">{{ title }}</v-card-title>

      <v-card-text class="d-flex flex-column">
        <slot />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          height="30px"
          elevation="0"
          variant="outlined"
          color="primary"
          class="rounded-md"
          @click="
            () => {
              $emit('close-dialog');
              dialog = false;
            }
          "
          ><span v-t="'cancel'"></span
        ></v-btn>
        <v-btn
          height="30px"
          color="primary"
          class="rounded-md"
          variant="flat"
          elevation="0"
          :disabled="disabledAction"
          @click="
            () => {
              $emit('action-handler');
              dialog = false;
            }
          "
        >
          {{ actionName }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from "vue";

defineProps({
  color: String,

  disabledBtn: {
    type: Boolean,
    default: false,
  },

  disabledAction: {
    type: Boolean,
    default: false,
  },

  tooltipMsg: {
    type: String,
    default: "",
  },

  icon: {
    type: String,
  },

  buttonName: {
    type: String,
  },

  buttonIcon: {
    type: String,
  },

  buttonOutlined: {
    type: Boolean,
    default: false,
  },

  title: {
    type: String,
    required: true,
  },
  actionName: {
    type: String,
    required: true,
  },
  height: {
    type: String,
    default: "35px",
  },
  width: {
    type: String,
    default: "35px",
  },
  fab: {
    type: Boolean,
    default: true,
  },
});

defineEmits(["close-dialog", "action-handler"]);

const dialog = ref(false);
</script>
