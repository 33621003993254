<template>
  <v-container style="height: 100%; max-width: 80%">
    <Error
      :pageError="pageError"
      v-if="pageError"
      @on-dismiss="pageError = null"
    ></Error>

    <TitleTile color="primary" textColor="white">
      <span>{{ courseName }}</span>

      <!-- Display alert if course is orphaned and if functionaity is limited for course-->
      <v-tooltip
        location="right"
        v-if="courseName && isOrphaned && !nextPageToken"
      >
        <template v-slot:activator="{ props }">
          <v-icon v-bind="props" size="24" class="ml-1" color="yellow"
            >mdi-alert</v-icon
          >
        </template>
        <span
          v-t="'manage_orphaned_course_teachers_warning'"
          class="mr-1"
        ></span>
        <span
          v-t="'limited_functionality_warning'"
          v-if="isFunctionalityExpired"
        ></span>
      </v-tooltip>

      <!-- Display alert if course is orphaned and if functionaity is limited for course-->
      <TooltipButton
        v-if="courseName && teachers.length == 0 && isFunctionalityExpired"
        iColor="yellow"
        iconName="mdi-alert"
        :tooltipMsg="$t('limited_functionality_warning')"
      />
    </TitleTile>

    <Headline :title="$t('teacher', 2)" color="dark-gray">
      <span
        v-if="teachers.length"
        class="ml-1 mt-1 text-dark-gray"
        style="font-size: 26px"
        >({{ teachers.length + "/" + maxTeacherLimit }})</span
      >

      <div class="d-flex ml-1 mt-1">
        <TooltipButton
          :disabledAction="loadingAddTeacher"
          :loading="loadingCourseTeachers || loadingTeacherInvitations"
          @action-handler="
            getAllCourseTeachers();
            getAllCourseTeacherInvitations();
          "
          iconName="mdi-refresh"
          :tooltipMsg="$t('refresh')"
          iconSize="26"
        />
      </div>
      <v-spacer />

      <Search
        @set-search-by-name="setSearchByName($event)"
        @set-search-by-email="setSearchByEmail($event)"
        :disabledAction="loadingCourseTeachers"
      />

      <DialogButton
        color="primary"
        icon="mdi-account-plus-outline"
        :title="$t('add') + ' ' + $t('teacher', 1)"
        :actionName="$t('add')"
        :fab="true"
        height="40"
        :disabledAction="!newTeacherEmail"
        v-if="!isFunctionalityExpired"
        @action-handler="addTeacherHandler(newTeacherEmail)"
        @close-dialog="newTeacherEmail = ''"
        :tooltipMsg="$t('add') + ' ' + $t('teacher', 1)"
      >
        <AutocompleteTeachers
          :placeholderName="$t('name')"
          :placeholderEmail="$t('email')"
          @select="newTeacherEmail = $event ? $event.email : null"
        />
      </DialogButton>
      <DialogButton
        color="primary"
        icon="mdi-account-multiple-plus-outline"
        :title="$t('add') + ' ' + $t('group')"
        :actionName="$t('add')"
        :fab="true"
        height="44"
        :disabledAction="!newGroupEmail"
        v-if="!isFunctionalityExpired"
        @action-handler="addGroupOfTeachersHandler(newGroupEmail)"
        @close-dialog="newGroupEmail = ''"
        :tooltipMsg="$t('add') + ' ' + $t('group')"
      >
        <AutocompleteDomainGroups
          class="pl-0 pb-0 pt-0"
          :placeholderName="$t('name')"
          :placeholderEmail="$t('email')"
          @select="newGroupEmail = $event ? $event.email : null"
        />
      </DialogButton>

      <Sort
        :selectedSort="selectedSorting"
        :sortingOption="sortingOption"
        :disabledAction="loadingCourseTeachers"
        iconCompHeight="25px"
        @selectedSorting="selectedSorting = $event"
      ></Sort>
    </Headline>

    <v-progress-linear
      v-if="loadingCourseTeachers"
      indeterminate
      color="primary"
      height="2px"
    ></v-progress-linear>

    <ItemsList
      :filteredItemsList="filteredTeachers"
      itemTitleCls="fontcls mr-2"
    >
      <template #listaction="itemProps">
        <v-progress-circular
          v-if="itemProps.item.taskStatus"
          indeterminate
          size="30"
          color="primary"
        />

        <div v-else>
          <v-btn
            elevation="0"
            color="primary"
            height="25px"
            class="rounded-md mr-2"
            size="small"
            @click="transferOwnership(itemProps.item.id)"
            :disabled="
              loadingCourseTeachers || loadingAddTeacher || itemProps.item.owner
            "
            v-if="!isFunctionalityExpired && !isOrphaned"
          >
            <v-icon size="20" class="mr-1">mdi-transit-transfer</v-icon>
            <span v-t="'make_owner'"></span>
          </v-btn>

          <v-btn
            variant="flat"
            color="primary"
            height="25px"
            class="rounded-md"
            size="small"
            @click="removeTeacherHandler(itemProps.item.email)"
            :disabled="
              loadingCourseTeachers || loadingAddTeacher || itemProps.item.owner
            "
            v-if="!isFunctionalityExpired"
          >
            <v-icon size="20" class="mr-1">mdi-account-remove</v-icon>
            <span v-t="'remove'"></span>
          </v-btn>
        </div>
      </template>
    </ItemsList>

    <NoDataText
      :itemLoading="loadingCourseTeachers"
      :nextPageToken="nextPageToken"
      :items="filteredTeachers"
    ></NoDataText>

    <DialogBox
      :dialog="warningDialog"
      :title="$t('unable_to_delete')"
      :actionName="$t('close')"
      :simple="true"
      @close-dialog="warningDialog = false"
      dialogWidth="300px"
    >
      <span class="fontcls" v-t="'insuffeceint_teachers'"></span>
    </DialogBox>

    <div class="d-flex justify-center">
      <LoadMore
        v-if="nextPageToken"
        :loading="loadingCourseTeachers"
        @next-page="getCourseTeachersPage"
      ></LoadMore>
    </div>

    <!-- invited teachers section  -->

    <Headline
      class="mt-10"
      :title="$t('invited') + ' ' + $t('teacher', 2)"
      color="dark-gray"
    >
      <v-spacer />

      <Search
        @set-search-by-name="setInvitationSearchByName($event)"
        @set-search-by-email="setInvitationSearchByEmail($event)"
        :disabledAction="loadingTeacherInvitations"
      />

      <DialogButton
        ref="InvitationAddDialog"
        color="primary"
        icon="mdi-account-plus-outline"
        :title="$t('create') + ' ' + $t('invitation')"
        :actionName="$t('add')"
        :fab="true"
        height="40"
        :disabledAction="!newTeacherInvitationEmail"
        v-if="!isFunctionalityExpired"
        @action-handler="addTeacherInvitationHandler()"
        @close-dialog="newTeacherInvitationEmail = null"
        :tooltipMsg="$t('create') + ' ' + $t('invitation')"
      >
        <AutocompleteTeachers
          :placeholderName="$t('name')"
          :placeholderEmail="$t('email')"
          @select="newTeacherInvitationEmail = $event"
        />

        <v-select
          class="mt-8"
          hide-details
          :label="$t('select_role')"
          :items="invitationRoles"
          :mandatory="true"
          v-model="selectedRole"
          variant="outlined"
          density="compact"
        />
      </DialogButton>

      <Sort
        :selectedSort="selectedInvitationSorting"
        :sortingOption="sortingOption"
        :disabledAction="loadingCourseTeachers || loadingTeacherInvitations"
        iconCompHeight="25px"
        @selectedSorting="selectedInvitationSorting = $event"
      ></Sort>
    </Headline>

    <v-progress-linear
      v-if="loadingTeacherInvitations"
      indeterminate
      color="primary"
      height="2px"
    ></v-progress-linear>

    <ItemsList
      :filteredItemsList="filteredTeacherInvitations"
      itemTitleCls="fontcls mr-2"
    >
      <template #listaction="itemProps">
        <v-progress-circular
          v-if="itemProps.item.taskStatus"
          indeterminate
          size="30"
          color="primary"
        />

        <v-btn
          v-else
          variant="flat"
          color="primary"
          height="25px"
          class="rounded-md"
          size="small"
          @click="
            removeTeacherInvitationHandler(
              itemProps.item.id,
              itemProps.item.userEmail,
              itemProps.item.role,
            )
          "
          :disabled="
            loadingCourseTeachers ||
            loadingTeacherInvitations ||
            loadingAddTeacher
          "
        >
          <v-icon size="20" class="mr-1" v-if="itemProps.item.role == 'OWNER'"
            >mdi-head-minus</v-icon
          >
          <v-icon size="20" class="mr-1" v-else>mdi-account-remove</v-icon>
          <span v-t="'remove'"></span>
        </v-btn>
      </template>
    </ItemsList>

    <NoDataText
      :itemLoading="loadingTeacherInvitations"
      :nextPageToken="nextInvitationPageToken"
      :items="filteredTeacherInvitations"
    ></NoDataText>

    <div class="d-flex justify-center">
      <LoadMore
        v-if="nextInvitationPageToken"
        :loading="loadingTeacherInvitations"
        @next-page="getCourseInvitedTeachersPage"
      ></LoadMore>
    </div>

    <v-row class="align-self-end">
      <v-col class="d-flex justify-end">
        <v-btn
          elevation="0"
          color="primary"
          style="background-color: white"
          height="25px"
          class="rounded-md"
          variant="outlined"
          @click="$router.go(-1)"
          ><span v-t="'back'"></span
        ></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import useGetTaskStatus from "@/composables/getTaskStatus";
import coursesAPI from "@/api/courses";
import orphanedCoursesAPI from "@/api/orphanedCourses";

import Error from "@/components/error.vue";
import Search from "@/components/search";
import Headline from "@/components/headline";
import TitleTile from "@/components/titleTile";
import DialogButton from "@/components/dialogButton";
import DialogBox from "@/components/dialog";
import Sort from "@/components/sort";
import AutocompleteTeachers from "@/components/autocompleteTeachers";
import AutocompleteDomainGroups from "@/components/autocompleteDomainGroups";
import LoadMore from "@/components/loadMoreButton";
import NoDataText from "@/components/noDataText.vue";
import TooltipButton from "@/components/tooltipButton";
import ItemsList from "@/components/itemsList.vue";

export default {
  setup() {
    const { getTaskStatus } = useGetTaskStatus();
    return { getTaskStatus };
  },

  middleware: "authenticated-access",
  components: {
    Search,
    Headline,
    TitleTile,
    DialogBox,
    DialogButton,
    Sort,
    AutocompleteTeachers,
    AutocompleteDomainGroups,
    Error,
    LoadMore,
    NoDataText,
    TooltipButton,
    ItemsList,
  },
  data() {
    return {
      warningDialog: false,
      newTeacherEmail: "",
      newGroupEmail: "",
      selectedSorting: 0,
      sortingOption: ["A-Z", "Z-A"],
      search: "",
      searchby: "name",
      maxTeacherLimit: 50,

      courseName: null,
      courseId: null,
      teachers: [],
      pageError: null,

      nextPageToken: null,

      loadingCourseTeachers: false,
      loadingAddTeacher: false,

      loadingTeacherInvitations: false,
      invitationSearch: "",
      invitationSearchBy: "name",
      newTeacherInvitationEmail: null,
      invitationRoles: ["TEACHER", "OWNER"],
      selectedRole: "TEACHER",
      selectedInvitationSorting: 0,
      nextInvitationPageToken: null,
      invitedTeachers: [],

      isFunctionalityExpired: false,
    };
  },

  computed: {
    ...mapGetters({
      sessionActive: "sessionActive",
    }),

    isOrphaned() {
      if (this.teachers.length != 0) {
        if (this.teachers.some((teacher) => teacher.owner)) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    filteredTeachers() {
      var tempTeachers = [];

      if (this.search) {
        if (this.searchby == "name") {
          tempTeachers = _.filter(this.teachers, (item) => {
            if (item.name) {
              return item.name
                .toLowerCase()
                .includes(this.search.toLowerCase());
            } else {
              return false;
            }
          });
        } else {
          tempTeachers = _.filter(this.teachers, (item) =>
            item.email.toLowerCase().includes(this.search.toLowerCase()),
          );
        }
      } else {
        tempTeachers = this.teachers;
      }

      // Sort all teachers except owner
      const owner = tempTeachers.find((teacher) => teacher.owner);

      if (this.sortingOption[this.selectedSorting] === "A-Z") {
        if (owner) {
          tempTeachers = [owner].concat(
            _.orderBy(
              tempTeachers.filter((t) => !t.owner),
              "email",
            ),
          );
        } else {
          tempTeachers = _.orderBy(tempTeachers, "email");
        }
      } else if (this.sortingOption[this.selectedSorting] === "Z-A") {
        if (owner) {
          tempTeachers = [owner].concat(
            _.orderBy(
              tempTeachers.filter((t) => !t.owner),
              "email",
              "desc",
            ),
          );
        } else {
          tempTeachers = _.orderBy(tempTeachers, "email", "desc");
        }
      }

      return tempTeachers;
    },

    filteredTeacherInvitations() {
      var tempInvitations = [];

      if (this.invitationSearch) {
        if (this.invitationSearchBy == "name") {
          tempInvitations = _.filter(this.invitedTeachers, (item) => {
            if (item.userName) {
              return item.userName
                .toLowerCase()
                .includes(this.invitationSearch.toLowerCase());
            } else {
              return false;
            }
          });
        } else {
          tempInvitations = _.filter(this.invitedTeachers, (item) =>
            item.userEmail
              .toLowerCase()
              .includes(this.invitationSearch.toLowerCase()),
          );
        }
      } else {
        tempInvitations = this.invitedTeachers;
      }

      // Sort all invitations by email except owner
      const owner = tempInvitations.find((teacher) => teacher.role == "OWNER");

      if (this.sortingOption[this.selectedInvitationSorting] === "A-Z") {
        if (owner) {
          tempInvitations = [owner].concat(
            _.orderBy(
              tempInvitations.filter((t) => t.role != "OWNER"),
              "userEmail",
            ),
          );
        } else {
          tempInvitations = _.orderBy(tempInvitations, "userEmail");
        }
      } else if (this.sortingOption[this.selectedInvitationSorting] === "Z-A") {
        if (owner) {
          tempInvitations = [owner].concat(
            _.orderBy(
              tempInvitations.filter((t) => t.role != "OWNER"),
              "userEmail",
              "desc",
            ),
          );
        } else {
          tempInvitations = _.orderBy(tempInvitations, "userEmail", "desc");
        }
      }

      return tempInvitations;
    },
  },

  watch: {
    async sessionActive(current) {
      if (current) {
        this.courseId = this.$route.params.courseId;
        this.courseName = this.$route.params.courseName;
        await this.getOrphanedCourseOwnerDeletionTime();
        await this.getAllCourseTeachers();
        await this.getAllCourseTeacherInvitations();
      }
    },

    pageError(val) {
      if (val) {
        window.scrollTo(0, 0);
      }
    },
  },

  methods: {
    ...mapActions({
      handleUnauthorisedError: "handleUnauthorisedError",
      signOut: "signOut",
    }),

    async getOrphanedCourseOwnerDeletionTime() {
      try {
        const response = await orphanedCoursesAPI.getCourse(
          "my_customer",
          this.courseId,
        );
        if (response.data) {
          if (response.data.owner_id != "105250506097979753968") {
            this.isFunctionalityExpired = false;
          } else {
            this.isFunctionalityExpired = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getAllCourseTeachers() {
      this.loadingCourseTeachers = true;
      try {
        const response = await coursesAPI.getCourseTeachers(
          "my_customer",
          this.courseId,
        );

        this.teachers = response.data.teachers;
        this.nextPageToken = response.data.nextPageToken;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingCourseTeachers = false;
      }
    },

    async getCourseTeachersPage() {
      this.loadingCourseTeachers = true;

      try {
        const response = await coursesAPI.getCourseTeachers(
          "my_customer",
          this.courseId,
          this.nextPageToken,
        );
        this.pageError = null;
        this.teachers = this.teachers.concat(response.data.teachers);
        this.nextPageToken = response.data.nextPageToken;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingCourseTeachers = false;
      }
    },

    setSearchByName: function (val) {
      this.searchby = "name";
      this.search = val;
    },

    setSearchByEmail: function (val) {
      this.searchby = "email";
      this.search = val;
    },

    async getAllCourseTeacherInvitations() {
      this.loadingTeacherInvitations = true;
      try {
        const response = await coursesAPI.getCourseInvitations(
          "my_customer",
          this.courseId,
          ["TEACHER", "OWNER"],
        );
        this.invitedTeachers = response.data.invitations;
        this.nextInvitationPageToken = response.data.nextPageToken;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingTeacherInvitations = false;
      }
    },

    async getCourseInvitedTeachersPage() {
      this.loadingTeacherInvitations = true;

      try {
        const response = await coursesAPI.getCourseInvitations(
          "my_customer",
          this.courseId,
          ["TEACHER", "OWNER"],
          this.nextInvitationPageToken,
        );
        this.pageError = null;
        this.invitedTeachers = this.invitedTeachers.concat(
          response.data.invitations,
        );
        this.nextInvitationPageToken = response.data.nextPageToken;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingTeacherInvitations = false;
      }
    },

    setInvitationSearchByName: function (val) {
      this.invitationSearchBy = "name";
      this.invitationSearch = val;
    },

    setInvitationSearchByEmail: function (val) {
      this.invitationSearchBy = "email";
      this.invitationSearch = val;
    },

    async removeTeacherInvitationHandler(invitationId, userEmail, role) {
      this.loadingTeacherInvitations = true;
      try {
        await coursesAPI.deleteCourseInvitation(
          "my_customer",
          this.courseId,
          invitationId,
          userEmail,
          role,
        );

        this.invitedTeachers = this.invitedTeachers.filter(
          (item) => item.id !== invitationId,
        );
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingTeacherInvitations = false;
      }
    },

    async addTeacherInvitationHandler() {
      if (
        this.newTeacherInvitationEmail &&
        this.newTeacherInvitationEmail.email
      ) {
        this.loadingTeacherInvitations = true;
        try {
          const response = await coursesAPI.createCourseInvitation(
            "my_customer",
            this.courseId,
            this.newTeacherInvitationEmail.id,
            this.newTeacherInvitationEmail.email,
            this.newTeacherInvitationEmail.name,
            this.selectedRole,
          );
          if (response.data) {
            this.invitedTeachers.push(response.data);
          }
        } catch (error) {
          this.setPageError(error);
        } finally {
          this.newTeacherInvitationEmail = null;
          this.loadingTeacherInvitations = false;
        }
      }
    },

    async transferOwnership(teacherId) {
      const teacher = this.teachers.find((item) => item.id === teacherId);
      try {
        this.pageError = null;
        this.loadingCourseTeachers = true;
        if (teacher) {
          teacher.taskStatus = "pending";
        }
        await coursesAPI.transferOwnership(
          "my_customer",
          this.courseId,
          teacherId,
        );
        await this.getAllCourseTeachers();
      } catch (error) {
        if (teacher) {
          teacher.taskStatus = null;
        }
        this.setPageError(error);
        this.loadingCourseTeachers = false;
      }
    },

    removeTeacherHandler: function (email) {
      if (this.teachers.length < 2) {
        this.warningDialog = true;
      } else if (email) {
        this.loadingCourseTeachers = true;
        const teacher = this.teachers.find((item) => item.email === email);
        teacher.taskStatus = "pending";

        coursesAPI
          .deleteCourseTeacher("my_customer", this.courseId, email)
          .then((response) => {
            this.pageError = null;
            this.getTaskStatus(response.data.taskStatusId, (success) => {
              if (success) {
                this.teachers = this.teachers.filter(
                  (item) => item.email !== email,
                );
                this.getAllCourseTeachers();
                this.getAllCourseTeacherInvitations();
              } else {
                this.pageError = this.$t("action_failed_error");
                teacher.taskStatus = null;
                this.loadingCourseTeachers = false;
                this.getAllCourseTeachers();
                this.getAllCourseTeacherInvitations();
              }
            });
          })
          .catch((error) => {
            this.setPageError(error);
            teacher.taskStatus = null;
            this.loadingCourseTeachers = false;
          });
      }
    },

    async addTeacherHandler(email) {
      if (email && !this.teachers.find((item) => item.email === email)) {
        try {
          this.pageError = null;
          this.loadingAddTeacher = true;
          const teacher = { email: email, taskStatus: "pending" };
          this.teachers.push(teacher);
          await coursesAPI.addCourseTeacher(
            "my_customer",
            this.courseId,
            email,
          );
          await this.getAllCourseTeachers();
          this.getAllCourseTeacherInvitations();
        } catch (error) {
          this.teachers = this.teachers.filter((item) => item.email !== email);
          this.setPageError(error);
        } finally {
          this.newTeacherEmail = "";
          this.loadingAddTeacher = false;
        }
      }
      this.newTeacherEmail = "";
    },

    addGroupOfTeachersHandler: function (email) {
      if (email && !this.teachers.find((item) => item.email === email)) {
        this.loadingAddTeacher = true;
        const teacher = { email: email, taskStatus: "pending" };
        this.teachers.push(teacher);

        coursesAPI
          .addGroupOfMembersToCourse(
            "my_customer",
            this.courseId,
            email,
            "teachers",
          )
          .then((response) => {
            this.pageError = null;
            this.getTaskStatus(response.data.taskStatusId, (success) => {
              if (success) {
                this.getAllCourseTeachers();
                this.getAllCourseTeacherInvitations();
                this.loadingAddTeacher = false;
              } else {
                this.teachers = this.teachers.filter(
                  (item) => item.email !== email,
                );
                this.loadingAddTeacher = false;
                this.pageError = this.$t("action_failed_error");
                this.getAllCourseTeachers();
                this.getAllCourseTeacherInvitations();
              }
            });
          })
          .catch((error) => {
            this.setPageError(error);
            this.teachers = this.teachers.filter(
              (item) => item.email !== email,
            );
            this.loadingAddTeacher = false;
          });
      }
      this.newTeacherEmail = "";
    },

    setPageError(error) {
      if (error.response) {
        if (error.response.data.detail) {
          var detail = error.response.data.detail;
          if (
            detail === "unauthorized_client" ||
            detail == "unknown_customer"
          ) {
            this.pageError =
              "Error. You are not authorized to use this application. Application is not installed in gsuite marketplace for this customer. If the app is already installed, please make sure to grant access to the scopes. For more details follow the following steps- \n 1. From the Admin console Home page, go to Apps and then G Suite Marketplace apps. \n 2. Click the app's name-Online Partner Drive Admin Tool \n 3. Grant Access";
            this.handleUnauthorisedError();
          } else if (
            detail === "transport_error" ||
            detail == "invalid_token" ||
            detail == "session_expired"
          ) {
            this.signOut();
          } else {
            this.pageError = detail;
          }
          return;
        }
      }
      this.pageError = "action_failed_error";
    },
  },

  async created() {
    if (this.sessionActive) {
      this.courseId = this.$route.params.courseId;
      this.courseName = this.$route.params.courseName;
      await this.getOrphanedCourseOwnerDeletionTime();
      await this.getAllCourseTeachers();
      await this.getAllCourseTeacherInvitations();
    }
  },
};
</script>

<style>
.v-input__append-inner {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.fontcls {
  color: #464646 !important;
  font-size: 16px !important;
}
</style>
