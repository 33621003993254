import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );

  const messages = {};

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale.replace("_", "-")] = locales(key);
    }
  });

  return messages;
}

const messages = loadLocaleMessages();

const cachedLocale = () => {
  let locale = "en";

  try {
    locale = localStorage.getItem("locale");
  } catch (error) {
    // pass
  }

  return locale;
};

const locale = cachedLocale() || "en";

document.documentElement.setAttribute("lang", locale.split("-")[0]);

export default createI18n({
  allowComposition: true, // to use with Composition API
  globalInjection: true, // to inject in the component
  legacy: false, //so that VueI18n still works with Options API,
  warnHtmlMessage: false,
  locale,
  fallbackLocale: "en",
  messages,
});
