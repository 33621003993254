import { createWebHistory, createRouter } from "vue-router";

import Index from "../pages/index.vue";
import Teacher from "../pages/teacher.vue";
import Settings from "../pages/settings.vue";
import OrphanedCourses from "../pages/orphaned/orphanedCourses.vue";

import Admin from "../pages/admin/index.vue";
import CourseTeachers from "../pages/admin/_courseName/_courseTeachers.vue";
import CourseStudents from "../pages/admin/_courseName/_courseStudents.vue";

import Educator from "../pages/educator/index.vue";
import PrimaryEmail from "../pages/educator/_primaryEmail.vue";

import SubstituteTeacher from "../pages/substituteTeacher.vue";

import Manage from "../pages/manage/index.vue";
import ManageEducator from "../pages/manage/educators.vue";
import ManageSubstituteTeacher from "../pages/manage/substituteTeachers.vue";

import Help from "../pages/help/index.vue";
import HelpAdmin from "../pages/help/admin.vue";
import HelpEducator from "../pages/help/educator.vue";
import HelpTeacher from "../pages/help/teacher.vue";
import HelpManage from "../pages/help/manage.vue";
import HelpSubstituteTeacher from "../pages/help/substituteTeacher.vue";
import HelpOrphanedCourses from "../pages/help/orphanedCourses.vue";
import HelpSettings from "../pages/help/settings.vue";

import Unauthorised from "../pages/unauthorised.vue";
import Privacy from "../pages/Privacy.vue";

import store from "../store";

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    meta: {
      key: "Trelson Class Manager",
    },
  },
  {
    path: "/teacher",
    name: "Teacher",
    component: Teacher,
    meta: {
      key: "teacher",
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    meta: {
      key: "Privacy Policy",
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      key: "settings",
    },
  },

  {
    path: "/orphanedcourses",
    name: "orphaned-courses",
    component: OrphanedCourses,
    meta: {
      key: "orphaned_courses",
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      key: "admin",
    },
  },
  {
    path: "/admin/:courseName/:courseId/teachers",
    name: "admin-courseName-courseId-teachers",
    component: CourseTeachers,
    meta: {
      key: "admin",
    },
  },
  {
    path: "/admin/:courseName/:courseId/students",
    name: "admin-courseName-courseId-students",
    component: CourseStudents,
    meta: {
      key: "admin",
    },
  },

  {
    path: "/substituteteacher",
    name: "Substitute Teacher",
    component: SubstituteTeacher,
    meta: {
      key: "substitute_teacher",
    },
  },
  {
    path: "/educator/:primaryemail",
    name: "educator-primaryEmail",
    component: PrimaryEmail,
    meta: {
      key: "support_educator",
    },
  },
  {
    path: "/educator",
    name: "Support Educator",
    component: Educator,
    meta: {
      key: "support_educator",
    },
  },

  {
    path: "/manage",
    name: "Manage",
    component: Manage,
    meta: {
      key: "manage",
    },
  },
  {
    path: "/manage/educators",
    name: "manage-educators",
    component: ManageEducator,
    meta: {
      key: "manage",
    },
  },
  {
    path: "/manage/substituteteachers",
    name: "manage-substitute-teachers",
    component: ManageSubstituteTeacher,
    meta: {
      key: "manage",
    },
  },

  {
    path: "/help",
    name: "help",
    component: Help,
    meta: {
      key: "help",
    },
  },
  {
    path: "/help/admin",
    name: "help-admin",
    component: HelpAdmin,
    meta: {
      key: "help",
    },
  },
  {
    path: "/help/educator",
    name: "help-educator",
    component: HelpEducator,
    meta: {
      key: "help",
    },
  },
  {
    path: "/help/substituteteacher",
    name: "help-substituteteacher",
    component: HelpSubstituteTeacher,
    meta: {
      key: "help",
    },
  },
  {
    path: "/help/orphanedcourses",
    name: "help-orphanedcourses",
    component: HelpOrphanedCourses,
    meta: {
      key: "help",
    },
  },
  {
    path: "/help/settings",
    name: "help-settings",
    component: HelpSettings,
    meta: {
      key: "help",
    },
  },
  {
    path: "/help/teacher",
    name: "help-teacher",
    component: HelpTeacher,
    meta: {
      key: "help",
    },
  },
  {
    path: "/help/manage",
    name: "help-manage",
    component: HelpManage,
    meta: {
      key: "help",
    },
  },

  {
    path: "/unauthorised",
    name: "unauthorised",
    component: Unauthorised,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

if (window.location.host === "classroom-admin-tool.firebaseapp.com") {
  window.location = "https://classmanager.trelson.com";
}

router.beforeEach((to, from, next) => {
  if (
    to.name != "admin-courseName-courseId-teachers" &&
    to.name != "admin-courseName-courseId-students" &&
    to.name != "Admin"
  ) {
    if (store) {
      store.dispatch("setTempTeacherSearch", {
        teacherEmail: null,
      });
      store.dispatch("setTempStudentSearch", {
        studentEmail: null,
      });
    }
  }

  const rootPath = to.path.split("/")[1];

  if (store.state.userRole === "Teacher") {
    if (
      (rootPath === "admin" ||
        rootPath === "manage" ||
        rootPath === "educator" ||
        rootPath === "settings" ||
        rootPath === "substituteteacher" ||
        rootPath === "orphanedcourses") &&
      to.path !== "/teacher"
    ) {
      next({ to: "/teacher" });
      return;
    }
  } else if (store.state.userRole === "Support Substitute teacher") {
    if (
      (rootPath === "admin" ||
        rootPath === "manage" ||
        rootPath === "settings" ||
        rootPath === "orphanedcourses") &&
      to.path !== "/educator" &&
      to.path !== "/substituteteacher"
    ) {
      next({ to: "/educator" });
      return;
    }
  } else if (store.state.userRole === "Support educator") {
    if (
      (rootPath === "admin" ||
        rootPath === "manage" ||
        rootPath === "settings" ||
        rootPath === "substituteteacher" ||
        rootPath === "orphanedcourses") &&
      to.path !== "/educator"
    ) {
      next({ to: "/educator" });
      return;
    }
  } else if (store.state.userRole === "Substitute teacher") {
    if (
      (rootPath === "admin" ||
        rootPath === "manage" ||
        rootPath === "settings" ||
        rootPath === "educator" ||
        rootPath === "orphanedcourses") &&
      to.path !== "/substituteteacher"
    ) {
      next({ to: "/substituteteacher" });
      return;
    }
  } else if (store.state.userRole === "Admin") {
    if (
      (rootPath === "manage" || rootPath === "settings") &&
      to.path !== "/admin"
    ) {
      next({ to: "/admin" });
      return;
    }
  }

  next();
});

export default router;
