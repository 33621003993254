import api from "../plugins/api";

export const remove = async (customerId, connectionId) => {
  const data = await api.delete(
    `/customers/${customerId}/connections/${connectionId}`,
  );
  return data;
};

export const connectGroupToCourse = async (
  customerId,
  groupId,
  courseId,
  courseName,
  nameFromAdminSetting,
) => {
  const data = await api.post(`/customers/${customerId}/connections`, {
    group_id: groupId,
    course_id: courseId,
    course_name: courseName,
    name_from_admin_setting: nameFromAdminSetting,
  });
  return data;
};

export const syncGroupToCourse = async (customerId, connectionId) => {
  const data = await api.post(
    `/customers/${customerId}/connections/${connectionId}/sync`,
  );
  return data;
};

export const getTotalSyncedConnections = async (customerId) => {
  const data = await api.get(`/customers/${customerId}/connections/count`);
  return data;
};

export default {
  remove,
  connectGroupToCourse,
  syncGroupToCourse,
  getTotalSyncedConnections,
};
