<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        variant="text"
        icon
        size="small"
        :disabled="disabledAction"
        class="d-flex align-self-end"
      >
        <IconsSort :height="iconCompHeight" />
      </v-btn>
    </template>
    <v-list v-model:selected="selectedSorting" mandatory>
      <v-list-item
        v-for="(item, i) in sortingOption"
        :key="i"
        :value="i"
        color="primary"
      >
        <span
          class="fontcls"
          v-if="typeof item === 'object' && item !== null && item.name"
          >{{ item.name }}</span
        >
        <span class="fontcls" v-else>{{ item }}</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>
import IconsSort from "@/components/icons/sort";
import { ref, watch } from "vue";

const props = defineProps({
  disabledAction: {
    type: Boolean,
    default: false,
  },
  iconCompHeight: {
    type: [Number, String],
    default: 30,
  },
  sortingOption: {
    type: Array,
  },
  selectedSort: {
    type: Number,
    default: 0,
  },
});
const emit = defineEmits(["selectedSorting"]);

const selectedSorting = ref([props.selectedSort]);

watch(selectedSorting, async (val) => {
  emit("selectedSorting", val[0]);
});
</script>
