<template>
  <v-container class="pt-0 pr-0">
    <v-list bg-color="background" class="scrollistcls" id="scroll-conditions">
      <v-list-item
        width="fit-content"
        v-for="condition in filterGroupConditions"
        :key="condition.id"
        :disabled="disabledAction"
      >
        <template v-slot:prepend
          ><v-icon :style="disabledAction ? 'color: gray;' : ''"
            >mdi-account-group-outline</v-icon
          ></template
        >

        <div style="max-width: fit-content">
          <v-tooltip location="bottom" v-if="condition.tool">
            <template v-slot:activator="{ props }">
              <v-list-item-title v-bind="props" class="sfontcls">
                <span>{{ getFieldNameShort(condition.filter) }}</span>
                <v-icon v-if="condition.tool" size="small" class="mx-1"
                  >mdi-wrench-outline</v-icon
                >
                <span class="mx-1">{{
                  getOperatorShort(condition.operator)
                }}</span>

                <span>'{{ condition.textField }}'</span>
              </v-list-item-title>
            </template>
            <span v-if="condition.tool">{{
              getToolDescriptionShort(
                condition.tool.type,
                condition.tool.primary,
                condition.tool.secondary,
              )
            }}</span>
            <span v-else></span>
          </v-tooltip>

          <v-list-item-title v-else class="sfontcls">
            {{
              getFieldNameShort(condition.filter) +
              " " +
              getOperatorShort(condition.operator) +
              " " +
              condition.textField
            }}
          </v-list-item-title>
        </div>

        <template v-slot:append>
          <v-list-item-action class="pl-5">
            <TooltipButton
              iColor="#686868"
              iconName="mdi-pencil-outline"
              :tooltipMsg="$t('edit')"
              :disabledAction="loadingSettings || disabledAction"
              @action-handler="editCondition(condition.id)"
            />
          </v-list-item-action>

          <v-list-item-action class="pl-0">
            <TooltipButton
              iColor="#686868"
              iconName="mdi-delete-outline"
              :tooltipMsg="$t('remove')"
              :disabledAction="loadingSettings || disabledAction"
              @action-handler="deleteCondition(condition.id)"
            />
          </v-list-item-action>
        </template>
      </v-list-item>
    </v-list>

    <div v-if="filterGroupConditions.length === 0" class="mt-2 ml-5 mb-4">
      <span v-t="'no_data_found'" class="text-gray"></span>
    </div>
  </v-container>
</template>

<style>
.sfontcls {
  font-size: 14px !important;
  color: #464646 !important;
}
</style>

<script>
import TooltipButton from "@/components/tooltipButton";

export default {
  name: "conditionslist",

  components: {
    TooltipButton,
  },

  props: {
    filterGroupConditions: {
      type: Array,
    },

    loadingSettings: {
      type: Boolean,
      default: false,
    },

    disabledAction: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  emits: ["editCondition", "deleteCondition"],

  methods: {
    getFieldNameShort(fieldId) {
      // if customSchema it returns fieldId
      let fieldShorts = {
        // Group-field
        email: this.$t("email"),
        name: this.$t("name"),
      };
      return fieldId in fieldShorts ? fieldShorts[fieldId] : fieldId;
    },

    getOperatorShort(fieldId) {
      return (
        {
          "===": "=",
          "!==": "≠",
          contains: this.$t("contains"),
          containsNot: this.$t("contains") + " " + this.$t("not"),
          regex: this.$t("regex_passer"),
        }[fieldId] || this.$t("error_occured")
      );
    },

    getToolDescriptionShort(toolId, primary, secondary) {
      if (toolId === "Lowercase") {
        return this.$t("lowercase_rule_for_field");
      } else if (toolId === "Regex") {
        if (secondary) {
          return (
            this.$t("regex_pattern") +
            ": " +
            primary +
            " " +
            this.$t("applied_with_flags") +
            ": " +
            secondary
          );
        } else {
          return (
            this.$t("regex_pattern") + ": " + primary + " " + this.$t("applied")
          );
        }
      } else if (toolId === "Replace") {
        return (
          this.$t("replaces_each_text") +
          " " +
          primary +
          " " +
          this.$t("with") +
          " " +
          '"' +
          secondary +
          '"'
        );
      } else if (toolId === "Slice") {
        if (primary && secondary) {
          return (
            this.$t("slice_tooltip") +
            " " +
            primary +
            " " +
            this.$t("and") +
            " " +
            secondary +
            " " +
            this.$t("used")
          );
        } else if (primary) {
          return (
            this.$t("slice_tool_tip_p_1") +
            " " +
            primary +
            " " +
            this.$t("slice_tool_tip_p_2")
          );
        } else if (secondary) {
          return (
            this.$t("slice_tool_tip_s_1") +
            " " +
            secondary +
            " " +
            this.$t("used")
          );
        } else {
          return this.$t("error_occured");
        }
      } else if (toolId === "Split") {
        return (
          this.$t("split_tooltip_1") +
          " " +
          primary +
          " " +
          this.$t("whereupon") +
          " " +
          " " +
          this.getToolSplitName(secondary).toLowerCase() +
          " " +
          this.$t("split_tooltip_3")
        );
      } else {
        return this.$t("error_occured");
      }
    },

    getToolSplitName(toolValueId) {
      return (
        {
          first: this.$t("first"),
          last: this.$t("last"),
        }[toolValueId] || ""
      );
    },

    async editCondition(cId) {
      await this.$emit("editCondition", cId);
    },

    async deleteCondition(conditionId) {
      await this.$emit("deleteCondition", conditionId);
    },

    scrollConditions() {
      var container = this.$el.querySelector("#scroll-conditions");

      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
  },

  watch: {
    filterGroupConditions: {
      handler() {
        this.scrollConditions();
      },
      deep: true,
    },
  },
};
</script>
