import axios from "axios";
import store from "@/store";

export default axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
});

export const requestInterceptor = (config) => {
  const headerKeys = Object.keys(config.headers);
  if (!headerKeys.some((key) => key.toLowerCase() === "authorization")) {
    config.headers.authorization = `Bearer ${store.getters["sessionToken"]}`;
  }
  return config;
};
