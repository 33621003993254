<template>
  <v-container class="d-flex pb-0">
    <v-autocomplete
      :disabled="disabledAction"
      v-model="select"
      :loading="loading"
      :items="items"
      v-model:search="searchUserEmailFunc"
      item-title="primaryEmail"
      :item-value="(item) => item"
      density="compact"
      variant="outlined"
      :placeholder="placeholderEmail"
      hide-details
      :no-data-text="$t('no_data_available')"
      class="autocompcls mr-4 rounded-md"
      clearable
      prepend-inner-icon="mdi-magnify"
      color="secondary"
      style="width: 50%"
    />

    <v-autocomplete
      :disabled="disabledAction"
      v-model="select"
      :loading="loading"
      :items="items"
      v-model:search="searchUserNameFunc"
      item-title="fullName"
      :item-value="(item) => item"
      density="compact"
      variant="outlined"
      :placeholder="placeholderName"
      class="autocompcls rounded-md"
      hide-details
      :no-data-text="$t('no_data_available')"
      clearable
      prepend-inner-icon="mdi-magnify"
      color="secondary"
      style="width: 50%"
    />
  </v-container>
</template>

<script>
import studentsAPI from "@/api/students";
import { debounce } from "lodash";

export default {
  props: {
    disabledAction: {
      type: Boolean,
      default: false,
    },

    placeholderName: {
      type: String,
      default: "..",
    },

    placeholderEmail: {
      type: String,
      default: "..",
    },

    itemtext: {
      type: String,
      default: "primaryEmail",
    },
  },

  emits: ["select"],

  data() {
    return {
      loading: false,
      items: [],
      search: null,
      select: null,
      searchUserNameFunc: null,
      searchUserEmailFunc: null,
    };
  },

  watch: {
    searchUserEmailFunc: debounce(async function (val) {
      if (this.loading) return;

      if (val) {
        await this.querySelections(val);
      }
    }, 500),

    searchUserNameFunc: debounce(async function (val) {
      if (this.loading) return;

      if (val) {
        await this.querySelections(val);
      }
    }, 500),

    select(val) {
      this.$emit("select", val);
    },
  },

  methods: {
    querySelections(search) {
      this.loading = true;
      studentsAPI
        .get("my_customer", search)
        .then((response) => {
          this.items = response.data.students;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
