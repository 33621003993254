<template>
  <v-container>
    <v-alert v-model="alert" density="compact" closable type="error">
      {{ comPageError }}
    </v-alert>
  </v-container>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps(["pageError"]);
const emit = defineEmits(["on-dismiss"]);

const comPageError = computed(() => {
  if (t(props.pageError) != props.pageError) {
    return t(props.pageError);
  } else {
    return props.pageError;
  }
});

const alert = ref(true);

watch(alert, (current) => {
  if (current == false) {
    emit("on-dismiss", null);
  }
});
</script>
