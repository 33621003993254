import api from "../plugins/api";

export const get = async (
  customerId,
  nextPageToken,
  courseState,
  sortby,
  showFullCapabilityExpired,
) => {
  const data = await api.get(`/customers/${customerId}/orphanedcourses`, {
    params: {
      next_page_token: nextPageToken,
      course_state: courseState,
      show_full_capability_expired: showFullCapabilityExpired,
      sortby: sortby,
    },
  });
  return data;
};

export const scannow = async (customerId) => {
  const data = await api.get(
    `/customers/${customerId}/orphanedcourses/scannow`,
  );
  return data;
};

export const getTotalCount = async (customerId) => {
  const data = await api.get(`/customers/${customerId}/orphanedcourses/count`);
  return data;
};

export const getCourse = async (customerId, courseId) => {
  const data = await api.get(
    `/customers/${customerId}/orphanedcourses/${courseId}`,
  );
  return data;
};

export default {
  get,
  scannow,
  getTotalCount,
  getCourse,
};
