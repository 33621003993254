import api from "../plugins/api";

export const create = async (idToken) => {
  const { data } = await api.get("/", {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
  return data;
};

export default {
  create,
};
