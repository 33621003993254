<template>
  <v-container class="mb-12" style="max-width: 80%">
    <v-row class="my-15">
      <v-col cols="12" md="6">
        <span
          class="font-weight-extra-bold text-dark-teal"
          style="font-size: 30px"
        >
          {{ $t("teacher", 1) }}
        </span>
        <br /><br />
        <div class="freemium">
          <span v-html="$t('teacher_freemium_text')"></span>
        </div>
      </v-col>
    </v-row>

    <div class="d-flex mb-12">
      <v-img
        width="350px"
        src="../../../public/pages/teachersearchgroup.png"
        alt="teacher help image"
        class="mr-8"
        style="border: 1px solid lightgrey"
      />

      <v-img
        width="350px"
        src="../../../public/pages/teacherchoosecoursename.png"
        alt="teacher help image"
        class="mr-8"
        style="border: 1px solid lightgrey"
      />
    </div>

    <div class="d-flex mb-12">
      <v-img
        width="300px"
        src="../../../public/pages/teacher.png"
        alt="teacher help image"
        class="mr-8"
        style="border: 1px solid lightgrey"
      />

      <v-img
        width="300px"
        src="../../../public/pages/teachernewcourse.png"
        alt="teacher help image"
        style="border: 1px solid lightgrey"
      />
    </div>

    <div class="d-flex mb-12">
      <v-img
        width="300px"
        src="../../../public/pages/teacherprovisioned.png"
        alt="teacher help image"
        class="mr-8"
        style="border: 1px solid lightgrey"
      />

      <v-img
        width="300px"
        src="../../../public/pages/teacherarchived.png"
        alt="teacher help image"
        style="border: 1px solid lightgrey"
      />
    </div>

    <v-row class="d-flex justify-end">
      <v-btn
        height="30px"
        variant="outlined"
        class="mr-2 mb-16 rounded-md"
        color="primary"
        style="background-color: white"
        exact
        to="/help"
        ><span v-t="'back'"></span
      ></v-btn>
    </v-row>
  </v-container>
</template>
