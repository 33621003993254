<template>
  <v-container style="max-width: 80%">
    <div v-if="sessionActive && hasContacted">
      <Error
        :pageError="pageError"
        v-if="pageError"
        @on-dismiss="pageError = null"
      ></Error>

      <Headline
        :title="$t('course', 2)"
        color="dark-gray"
        iconName="mdi-account-multiple-outline"
        class="mt-10"
      >
        <v-spacer></v-spacer>

        <AutocompleteStudents
          :disabledAction="loadingNextPage || showMyCourses"
          :placeholderName="$t('teacher', 1) + ' ' + $t('name')"
          :placeholderEmail="$t('teacher', 1) + ' ' + $t('email')"
          @select="searchByTeacherEmail = $event ? $event.primaryEmail : null"
          style="width: 50%"
          class="mr-2 mb-1"
        ></AutocompleteStudents>

        <!-- Action: show my courses -->
        <v-checkbox
          v-model="showMyCourses"
          class="mr-1"
          color="primary"
          :disabled="loadingNextPage"
          hide-details
          style="height: 40px"
        >
          <template #label>
            <span style="font-size: 16px !important">
              {{ $t("show_my_courses") }}
            </span>
          </template>
        </v-checkbox>

        <!-- Action: Join course -->
        <v-btn
          color="primary"
          elevation="0"
          size="small"
          @click="
            addTeacherDialog = true;
            addedTill = null;
            errorCourseMessage = '';
          "
          class="d-flex align-self-end rounded-md mb-2 mr-4"
          :disabled="selected.length == 0"
          ><span v-t="'join_course'"></span
        ></v-btn>

        <!-- Action: leave course -->
        <v-btn
          color="primary"
          elevation="0"
          size="small"
          @click="
            removeTeacherDialog = true;
            errorCourseMessage = '';
          "
          class="d-flex align-self-end rounded-md mb-2 mr-1"
          :disabled="selected.length == 0"
        >
          <span v-t="'leave_course'"> </span>
        </v-btn>
      </Headline>

      <v-progress-linear
        v-if="loadingNextPage"
        indeterminate
        color="primary"
        height="2px"
      ></v-progress-linear>

      <!-- datatable for the list of courses -->
      <v-data-table
        :headers="headers"
        v-model="selected"
        :items="courses"
        :show-select="courses.length != 0"
        item-key="id"
        class="mydatatable bg-background"
        color="primary"
        return-object
        :items-per-page="-1"
        :loading-text="$t('loading_please_wait')"
        fixed-header
        height="550"
        :no-data-text="noDataText"
        :hover="true"
      >
        <!-- name -->
        <template v-slot:item.name="{ item }">
          <a
            target="_blank"
            class="text-dark-teal"
            :href="item.alternateLink"
            v-if="item.alternateLink"
          >
            {{ item.name }}
          </a>

          <a v-else class="text-dark-teal">{{ item.name }}</a>
        </template>

        <!-- addedTill -->
        <template v-slot:item.addedTill="{ item }">
          <span v-if="item.addedTill">
            {{ new Date(item.addedTill).toDateString() }}

            <!-- Display alert if the last scan has failed -->
            <TooltipButton
              v-if="isAddedTillExpired(item)"
              compStyle="margin-bottom: 4px;"
              iColor="red"
              iconName="mdi-alert"
              :tooltipMsg="$t('failed_to_remove_substitute_teacher_warning')"
            />
          </span>

          <span v-else>_</span>
        </template>

        <template v-slot:bottom></template>
      </v-data-table>

      <!-- load more for the next page of courses  -->
      <div class="d-flex justify-center">
        <LoadMore
          v-if="nextCursor"
          :loading="loadingNextPage"
          @next-page="getNextPage"
        ></LoadMore>
      </div>

      <!-- join course dialog  -->
      <DialogBox
        :dialog="addTeacherDialog"
        :title="$t('ask_to_confirm_join_course')"
        :actionName="$t('confirm')"
        :disabledAction="addTeacherConfirmDisabled"
        :disabledCancel="loadingNextPage"
        :loading="loadingNextPage"
        :dialogErrMsg="errorCourseMessage"
        dialogWidth="600px"
        @close-dialog="cancel()"
        @action-handler="addSubstituteTeacherToCourses()"
        @close-dialog-err-msg="errorCourseMessage = ''"
      >
        <div class="listcontent">
          <v-list class="pa-0" v-for="(course, index) in selected" :key="index">
            <v-list-item density="compact">
              <span class="fontcls">{{ course.name }}</span>
            </v-list-item>
          </v-list>
        </div>

        <v-divider></v-divider>

        <div class="d-flex align-center">
          <label
            ><span v-t="'join_course_till'" class="fontcls mr-1"></span
          ></label>

          <DatePickerComp
            :isDisabled="false"
            v-on:updateValue="addedTill = $event"
          ></DatePickerComp>
        </div>
      </DialogBox>

      <!-- leave course dialog  -->
      <DialogBox
        :dialog="removeTeacherDialog"
        :title="$t('ask_to_confirm_leave_course')"
        :actionName="$t('confirm')"
        :dialogErrMsg="errorCourseMessage"
        :disabledCancel="loadingNextPage"
        :loading="loadingNextPage"
        dialogWidth="600px"
        @close-dialog="cancel()"
        @action-handler="removeSubstituteTeacherFromCourses()"
        @close-dialog-err-msg="errorCourseMessage = ''"
      >
        <div class="listcontent">
          <v-list class="pa-0" v-for="(course, index) in selected" :key="index">
            <v-list-item density="compact">
              <span class="fontcls">{{ course.name }} </span>
            </v-list-item>
          </v-list>
        </div>
      </DialogBox>

      <SnackBar
        :text="alertStatusText"
        :color="alertStatusColor"
        v-if="alertStatus"
        @on-dismiss="
          alertStatus = false;
          alertStatusText = '';
        "
      ></SnackBar>
    </div>

    <Freemium
      v-else-if="sessionActive && !hasContacted"
      images="/substituteteacher.gif"
      :title="$t('substitute_teacher')"
      :bodyText="$t('sub_teacher_freemium_text')"
    ></Freemium>
  </v-container>
</template>

<style>
.v-input--checkbox .v-label {
  font-size: 20px;
}
.v-table.v-table--fixed-header > .v-table__wrapper > table > thead > tr > th {
  background: #5e9c96;
  color: white !important;
  font-size: 16px;
  font-weight: 500 !important;
}

.mdi-checkbox-marked {
  color: #5e9c96;
}

.mydatatable .v-data-table__th .mdi-checkbox-marked {
  color: white;
}
.v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td {
  border-bottom: 0;
}

.mdi-arrow-up::before {
  color: white;
}
.mdi-arrow-up::after {
  color: transparent;
}
</style>

<style scoped>
.listcontent {
  max-height: 300px;
  font-size: 16px;
  overflow-y: auto !important;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

import coursesAPI from "@/api/courses";
import AutocompleteStudents from "@/components/autocompleteStudents.vue";

import Error from "@/components/error.vue";
import Headline from "@/components/headline";
import DatePickerComp from "@/components/DatePickerComp";
import SnackBar from "@/components/snackBar.vue";
import DialogBox from "@/components/dialog.vue";
import LoadMore from "@/components/loadMoreButton";
import Freemium from "@/components/freemium.vue";
import TooltipButton from "@/components/tooltipButton";

export default {
  middleware: "authenticated-access",
  components: {
    Error,
    Headline,
    AutocompleteStudents,
    DatePickerComp,
    SnackBar,
    DialogBox,
    LoadMore,
    Freemium,
    TooltipButton,
  },

  data() {
    return {
      pageError: null,
      selected: [],
      courses: [],

      nextCursor: null,
      loadingNextPage: false,

      searchByTeacherEmail: "",
      searchByStudentEmail: "",

      showMyCourses: false,

      errorCourseMessage: "",

      addTeacherDialog: false,
      addedTill: null,

      removeTeacherDialog: false,

      alertStatus: false,
      alertStatusText: "",
      alertStatusColor: "black",
    };
  },

  computed: {
    ...mapGetters({
      sessionActive: "sessionActive",
      hasContacted: "hasContacted",
    }),

    noDataText() {
      if (this.loadingNextPage) {
        return this.$t("loading_please_wait");
      } else if (!this.searchByTeacherEmail && !this.showMyCourses) {
        return this.$t("search_tea_text");
      } else {
        return this.$t("no_data_found");
      }
    },

    headers() {
      if (this.courses.length == 0) {
        return [];
      } else {
        return [
          {
            title: "",
            key: "data-table-select",
            width: "4%",
          },
          {
            title: this.$t("name"),
            value: "name",
            align: "start",
            width: "10%",
            sortable: true,
          },
          {
            title: this.$t("added_till"),
            value: "addedTill",
            align: "start",
            width: "40%",
            sortable: true,
          },
        ];
      }
    },

    addTeacherConfirmDisabled() {
      if (
        this.addedTill == null ||
        this.addedTill == undefined ||
        this.addedTill == ""
      ) {
        return true;
      }

      return false;
    },
  },

  methods: {
    ...mapActions({
      handleUnauthorisedError: "handleUnauthorisedError",
      signOut: "signOut",
    }),

    async addSubstituteTeacherToCourses() {
      this.loadingNextPage = true;
      this.errorCourseMessage = "";
      this.pageError = null;
      var tempSingleCourseErrMsg = "";

      var selected = [];
      const scheduledPromises = [];

      for (let i = 0; i < this.selected.length; i++) {
        scheduledPromises.push(
          coursesAPI.addSubstituteTeacherToCourse(
            "my_customer",
            this.selected[i].id,
            this.selected[i].name,
            this.selected[i].alternateLink,
            this.addedTill,
          ),
        );
      }
      const scheduledResults = await Promise.allSettled(scheduledPromises);

      for (let i = 0; i < scheduledResults.length; i++) {
        const { status, value } = scheduledResults[i];

        if (status === "rejected") {
          selected.push(this.selected[i]);

          tempSingleCourseErrMsg = await this.getErrMsgFromRejectedPromise(
            scheduledResults[i],
          );
        }
        if (status === "fulfilled") {
          const course = this.courses.find((c) => c.id === this.selected[i].id);

          if (course) {
            course["addedTill"] = value.data.added_till;
            this.courses;
          }
        }
      }

      this.selected = selected;
      this.loadingNextPage = false;

      this.handleCourseActionError(
        tempSingleCourseErrMsg,
        this.$t("user_successfully_added"),
      );
    },

    async removeSubstituteTeacherFromCourses() {
      this.loadingNextPage = true;
      this.errorCourseMessage = "";
      this.pageError = null;
      var tempSingleCourseErrMsg = "";

      var selected = [];
      const scheduledPromises = [];

      for (let i = 0; i < this.selected.length; i++) {
        scheduledPromises.push(
          coursesAPI.removeSubstituteTeacherFromCourse(
            "my_customer",
            this.selected[i].id,
          ),
        );
      }
      const scheduledResults = await Promise.allSettled(scheduledPromises);

      for (let i = 0; i < scheduledResults.length; i++) {
        const { status } = scheduledResults[i];

        if (status === "rejected") {
          selected.push(this.selected[i]);

          tempSingleCourseErrMsg = await this.getErrMsgFromRejectedPromise(
            scheduledResults[i],
          );
        }
        if (status === "fulfilled") {
          const course = this.courses.find((c) => c.id === this.selected[i].id);

          if (course) {
            if (this.showMyCourses) {
              var index = this.courses.findIndex(function (c) {
                return c.id === course.id;
              });

              if (index !== -1) {
                this.courses.splice(index, 1);
              }
            } else {
              course["addedTill"] = null;
            }
            this.courses;
          }
        }
      }

      this.selected = selected;
      this.loadingNextPage = false;

      this.handleCourseActionError(
        tempSingleCourseErrMsg,
        this.$t("user_successfully_removed"),
      );
    },

    getErrMsgFromRejectedPromise(scheduledResult) {
      if (
        scheduledResult &&
        scheduledResult.reason &&
        scheduledResult.reason.response &&
        scheduledResult.reason.response.data
      ) {
        return scheduledResult.reason.response.data.detail;
      } else {
        return "";
      }
    },

    handleCourseActionError(tempSingleCourseErrMsg, successMsg) {
      if (this.selected.length === 0) {
        this.cancel();
        this.alertStatusText = successMsg;
        this.alertStatusColor = "#4BB543";
        this.alertStatus = true;
      } else if (this.selected.length == 1 && tempSingleCourseErrMsg) {
        this.errorCourseMessage = tempSingleCourseErrMsg;
      } else {
        this.errorCourseMessage = this.$t("action_failed_error");
      }
    },

    isAddedTillExpired(item) {
      if (item.addedTill) {
        try {
          var diff = new Date(item.addedTill).getTime() - new Date().getTime();
          if (diff / (1000 * 3600 * 24) < -1) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          return false;
        }
      } else {
        return false;
      }
    },

    cancel() {
      this.addTeacherDialog = false;
      this.errorCourseMessage = "";
      this.addedTill = null;
      this.removeTeacherDialog = false;
    },

    getFirstPage() {
      this.selected = [];
      this.nextCursor = null;
      this.courses = [];
      this.getNextPage();
    },

    async getNextPage() {
      this.loadingNextPage = true;

      try {
        this.pageError = null;
        var response;

        if (this.showMyCourses) {
          response = await coursesAPI.getMemberCourses(
            "my_customer",
            this.searchByTeacherEmail,
            this.searchByStudentEmail,
            this.nextCursor,
            true,
          );
        } else {
          response = await coursesAPI.getMemberCourses(
            "my_customer",
            this.searchByTeacherEmail,
            this.searchByStudentEmail,
            this.nextCursor,
            false,
          );
        }

        // Add response courses to datatable rows
        if (response.data.courses.length !== 0) {
          response.data.courses.forEach((course) => this.courses.push(course));
        }
        this.nextCursor = response.data.nextPageToken;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingNextPage = false;
      }
    },

    setPageError(error) {
      if (error.response) {
        if (error.response.data.detail) {
          var detail = error.response.data.detail;
          if (
            detail === "unauthorized_client" ||
            detail == "unknown_customer"
          ) {
            this.pageError =
              "Error. You are not authorized to use this application. Application is not installed in gsuite marketplace for this customer. If the app is already installed, please make sure to grant access to the scopes. For more details follow the following steps- \n 1. From the Admin console Home page, go to Apps and then G Suite Marketplace apps. \n 2. Click the app's name-Online Partner Drive Admin Tool \n 3. Grant Access";
            this.handleUnauthorisedError();
          } else if (
            detail === "transport_error" ||
            detail == "invalid_token" ||
            detail == "session_expired"
          ) {
            this.signOut();
          } else {
            this.pageError = detail;
          }
          return;
        }
      }
      this.pageError = "action_failed_error";
    },
  },

  watch: {
    async sessionActive(current) {
      if (current) {
        console.log("");
      }
    },

    async showMyCourses(val) {
      if (!this.searchByTeacherEmail && !this.searchByStudentEmail && !val) {
        this.courses = [];
        this.nextCursor = null;
        this.selected = [];
      } else {
        await this.getFirstPage();
      }
    },

    async searchByTeacherEmail(val) {
      if (
        (val != null && val != "") ||
        (this.searchByStudentEmail != null && this.searchByStudentEmail != "")
      ) {
        await this.getFirstPage();
      } else {
        this.courses = [];
        this.nextCursor = null;
        this.selected = [];
      }
    },

    async searchByStudentEmail(val) {
      if (
        (val != null && val != "") ||
        (this.searchByTeacherEmail != null && this.searchByTeacherEmail != "")
      ) {
        await this.getFirstPage();
      } else {
        this.courses = [];
        this.nextCursor = null;
        this.selected = [];
      }
    },
  },

  async created() {
    if (this.sessionActive) {
      console.log("");
    }
  },
};
</script>
