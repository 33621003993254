<template>
  <v-container style="max-width: 80%">
    <div v-if="sessionActive && hasContacted">
      <SnackBar
        :text="snackbarText"
        :color="snackBarColor"
        v-if="snackbar"
        @on-dismiss="
          snackbar = false;
          snackbarText = '';
        "
      ></SnackBar>

      <Error
        :pageError="pageError"
        v-if="pageError"
        @on-dismiss="pageError = null"
      ></Error>

      <manage
        :title="$t('substitute_teacher')"
        :list="substituteTeachers"
        :groupsList="substituteTeachersGroups"
        :usersNextPageToken="substituteTeachersNextPageToken"
        :groupsNextPageToken="substituteTeacherGroupsNextPageToken"
        :loadingItems="loadingItems"
        :loadingGroups="loadingGroups"
        @add-item="addSubstituteTeacherHandler"
        @add-group="addGroupHandler"
        @remove-item="removeSubstituteTeacher"
        @remove-group="removeSubstituteTeacherGroup"
        @fetch-users="getAllSubstituteTeachers"
        @fetch-groups="getAllSubstituteTeachersGroups"
        @get-searched-users="getSearchedSubstituteTeachers"
        @get-searched-groups="getSearchedSubstituteTeacherGroups"
        @get-user="getSubstituteTeacher"
        @get-group="getSubstituteTeacherGroup"
        @sort-users="sortSubstituteTeachers"
        @sort-groups="sortSubstituteTeacherGroups"
      />
    </div>

    <Freemium
      v-else-if="sessionActive && !hasContacted"
      images="/manageusers.gif"
      :title="$t('manage_users_freemium_title')"
      :bodyText="$t('manage_users_freemium_text')"
    >
    </Freemium>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import manage from "@/components/manage";
import roleAssignmentsAPI from "@/api/roleAssignments";

import Error from "@/components/error.vue";
import SnackBar from "@/components/snackBar.vue";
import Freemium from "@/components/freemium.vue";

export default {
  middleware: "authenticated-access",
  components: {
    manage,
    Error,
    SnackBar,
    Freemium,
  },

  data() {
    return {
      substituteTeachers: [],
      substituteTeachersGroups: [],
      pageError: null,
      loadingItems: false,
      loadingGroups: false,
      substituteTeachersNextPageToken: null,
      substituteTeacherGroupsNextPageToken: null,

      snackbar: false,
      snackbarText: "",
      snackBarColor: "black",
    };
  },

  computed: {
    ...mapGetters({
      sessionActive: "sessionActive",
      hasContacted: "hasContacted",
    }),
  },

  methods: {
    ...mapActions({
      handleUnauthorisedError: "handleUnauthorisedError",
      signOut: "signOut",
    }),

    async getAllSubstituteTeachers(sortUserBy) {
      this.loadingItems = true;
      try {
        const response = await roleAssignmentsAPI.getUsers(
          "my_customer",
          "Substitute teacher",
          this.substituteTeachersNextPageToken,
          sortUserBy,
        );
        this.pageError = null;
        const substituteTeachersResp = response.data.role_assignments;

        if (this.substituteTeachersNextPageToken == null) {
          this.substituteTeachers = substituteTeachersResp;
        } else {
          this.substituteTeachers = this.substituteTeachers.concat(
            substituteTeachersResp,
          );
        }
        this.substituteTeachersNextPageToken = response.data.next_page_token;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingItems = false;
      }
    },

    async getAllSubstituteTeachersGroups(sortGroupBy) {
      this.loadingGroups = true;
      try {
        const response = await roleAssignmentsAPI.getGroups(
          "my_customer",
          "Substitute teacher",
          this.substituteTeacherGroupsNextPageToken,
          sortGroupBy,
        );
        this.pageError = null;
        const substituteTeachersGroupsResp = response.data.role_assignments;

        if (this.substituteTeacherGroupsNextPageToken == null) {
          this.substituteTeachersGroups = substituteTeachersGroupsResp;
        } else {
          this.substituteTeachersGroups = this.substituteTeachersGroups.concat(
            substituteTeachersGroupsResp,
          );
        }

        this.substituteTeacherGroupsNextPageToken =
          response.data.next_page_token;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingGroups = false;
      }
    },

    async addSubstituteTeacherHandler(userId, email, sortGroupBy) {
      this.loadingItems = true;

      try {
        const response = await roleAssignmentsAPI.createUser(
          "my_customer",
          userId,
          email,
          "Substitute teacher",
        );
        if (response.status === 200) {
          this.pageError = null;
          this.substituteTeachersNextPageToken = null;
          await this.getAllSubstituteTeachers(sortGroupBy);
          this.snackbarText = this.$t("user_successfully_added");
          this.snackBarColor = "#4BB543";
          this.snackbar = true;
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingItems = false;
      }
    },

    async addGroupHandler(group_id, group_email, sortGroupBy) {
      this.loadingGroups = true;

      try {
        const response = await roleAssignmentsAPI.createGroup(
          "my_customer",
          group_id,
          group_email,
          "Substitute teacher",
        );
        if (response.status === 200) {
          this.pageError = null;

          this.substituteTeacherGroupsNextPageToken = null;
          await this.getAllSubstituteTeachersGroups(sortGroupBy);
          this.snackbarText = this.$t("group_successfully_added");
          this.snackBarColor = "#4BB543";
          this.snackbar = true;
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingGroups = false;
      }
    },

    async removeSubstituteTeacher(id, sortUserBy) {
      this.loadingItems = true;

      try {
        const response = await roleAssignmentsAPI.removeUser("my_customer", id);
        if (response.status === 200) {
          this.pageError = null;

          if (
            !this.substituteTeachersNextPageToken &&
            this.substituteTeachers.length <= 1
          ) {
            // deleting searched user
            this.substituteTeachersNextPageToken = null;
            await this.getAllSubstituteTeachers(sortUserBy);
          } else {
            // deleting direct user
            this.substituteTeachers = this.substituteTeachers.filter(
              (item) => item.id !== id,
            );
          }

          this.snackbarText = this.$t("substitute_teacher_deleted");
          this.snackBarColor = "#4BB543";
          this.snackbar = true;
        }
      } catch (error) {
        var detail = await this.setPageError(error);
        if (detail == "st_email_not_found") {
          this.pageError = "st_email_not_found";

          if (
            !this.substituteTeachersNextPageToken &&
            this.substituteTeachers.length <= 1
          ) {
            this.substituteTeachersNextPageToken = null;
            await this.getAllSubstituteTeachers(sortUserBy);
          } else {
            this.substituteTeachers = this.substituteTeachers.filter(
              (item) => item.id !== id,
            );
          }
        }
      } finally {
        this.loadingItems = false;
      }
    },

    async removeSubstituteTeacherGroup(id, sortGroupBy) {
      this.loadingGroups = true;

      try {
        const response = await roleAssignmentsAPI.removeGroup(
          "my_customer",
          id,
        );
        if (response.status === 200) {
          this.pageError = null;

          if (
            !this.substituteTeacherGroupsNextPageToken &&
            this.substituteTeachersGroups.length <= 1
          ) {
            this.substituteTeacherGroupsNextPageToken = null;
            await this.getAllSubstituteTeachersGroups(sortGroupBy);
          } else {
            this.substituteTeachersGroups =
              this.substituteTeachersGroups.filter((item) => item.id !== id);
          }
          this.snackbarText = this.$t("substitute_teacher_group_deleted");
          this.snackBarColor = "#4BB543";
          this.snackbar = true;
        }
      } catch (error) {
        var detail = await this.setPageError(error);
        if (detail == "st_email_not_found") {
          this.pageError = "st_email_not_found";

          if (
            !this.substituteTeacherGroupsNextPageToken &&
            this.substituteTeachersGroups.length <= 1
          ) {
            this.substituteTeacherGroupsNextPageToken = null;
            await this.getAllSubstituteTeachersGroups(sortGroupBy);
          } else {
            this.substituteTeachersGroups =
              this.substituteTeachersGroups.filter((item) => item.id !== id);
          }
        }
      } finally {
        this.loadingGroups = false;
      }
    },

    async getSubstituteTeacher(userId) {
      this.loadingItems = true;
      try {
        const response = await roleAssignmentsAPI.getUser(
          "my_customer",
          userId,
          "Substitute teacher",
        );

        if (response.data != null) {
          this.substituteTeachersNextPageToken = null;
          this.substituteTeachers = [
            {
              id: response.data.id,
              user_id: response.data.user_id,
              email: response.data.email,
              role: response.data.role,
            },
          ];
        } else {
          this.snackbarText = this.$t("st_email_not_found");
          this.snackBarColor = "#D22B2B";
          this.snackbar = true;
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingItems = false;
      }
    },

    async getSearchedSubstituteTeachers(sortUserBy) {
      this.substituteTeachersNextPageToken = null;
      await this.getAllSubstituteTeachers(sortUserBy);
    },

    async getSubstituteTeacherGroup(groupId) {
      this.loadingGroups = true;
      try {
        const response = await roleAssignmentsAPI.getGroup(
          "my_customer",
          groupId,
          "Substitute teacher",
        );

        if (response.data != null) {
          this.substituteTeacherGroupsNextPageToken = null;
          this.substituteTeachersGroups = [
            {
              id: response.data.id,
              group_id: response.data.group_id,
              email: response.data.email,
              role: response.data.role,
            },
          ];
        } else {
          this.snackbarText = this.$t("st_email_not_found");
          this.snackBarColor = "#D22B2B";
          this.snackbar = true;
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingGroups = false;
      }
    },

    async getSearchedSubstituteTeacherGroups(sortGroupBy) {
      this.substituteTeacherGroupsNextPageToken = null;
      await this.getAllSubstituteTeachersGroups(sortGroupBy);
    },

    async sortSubstituteTeachers(sortUserBy) {
      this.substituteTeachersNextPageToken = null;
      await this.getAllSubstituteTeachers(sortUserBy);
    },

    async sortSubstituteTeacherGroups(sortGroupBy) {
      this.substituteTeacherGroupsNextPageToken = null;
      await this.getAllSubstituteTeachersGroups(sortGroupBy);
    },

    setPageError(error) {
      if (error.response) {
        if (error.response.data.detail) {
          var detail = error.response.data.detail;
          if (
            detail === "unauthorized_client" ||
            detail == "unknown_customer"
          ) {
            this.pageError =
              "Error. You are not authorized to use this application. Application is not installed in gsuite marketplace for this customer. If the app is already installed, please make sure to grant access to the scopes. For more details follow the following steps- \n 1. From the Admin console Home page, go to Apps and then G Suite Marketplace apps. \n 2. Click the app's name-Online Partner Drive Admin Tool \n 3. Grant Access";
            this.handleUnauthorisedError();
          } else if (
            detail === "transport_error" ||
            detail == "invalid_token" ||
            detail == "session_expired"
          ) {
            this.signOut();
          } else if (detail == "email_not_found") {
            return "st_email_not_found";
          } else {
            this.pageError = detail;
          }
          return;
        }
      }
      this.pageError = "action_failed_error";
    },
  },

  watch: {
    async sessionActive(current) {
      if (current && this.hasContacted) {
        await this.getAllSubstituteTeachers("email");
        await this.getAllSubstituteTeachersGroups("email");
      }
    },
  },

  async created() {
    if (this.sessionActive && this.hasContacted) {
      await this.getAllSubstituteTeachers("email");
      await this.getAllSubstituteTeachersGroups("email");
    }
  },
};
</script>
