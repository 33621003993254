import api from "../plugins/api";

export const get = async (customerId, studentNameOrEmail, nextPageToken) => {
  const data = await api.get(`/customers/${customerId}/students`, {
    params: {
      student_name_or_email: studentNameOrEmail,
      nextPageToken: nextPageToken,
    },
  });
  return data;
};

export const getStudent = async (customerId, email) => {
  const data = await api.get(`/customers/${customerId}/students/get/${email}`);
  return data;
};

export const getStudentCourses = async (
  customerId,
  primaryEmail,
  nextPageToken,
) => {
  const data = await api.get(
    `/customers/${customerId}/support-educator/students/${primaryEmail}/courses`,
    {
      params: {
        nextPageToken: nextPageToken,
      },
    },
  );
  return data;
};

export const addBookmarkStudent = async (customerId, student_id) => {
  const data = await api.post(
    `/customers/${customerId}/support-educator/students/bookmarked`,
    {
      student_id: student_id,
    },
  );
  return data;
};

export const getBookmarkedStudent = async (customerId, student_id) => {
  const data = await api.get(
    `/customers/${customerId}/support-educator/students/bookmarked/${student_id}`,
  );
  return data;
};

export const removeBookmarkedStudent = async (customerId, student_id) => {
  const data = await api.delete(
    `/customers/${customerId}/support-educator/students/bookmarked/${student_id}`,
  );
  return data;
};

export const fetchAllBookmarkedStudents = async (
  customerId,
  nextPageToken,
  sortby,
) => {
  const data = await api.get(
    `/customers/${customerId}/support-educator/students/bookmarked`,
    {
      params: {
        next_page_token: nextPageToken,
        sortby: sortby,
      },
    },
  );
  return data;
};

export default {
  get,
  getStudent,
  getStudentCourses,
  addBookmarkStudent,
  getBookmarkedStudent,
  removeBookmarkedStudent,
  fetchAllBookmarkedStudents,
};
