<template>
  <v-container class="pt-0 pr-0">
    <v-list bg-color="background" class="scrollistcls" id="scroll-items">
      <v-list-item
        width="fit-content"
        v-for="item in filteredItemsList"
        :key="item.id"
        :disabled="disabledAction"
      >
        <template v-slot:prepend v-if="itemIcon">
          <v-icon :style="disabledAction ? 'color: gray;' : ''">{{
            itemIcon
          }}</v-icon>
        </template>

        <div :style="itemContentStyle">
          <v-list-item-title v-if="item.email" :class="itemTitleCls">{{
            item.email
          }}</v-list-item-title>

          <v-list-item-title
            v-else-if="item.primaryEmail"
            :class="itemTitleCls"
            >{{ item.primaryEmail }}</v-list-item-title
          >

          <v-list-item-title v-else-if="item.userEmail" :class="itemTitleCls">{{
            item.userEmail
          }}</v-list-item-title>
        </div>

        <template v-slot:append>
          <v-list-item-action>
            <slot name="listaction" :item="item" />
          </v-list-item-action>
        </template>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<style>
.scrollistcls {
  overflow-y: auto;
  max-height: 250px;
  scroll-behavior: smooth;
  padding: 0px;
}
</style>

<script>
export default {
  name: "itemlist",
  props: {
    filteredItemsList: {
      type: Array,
    },

    disabledAction: {
      type: Boolean,
      default: false,
    },

    itemIcon: {
      type: String,
      default: null,
    },

    itemContentStyle: {
      type: String,
      default: "width: 320px; height: fit-content",
    },

    itemTitleCls: {
      type: String,
      default: "sfontcls",
    },
  },

  methods: {
    scrollitems() {
      var container = this.$el.querySelector("#scroll-items");
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
  },

  watch: {
    filteredItemsList: {
      handler() {
        this.scrollitems();
      },
      deep: true,
    },
  },
};
</script>
