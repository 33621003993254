<template>
  <v-container style="max-width: 80%">
    <div v-if="sessionActive && hasContacted">
      <Error
        :pageError="pageError"
        v-if="pageError"
        @on-dismiss="pageError = null"
      ></Error>

      <SnackBar
        :text="alertInvitedText"
        color="#4BB543"
        v-if="alertInvited"
        @on-dismiss="
          alertInvited = false;
          alertInvitedText = '';
        "
      ></SnackBar>

      <Headline
        class="mt-10"
        :title="$t('my') + ' ' + 'Classroom'"
        color="dark-gray"
        iconName="mdi-account-group-outline"
      >
        <v-spacer></v-spacer>

        <!-- TODO CAT-237  -->
        <!-- <JsonClass
          @refresh-courses="getCourses()"
          @set-page-error="setPageError"
        >
        </JsonClass> -->

        <SearchGroupDialog
          buttonIcon="mdi-plus"
          :buttonName="$t('create') + ' ' + 'Classroom'"
          :title="$t('choose') + ' ' + $t('group', 1)"
          :loadingGroups="loadingGroups"
          :groups="groups"
          :groupsNextPageToken="groupsNextPageToken"
          @refresh="getGroups"
          :newCourse="true"
          @groups-next-page="getgroupsNextPage"
          @attach-course-handler="attachCourseHandler"
          :forceCourseNameTool="forceCourseNameTool"
          :allowMultipleCourseConnections="allowMultipleCourseConnections"
          :groupsConnInProgress="groupsConnInProgress"
        ></SearchGroupDialog>
      </Headline>

      <v-progress-linear
        v-if="loadingCourses"
        indeterminate
        color="primary"
        height="2px"
      ></v-progress-linear>

      <div class="d-flex flex-column">
        <v-chip-group
          v-model="courseState"
          selected-class="bg-secondary"
          mandatory
        >
          <v-chip
            :disabled="loadingCourses"
            color="light-gray"
            class="text-dark-teal text-subtitle-2 rounded-pill"
            v-for="(state, index) in courseStates"
            :key="index"
            :value="state.value"
          >
            {{ $t(state.name) }}
          </v-chip>
        </v-chip-group>

        <div
          class="d-flex ml-16 mt-2"
          v-if="courses.length != 0 && courseState != 'ARCHIVED'"
        >
          <v-alert
            v-if="autoArchiveCoursesAt && !hasAutoArchivalOutdated"
            density="compact"
            type="info"
            class="d-flex justify-center flex-nowrap"
          >
            {{
              $t("auto_archive_warning", {
                coursetype: $t("all").toLowerCase(),
                datefield: new Date(autoArchiveCoursesAt).toLocaleDateString(
                  locale,
                  options,
                ),
              }) +
              " " +
              $t("archive_connected_course_warning")
            }}
          </v-alert>

          <v-alert
            v-else-if="
              autoArchiveConnectedCoursesAt && !hasAutoConnectedArchivalOutdated
            "
            density="compact"
            type="info"
            class="d-flex justify-center flex-nowrap"
          >
            {{
              $t("auto_archive_warning", {
                coursetype: $t("connected").toLowerCase(),
                datefield: new Date(
                  autoArchiveConnectedCoursesAt,
                ).toLocaleDateString(locale, options),
              }) +
              " " +
              $t("archive_connected_course_warning")
            }}
          </v-alert>
        </div>
      </div>

      <NoDataText
        :itemLoading="loadingCourses"
        :nextPageToken="nextPageToken"
        :items="courses"
      ></NoDataText>

      <v-row class="d-flex justify-center mb-0 pb-0">
        <v-col cols="12" class="d-flex flex-wrap">
          <CardGrid v-for="item in courses" :key="item.courseId" :item="item">
            <template v-slot:title>
              <div class="d-flex justify-space-between" style="width: 465px">
                <div
                  class="cardtitle d-flex"
                  style="width: 330px"
                  v-if="item.alternateLink"
                >
                  <v-tooltip
                    v-if="item.alternateLink"
                    location="bottom"
                    color="black"
                    class="text-white"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        v-if="item.alternateLink"
                        variant="text"
                        :href="item.alternateLink"
                        target="_blank"
                        class="classlink text-dark-teal"
                        style="letter-spacing: 0.3px !important"
                      >
                        {{ item.courseName }}
                      </v-btn>
                    </template>
                    <span v-t="'open_course_in_google'"></span>
                  </v-tooltip>
                </div>
                <span class="cardtitle" style="width: 330px" v-else>{{
                  item.courseName
                }}</span>

                <v-btn
                  size="small"
                  variant="text"
                  class="archivecls pa-0 text-dark-gray"
                  :title="
                    item.whitelistFromAutoArchival
                      ? $t('unwhitelist_tooltip')
                      : $t('whitelist_tooltip')
                  "
                  :disabled="loadingCourses"
                  v-if="
                    (autoArchiveConnectedCoursesAt
                      ? item.connectionId
                      : autoArchiveCoursesAt) &&
                    showArchiveBtn &&
                    item.courseState != 'ARCHIVED'
                  "
                  @click="
                    whitelistFromAutoArchival(
                      item.courseId,
                      item.courseName,
                      item.alternateLink,
                      item.ownerId,
                      item.whitelistFromAutoArchival,
                    )
                  "
                >
                  <span v-t="'to_be_archived'"></span>
                  <v-icon
                    color="primary"
                    size="26"
                    v-if="item.whitelistFromAutoArchival"
                    >mdi-bookmark-outline</v-icon
                  >
                  <v-icon color="primary" size="26" v-else
                    >mdi-bookmark-check</v-icon
                  ></v-btn
                >
              </div>

              <span class="desctext">
                {{ item.courseSection }}
              </span>
            </template>

            <template v-slot:actions>
              <!-- Connected course actions  -->
              <div v-if="item.connectionId" class="d-flex">
                <div class="d-flex align-center ml-1 mr-1">
                  <v-icon size="30" color="black">mdi-link</v-icon>
                </div>

                <div class="d-flex flex-wrap">
                  <span class="cardtitle text-dark-gray mr-1"
                    >{{ item.groupName }}
                  </span>
                </div>
              </div>

              <!-- Unconnected course actions  -->
              <v-icon size="30" class="pl-2 pr-0 mr-0" color="black" v-else
                >mdi-link-off</v-icon
              >

              <v-spacer></v-spacer>

              <v-progress-circular
                v-if="item.taskStatus"
                indeterminate
                size="30"
                color="primary"
                class="mr-2"
              ></v-progress-circular>

              <div v-else class="d-flex flex-unwrap">
                <SearchGroupDialog
                  v-if="!item.connectionId && item.courseState == 'ACTIVE'"
                  :groups="groups"
                  :groupsNextPageToken="groupsNextPageToken"
                  :loadingGroups="loadingGroups"
                  buttonIcon="mdi-plus"
                  :buttonName="$t('attach')"
                  :title="$t('choose') + ' ' + $t('group', 1)"
                  @refresh="getGroups"
                  :existingCourseName="item.courseName"
                  :courseId="item.courseId"
                  @attach-course-handler="attachCourseHandler"
                  @groups-next-page="getgroupsNextPage"
                  :forceCourseNameTool="forceCourseNameTool"
                  :allowMultipleCourseConnections="
                    allowMultipleCourseConnections
                  "
                  :groupsConnInProgress="groupsConnInProgress"
                ></SearchGroupDialog>

                <!-- Activate the provisioned/archived course  -->

                <TooltipButton
                  v-else-if="
                    item.courseState == 'PROVISIONED' ||
                    item.courseState == 'ARCHIVED'
                  "
                  :buttonName="$t('activate')"
                  :tooltipMsg="$t('activate_course_tooltip')"
                  :disabledAction="loadingCourses"
                  compStyle="margin-right: 8px;"
                  @action-handler="
                    changeCourseState(
                      item.courseId,
                      item.courseName,
                      'ACTIVE',
                      $t('course_successfully_activated_msg'),
                    )
                  "
                />

                <!-- detach the connected course  -->
                <TooltipButton
                  v-if="item.connectionId"
                  :buttonName="$t('detach')"
                  :tooltipMsg="$t('detach_connection')"
                  :loading="
                    item.detachTaskStatus != null &&
                    item.detachTaskStatus != undefined
                  "
                  compStyle="margin-right: 8px;"
                  @action-handler="getDetachConfirmation(item.connectionId)"
                />

                <!-- TODO: CAT-146 code to sync group with course  -->
                <!-- <v-btn
                  v-if="item.connectionId"
                  variant="plain"
                  icon
                  class="pa-0 ml-0"
                  size="small"
                  @click="syncCourseHandler(item.connectionId)"
                >
                  <v-icon class="mb-2 ml-0 pa-0" size="large"
                    >mdi-refresh</v-icon
                  >
                </v-btn> -->
              </div>
            </template>
          </CardGrid>
        </v-col>

        <LoadMore
          v-if="nextPageToken"
          :loading="loadingCourses"
          @next-page="getCoursesNextPage"
        ></LoadMore>
      </v-row>
    </div>

    <Freemium
      v-else-if="sessionActive && !hasContacted"
      images="/teacher.gif"
      :title="$t('teacher', 1)"
      :bodyText="$t('teacher_freemium_text')"
    ></Freemium>

    <DialogBox
      :dialog="detachConfirmDialog"
      :actionName="$t('confirm')"
      :loading="loadingCourses"
      dialogWidth="600px"
      @close-dialog="cancelDetach()"
      @action-handler="detachCourseHandler(detachItem)"
    >
      <span v-t="'detach_connection_ask_confirmation'"></span>
    </DialogBox>

    <vue3-confirm-dialog></vue3-confirm-dialog>
  </v-container>
</template>
<style>
.classlink span.v-btn__content {
  width: 330px !important;
  justify-content: start;
}

.desctext {
  margin-left: 1px;
  margin-top: 3px;
  line-height: 1.25rem;
  color: black;
  max-width: 400px;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 8px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>

<style scoped>
.classlink {
  text-transform: none;
  font-size: 16px;
  width: 330px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-top: 6px;
  height: fit-content !important;
}

.cardtitle {
  font-size: 16px;
}

.archivecls {
  border: None;
  text-transform: none;
  margin-top: 3px;
  letter-spacing: 0.3px !important;
  font-size: 13px;
}

.text-subtitle-2 {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 400 !important;
}
</style>

<script setup>
import { ref, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

import groupsAPI from "@/api/groups";
import coursesAPI from "@/api/courses";
import connectionsAPI from "@/api/connections";
import settingsAPI from "@/api/customerSettings";
import useGetTaskStatus from "../composables/getTaskStatus";

import Error from "@/components/error.vue";
import Headline from "@/components/headline";
import CardGrid from "@/components/cardGrid";
import SearchGroupDialog from "@/components/searchGroupDialog";
import LoadMore from "@/components/loadMoreButton";
import SnackBar from "@/components/snackBar.vue";
import TooltipButton from "@/components/tooltipButton";
import NoDataText from "@/components/noDataText.vue";
import Freemium from "@/components/freemium.vue";
// import JsonClass from "@/components/jsonclass.vue";
import DialogBox from "@/components/dialog.vue";

const store = useStore();
const { t, locale } = useI18n();
const { getTaskStatus } = useGetTaskStatus();

const pageError = ref(null);
const forceCourseNameTool = ref(false);
const allowMultipleCourseConnections = ref(false);
const autoArchiveCoursesAt = ref(null);
const autoArchiveConnectedCoursesAt = ref(null);
const loadingCourses = ref(false);
const courses = ref([]);
const nextPageToken = ref(null);

const loadingGroups = ref(false);
const groups = ref([]);
const groupsNextPageToken = ref(null);
const groupsConnInProgress = ref([]);

const courseState = ref("ACTIVE");
const courseStates = reactive([
  { name: "active", value: "ACTIVE" },
  { name: "provisioned", value: "PROVISIONED" },
  { name: "archived", value: "ARCHIVED" },
]);

const alertInvited = ref(false);
const alertInvitedText = ref("");
const options = reactive({
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
});

const detachConfirmDialog = ref(false);
const detachItem = ref(null);

const sessionActive = computed(() => store.getters.sessionActive);
const hasContacted = computed(() => store.getters.hasContacted);
const enabledScan = computed(() => store.getters.enabledScan);

const hasAutoArchivalOutdated = computed(() => {
  var today = new Date().setHours(0, 0, 0, 0);
  var archivalDate = new Date(autoArchiveCoursesAt.value).setHours(0, 0, 0, 0);

  if (archivalDate < today) {
    return true;
  }

  return false;
});

const hasAutoConnectedArchivalOutdated = computed(() => {
  var today = new Date().setHours(0, 0, 0, 0);
  var archivalDate = new Date(autoArchiveConnectedCoursesAt.value).setHours(
    0,
    0,
    0,
    0,
  );

  if (archivalDate < today) {
    return true;
  }

  return false;
});

const showArchiveBtn = computed(() => {
  if (autoArchiveCoursesAt.value && !hasAutoArchivalOutdated.value) {
    return true;
  } else if (
    autoArchiveConnectedCoursesAt.value &&
    !hasAutoConnectedArchivalOutdated.value
  ) {
    return true;
  } else {
    return false;
  }
});

watch(sessionActive, async (current) => {
  if (current && hasContacted.value) {
    await getSettings();
    await getCourses();
    await getGroups();
  }
});

watch(pageError, async (val) => {
  if (val) {
    window.scrollTo(0, 0);
  }
});

watch(courseState, async () => {
  await getCourses();
});

onMounted(async () => {
  if (sessionActive.value && hasContacted.value) {
    await getSettings();
    await getCourses();
    await getGroups();
  }
});

const handleUnauthorisedError = () => store.dispatch("handleUnauthorisedError");
const signOut = () => store.dispatch("signOut");

const getSettings = async () => {
  loadingCourses.value = true;
  try {
    const response = await settingsAPI.getSettings("my_customer");
    forceCourseNameTool.value = response.data.force_course_name_tool;
    allowMultipleCourseConnections.value =
      response.data.allow_multiple_course_connections;

    if (enabledScan.value) {
      autoArchiveCoursesAt.value = response.data.auto_archive_courses_at;
    }
    autoArchiveConnectedCoursesAt.value =
      response.data.auto_archive_connected_courses_at;
  } catch (error) {
    setPageError(error);
  } finally {
    loadingCourses.value = false;
  }
};

const setPageError = async (error) => {
  if (error.response) {
    if (error.response.data && error.response.data.detail) {
      var detail = error.response.data.detail;
      if (detail === "unauthorized_client" || detail == "unknown_customer") {
        pageError.value =
          "Error. You are not authorized to use this application. Application is not installed in gsuite marketplace for this customer. If the app is already installed, please make sure to grant access to the scopes. For more details follow the following steps- \n 1. From the Admin console Home page, go to Apps and then G Suite Marketplace apps. \n 2. Click the app's name-Online Partner Drive Admin Tool \n 3. Grant Access";
        handleUnauthorisedError();
      } else if (
        detail === "transport_error" ||
        detail == "invalid_token" ||
        detail == "session_expired"
      ) {
        signOut();
      } else {
        pageError.value = detail;
      }
      return;
    }
  }
  pageError.value = "action_failed_error";
};

const getCourses = async () => {
  loadingCourses.value = true;
  try {
    const {
      data: {
        unconnectedCourses = [],
        connectedCourses = [],
        nextPageToken: nextPage,
      },
    } = await coursesAPI.get({
      customerId: "my_customer",
      me: true,
      courseState: courseState.value,
    });

    const resp = unconnectedCourses.concat(connectedCourses);
    nextPageToken.value = nextPage;
    courses.value = resp;
  } catch (error) {
    setPageError(error);
  } finally {
    loadingCourses.value = false;
  }
};

const whitelistFromAutoArchival = async (
  courseId,
  courseName,
  alternateLink,
  ownerId,
  currentWhitelistStatus,
) => {
  loadingCourses.value = true;
  try {
    await coursesAPI.whitelistFromAutoArchival(
      "my_customer",
      courseId,
      courseName,
      alternateLink,
      ownerId,
      !currentWhitelistStatus,
    );

    const item = courses.value.find((c) => c.courseId === courseId);

    if (item) {
      item.whitelistFromAutoArchival = !currentWhitelistStatus;
    }
  } catch (error) {
    setPageError(error);
  } finally {
    loadingCourses.value = false;
  }
};

const getGroups = async (
  search = null,
  selectedSorting = "ASC",
  showDisabled = false,
) => {
  loadingGroups.value = true;
  groupsAPI
    .getTeacherGroups({
      customerId: "my_customer",
      me: true,
      name_or_email: search,
      sorterOrder: selectedSorting,
      showDisabled: showDisabled,
    })
    .then(
      ({
        data: {
          unconnectedGroups = [],
          connectedGroups = [],
          nextPageToken: nextPage,
        },
      }) => {
        groups.value = unconnectedGroups.concat(connectedGroups);
        groupsNextPageToken.value = nextPage;
        loadingGroups.value = false;
      },
    )
    .catch((error) => {
      setPageError(error);
      loadingGroups.value = false;
    });
};

const getgroupsNextPage = async (
  search = null,
  selectedSorting = "ASC",
  showDisabled = false,
) => {
  pageError.value = null;
  loadingGroups.value = true;

  groupsAPI
    .getTeacherGroups({
      customerId: "my_customer",
      me: true,
      name_or_email: search,
      nextPageToken: groupsNextPageToken.value,
      sorterOrder: selectedSorting,
      showDisabled: showDisabled,
    })
    .then(
      ({
        data: {
          unconnectedGroups = [],
          connectedGroups = [],
          nextPageToken: nextPage,
        },
      }) => {
        groups.value = groups.value.concat(
          unconnectedGroups.concat(connectedGroups),
        );

        groupsNextPageToken.value = nextPage;
        loadingGroups.value = false;
      },
    )
    .catch((error) => {
      setPageError(error);
      loadingGroups.value = false;
    });
};

const getCoursesNextPage = async () => {
  pageError.value = null;
  loadingCourses.value = true;

  coursesAPI
    .get({
      customerId: "my_customer",
      me: true,
      courseState: courseState.value,
      nextPageToken: nextPageToken.value,
    })
    .then(
      ({
        data: {
          connectedCourses = [],
          unconnectedCourses = [],
          nextPageToken: nextPage = null,
        },
      }) => {
        courses.value = courses.value.concat(
          unconnectedCourses.concat(connectedCourses),
        );
        nextPageToken.value = nextPage;
        loadingCourses.value = false;
      },
    )
    .catch((error) => {
      setPageError(error);
      loadingCourses.value = false;
    });
};

const attachCourseHandler = async (
  courseId,
  groupId,
  courseName,
  nameFromAdminSetting,
) => {
  pageError.value = null;
  if (groupId && courseName) {
    var course = null;
    // if attach action
    if (courseId) {
      course = courses.value.find((item) => item.courseId === courseId);
      if (course) {
        course.taskStatus = "pending";
      }
    }
    loadingCourses.value = true;

    const connectingGroup = groups.value.find(
      (item) => item.groupId === groupId,
    );

    // remove the connecting group if allow multiple connections is disabled
    if (!allowMultipleCourseConnections.value) {
      groupsConnInProgress.value.push(groupId);
      groups.value = groups.value.filter((item) => item.groupId !== groupId);
    }

    await connectionsAPI
      .connectGroupToCourse(
        "my_customer",
        groupId,
        courseId,
        courseName,
        nameFromAdminSetting,
      )
      .then((response) => {
        // if create action
        if (!course) {
          courseId = response.data.courseId;

          if (courseId) {
            course = {
              courseId: response.data.courseId,
              courseName: response.data.courseName,
              courseState: "ACTIVE",
              taskStatus: "pending",
              groupId: null,
              groupName: null,
              connectionId: null,
              whitelistFromAutoArchival: false,
            };
            courses.value.push(course);
          }
        }

        getTaskStatus(response.data.taskStatusId, (success) => {
          if (success) {
            if (courseId) {
              const connCourse = courses.value.find(
                (item) => item.courseId === courseId,
              );

              if (connCourse) {
                connCourse.groupId = groupId;
                connCourse.groupName = connectingGroup
                  ? connectingGroup.groupName
                  : "unknown group";
                connCourse.taskStatus = null;
              }
            }
            groupsConnInProgress.value = groupsConnInProgress.value.filter(
              (g) => g !== groupId,
            );
            getCourses();
          } else {
            if (courseId) {
              const connCourse = courses.value.find(
                (item) => item.courseId === courseId,
              );
              if (connCourse) {
                connCourse.taskStatus = null;
              }
            }

            groupsConnInProgress.value = groupsConnInProgress.value.filter(
              (g) => g !== groupId,
            );

            loadingCourses.value = false;
            pageError.value = t("action_failed_error");
            getCourses();
          }
        });
      })
      .catch((error) => {
        groupsConnInProgress.value = groupsConnInProgress.value.filter(
          (g) => g !== groupId,
        );
        setPageError(error);

        if (courseId) {
          const connCourse = courses.value.find(
            (item) => item.courseId === courseId,
          );
          if (connCourse) {
            connCourse.taskStatus = null;
          }
        }
        loadingCourses.value = false;
      });
  }
};

const detachCourseHandler = async (connectionId) => {
  detachConfirmDialog.value = false;
  if (connectionId) {
    loadingCourses.value = true;
    const course = courses.value.find(
      (item) => item.connectionId === connectionId,
    );
    course.detachTaskStatus = "pending";
    pageError.value = null;

    await connectionsAPI
      .remove("my_customer", connectionId)
      .then((response) => {
        if (response.data === "OK") {
          course.connectionId = null;
          course.groupName = null;
          course.groupId = null;
          course.detachTaskStatus = null;
          loadingCourses.value = false;
          getGroups();
        } else {
          course.detachTaskStatus = null;
          loadingCourses.value = false;
        }
      })
      .catch((error) => {
        setPageError(error);
        course.detachTaskStatus = null;
        loadingCourses.value = false;
      });
  }
  detachItem.value = null;
};

const cancelDetach = () => {
  detachConfirmDialog.value = false;
  detachItem.value = null;
};

const getDetachConfirmation = (connectionId) => {
  detachItem.value = connectionId;
  detachConfirmDialog.value = true;
};

// TODO: CAT-146 sync feature currently removed, may be added in future
// const syncCourseHandler = async (connectionId) => {
//   pageError.value = null;
//   if (connectionId) {
//     const course = courses.value.find(
//       (item) => item.connectionId === connectionId
//     );
//     if (course) {
//       course.taskStatus = "pending";
//     }

//     loadingCourses.value = true;

//     connectionsAPI
//       .syncGroupToCourse("my_customer", connectionId)
//       .then((response) => {
//         if (response.data !== "Already synced") {
//           getTaskStatus(response.data.taskStatusId, (success) => {
//             if (success) {
//               getCourses();
//               console.log("Done");
//             }
//           });
//         } else {
//           loadingCourses.value = false;
//           pageError.value = t("action_failed_error");
//           getCourses();
//         }
//       })
//       .catch((error) => {
//         setPageError(error);

//         if (course) {
//           course.taskStatus = null;
//         }

//         loadingCourses.value = false;
//         getCourses();
//       })
//       .finally(() => {
//         if (course) {
//           course.taskStatus = null;
//         }
//       });
//   }
// };

const changeCourseState = async (
  courseId,
  courseName,
  courseStateTo,
  successMsg,
) => {
  loadingCourses.value = true;
  pageError.value = null;

  try {
    const response = await coursesAPI.changeCourseState(
      "my_customer",
      courseId,
      courseName,
      courseStateTo,
    );
    if (response.data && response.data.courseState == courseStateTo) {
      alertInvitedText.value = successMsg;
      alertInvited.value = true;

      courses.value = courses.value.filter(
        (item) => item.courseId !== courseId,
      );

      await getCourses();
    }
  } catch (error) {
    setPageError(error);
  } finally {
    loadingCourses.value = false;
  }
};
</script>
