import api from "../plugins/api";

export const getUsers = async (customerId, role, nextPageToken, sortby) => {
  const data = await api.get(`/customers/${customerId}/roleassignments/users`, {
    params: {
      role: role,
      next_page_token: nextPageToken,
      sortby: sortby,
    },
  });
  return data;
};

export const createUser = async (customerId, userId, email, role) => {
  const data = await api.post(
    `/customers/${customerId}/roleassignments/users/${userId}`,
    {
      role: role,
      email: email,
    },
  );
  return data;
};

export const removeUser = async (customerId, id) => {
  const data = await api.delete(
    `/customers/${customerId}/roleassignments/users/${id}`,
  );
  return data;
};

export const getUser = async (customerId, userId, role) => {
  const data = await api.get(
    `/customers/${customerId}/roleassignments/users/${userId}`,
    {
      params: {
        role: role,
      },
    },
  );
  return data;
};

export const getGroups = async (customerId, role, nextPageToken, sortby) => {
  const data = await api.get(
    `/customers/${customerId}/roleassignments/groups`,
    {
      params: {
        role: role,
        next_page_token: nextPageToken,
        sortby: sortby,
      },
    },
  );
  return data;
};

export const createGroup = async (customerId, groupId, email, role) => {
  const data = await api.post(
    `/customers/${customerId}/roleassignments/groups/${groupId}`,
    {
      role: role,
      email: email,
    },
  );
  return data;
};

export const removeGroup = async (customerId, id) => {
  const data = await api.delete(
    `/customers/${customerId}/roleassignments/groups/${id}`,
  );
  return data;
};

export const getGroup = async (customerId, groupId, role) => {
  const data = await api.get(
    `/customers/${customerId}/roleassignments/groups/${groupId}`,
    {
      params: { role: role },
    },
  );
  return data;
};

export default {
  getUsers,
  createUser,
  removeUser,
  getUser,
  getGroups,
  createGroup,
  removeGroup,
  getGroup,
};
