<template>
  <v-container style="max-width: 80%">
    <v-row class="my-15">
      <v-col cols="12" md="6">
        <span
          class="font-weight-extra-bold text-dark-teal"
          style="font-size: 30px"
          v-t="'substitute_teacher'"
        ></span>
        <br /><br />

        <span v-html="$t('sub_teacher_freemium_text')"></span>
      </v-col>
    </v-row>

    <div class="d-flex mb-12">
      <v-img
        width="300px"
        src="../../../public/pages/substituteteachersearch.png"
        alt="substitute teacher help image"
        class="mr-8"
        style="border: 1px solid lightgrey"
      />

      <v-img
        width="300px"
        src="../../../public/pages/substitutejoincourse.png"
        alt="substitute teacher help image"
        style="border: 1px solid lightgrey"
      />
    </div>

    <v-row class="d-flex justify-end">
      <v-btn
        height="30px"
        variant="outlined"
        class="mr-2 mb-16 rounded-md"
        color="primary"
        style="background-color: white"
        exact
        to="/help"
        ><span v-t="'back'"></span
      ></v-btn>
    </v-row>
  </v-container>
</template>
