<template>
  <v-app>
    <v-navigation-drawer
      v-if="showNavDrawer"
      :model-value="drawer"
      color="background"
    >
      <v-list-item
        lines="two"
        class="d-flex justify-center pt-0 pb-0 pr-1 pl-0"
      >
        <img src="/Trelson_400.png" style="height: 48px; width: 200px" />
      </v-list-item>

      <v-divider></v-divider>
      <v-list nav>
        <v-list-item
          v-for="(item, i) in items.filter((item) => showPageUnderMenu(item))"
          :key="i"
          :to="item.to"
          router
          exact
          :active-class="
            'bg-active-light-teal active active-' + item.to.split('/')[1]
          "
        >
          <template v-slot:prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>

          <v-list-item-title
            v-if="item.title == 'teacher'"
            class="text-dark-gray text-subtitle-1"
          >
            {{ $t(item.title, 1) }}
          </v-list-item-title>

          <v-list-item-title v-else class="text-dark-gray text-subtitle-1">
            {{ $t(item.title) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="d-flex justify-center">
          <v-btn
            color="light-gray"
            class="text-dark-gray"
            href="/privacy"
            variant="text"
            rounded
            style="text-transform: none"
            >Privacy Policy</v-btn
          >
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      :class="
        $route.name == 'index'
          ? 'bg-background elevation-0'
          : 'bg-background elevation-1'
      "
    >
      <v-app-bar-nav-icon
        v-if="showNavDrawer"
        @click.stop="drawer = !drawer"
        color="primary"
      />
      <div class="d-flex">
        <v-toolbar-title
          v-if="
            [
              'admin-courseName-courseId-teachers',
              'admin-courseName-courseId-students',
              'educator-primaryEmail',
              'manage-educators',
              'manage-substitute-teachers',
              'Admin',
              'Support Educator',
              'Substitute Teacher',
              'Manage',
              'help',
              'help-admin',
              'help-educator',
              'help-teacher',
              'help-manage',
              'help-substituteteacher',
              'help-orphanedcourses',
              'help-settings',
              'orphaned-courses',
              'Settings',
            ].includes($route.name)
          "
          class="text-h5 text-gray"
        >
          {{ $t($route.meta.key) }}
        </v-toolbar-title>
        <v-toolbar-title
          v-else-if="['Teacher'].includes($route.name)"
          class="text-h5 text-gray"
        >
          {{ $t($route.meta.key, 1) }}
        </v-toolbar-title>

        <v-toolbar-title
          v-else-if="['Privacy'].includes($route.name)"
          class="text-h5 text-gray"
        >
          <v-btn
            variant="text"
            icon="mdi-arrow-left"
            v-if="signedIn"
            @click="$router.go(-1)"
          ></v-btn>

          <v-btn
            variant="text"
            icon="mdi-arrow-left"
            exact
            v-else
            @click="backToHome()"
          ></v-btn>
          {{ $t($route.meta.key) }}
        </v-toolbar-title>

        <v-progress-linear
          v-if="$route.name != 'index'"
          :active="loading"
          :indeterminate="loading"
          absolute
          location="bottom"
          color="grey"
        ></v-progress-linear>
      </div>

      <v-spacer />
      <div class="fill-height" v-if="$route.path.split('/')[1] === 'manage'">
        <v-tabs color="dark-teal">
          <v-tab exact to="/manage" class="managetab bg-background btn-font">
            <span v-t="'admin'"></span>
          </v-tab>
          <v-tab
            exact
            to="/manage/educators"
            class="managetab bg-background btn-font"
          >
            <span v-t="'support_educator'"></span>
          </v-tab>
          <v-tab
            exact
            to="/manage/substituteteachers"
            class="managetab bg-background btn-font"
          >
            <span v-t="'substitute_teacher'"></span>
          </v-tab>
        </v-tabs>
      </div>

      <v-spacer />

      <v-tooltip
        location="bottom"
        color="black"
        class="text-white"
        :text="$t('help')"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            size="x-small"
            variant="outlined"
            icon
            exact
            to="/help"
            class="btncls mr-2 ml-5"
            color="primary"
          >
            <v-icon color="primary">mdi-help</v-icon>
          </v-btn>
        </template>
      </v-tooltip>

      <v-tooltip
        location="bottom"
        color="black"
        class="text-white"
        :text="$t('change_languages')"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            @click="changeLanguageDialog = true"
            icon
            variant="outlined"
            color="primary"
            size="x-small"
            class="btncls"
          >
            <v-icon color="primary">mdi-translate</v-icon>
          </v-btn>
        </template>
      </v-tooltip>

      <DialogBox
        :dialog="changeLanguageDialog"
        :title="$t('change_languages')"
        :actionName="$t('close')"
        :simple="true"
        @close-dialog="changeLanguageDialog = false"
        dialogWidth="500px"
      >
        <v-radio-group v-model="currentLocale" @update:modelValue="setLocale()">
          <v-radio
            color="primary"
            v-for="language in languages"
            :key="language.code"
            :label="language.language"
            :value="language.code"
          ></v-radio>
        </v-radio-group>
      </DialogBox>

      <v-dialog
        v-model="userDialog"
        v-if="signedIn"
        :scrim="false"
        width="300"
        content-class="elevation-5 user-dialog"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            class="btncls ml-2"
            height="34px"
            width="34px"
            icon
            elevation="0"
          >
            <v-avatar size="30"
              ><v-img
                :src="$store.getters['google/userImageUrl']"
                :title="$t('my_profile')"
            /></v-avatar>
          </v-btn>
        </template>

        <v-card class="d-flex flex-column align-center text-center">
          <v-card-title class="ma-5"
            ><v-avatar size="100"
              ><v-img :src="$store.getters['google/userImageUrl']"
            /></v-avatar>
          </v-card-title>

          <v-card-subtitle class="font-weight-bold text-h5 text-dark-gray">
            {{ $store.getters["google/userName"] }}
          </v-card-subtitle>

          <v-card-text class="text-dark-gray">
            {{ $store.getters["google/userEmail"] }}
          </v-card-text>

          <v-card-actions>
            <v-btn
              @click="signOut"
              class="mb-5 px-10"
              variant="flat"
              color="primary"
            >
              <span v-t="'log_out'"></span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <v-main :style="{ background: $vuetify.theme.themes['light'].background }">
      <router-view></router-view>
    </v-main>

    <v-footer color="primary" app v-if="$route.name == 'index'" class="pa-0">
      <v-card width="100%">
        <v-card-text class="text-white bg-primary d-flex justify-center pa-0">
          <v-btn color="white" href="/privacy" variant="text" rounded
            >PRIVACY POLICY</v-btn
          >

          <v-btn
            href="https://www.onlinepartner.se/avtal/general-terms-and-conditions-regarding-services-and-software/"
            target="_blank"
            color="white"
            variant="text"
            rounded
          >
            TERMS OF SERVICE
          </v-btn>

          <v-btn
            href="https://www.onlinepartner.se/avtal/personal-data-processor-agreement/"
            target="_blank"
            color="white"
            variant="text"
            rounded
          >
            AGREEMENT
          </v-btn>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script setup>
import { ref } from "vue";

import { useI18n } from "vue-i18n";
const { locale } = useI18n({ useScope: "global" });

const currentLocale = ref(localStorage.getItem("locale") || "en");

const setLocale = () => {
  locale.value = currentLocale.value;
  localStorage.setItem("locale", currentLocale.value);
  document.documentElement.setAttribute("lang", currentLocale.value);
};
</script>

<script>
import { mapGetters, mapActions } from "vuex";
import DialogBox from "@/components/dialog";

export default {
  components: {
    DialogBox,
  },

  data() {
    return {
      drawer: true,

      changeLanguageDialog: false,

      languages: [
        {
          language: "English",
          code: "en",
        },
        {
          language: "Svenska",
          code: "sv",
        },
        {
          language: "Español",
          code: "es",
        },
      ],

      userDialog: false,
      items: [
        {
          title: "teacher",
          to: "/teacher",
          icon: "mdi-account-multiple-plus",
          showFor: [
            "Teacher",
            "Support Substitute teacher",
            "Support educator",
            "Substitute teacher",
            "Admin",
            "Super admin",
          ],
        },
        {
          title: "support_educator",
          to: "/educator",
          icon: "mdi-account-plus",
          showFor: [
            "Support Substitute teacher",
            "Support educator",
            "Admin",
            "Super admin",
          ],
        },
        {
          title: "substitute_teacher",
          to: "/substituteteacher",
          icon: "mdi-account",
          showFor: [
            "Support Substitute teacher",
            "Substitute teacher",
            "Admin",
            "Super admin",
          ],
        },
        {
          title: "admin",
          to: "/admin",
          icon: "mdi-book-plus-multiple",
          showFor: ["Admin", "Super admin"],
        },
        {
          title: "orphaned_courses",
          to: "/orphanedcourses",
          icon: "mdi-account-group",
          showFor: ["Admin", "Super admin"],
        },
        {
          title: "manage",
          to: "/manage",
          icon: "mdi-account-cog",
          showFor: ["Super admin"],
        },
        {
          title: "settings",
          to: "/settings",
          icon: "mdi-cog",
          showFor: ["Super admin"],
        },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      sessionActive: "sessionActive",
      signedIn: "google/signedIn",
      userRole: "userRole",
    }),

    showNavDrawer: function () {
      return [
        "Support Substitute teacher",
        "Substitute teacher",
        "Support educator",
        "Admin",
        "Super admin",
        "Teacher",
      ].includes(this.$store.state.userRole);
    },
  },

  methods: {
    ...mapActions({
      createSession: "createSession",
      clearSession: "clearSession",
      handleUnauthorisedError: "handleUnauthorisedError",
      setSessionError: "setSessionError",
      googleSignout: "google/signOut",
    }),

    showPageUnderMenu(item) {
      return item.showFor.includes(this.$store.state.userRole);
    },

    async signOut() {
      await this.googleSignout();
      this.userDialog = false;

      if (this.$route.path != "/") {
        await this.$router.push("/").catch(() => {});
      }

      await this.clearSession();
    },

    backToHome() {
      this.$router.push("/").catch(() => {});
    },
  },

  watch: {
    async signedIn(current) {
      if (current) {
        if (this.$route.name !== "Privacy") {
          this.loading = true;
          this.setSessionError(null);
          try {
            await this.createSession();
            if (this.sessionActive) {
              //pushing to the route after session is active

              const rootPath = this.$route.path.split("/")[1];
              if (this.$store.state.userRole == "Teacher") {
                this.$router.push({ path: "/teacher" }).catch(() => {});
              } else if (
                this.$store.state.userRole == "Support Substitute teacher"
              ) {
                if (
                  ["admin", "manage", "settings", "orphanedcourses"].includes(
                    rootPath,
                  ) ||
                  this.$route.path == "/"
                ) {
                  this.$router.push({ path: "/educator" }).catch(() => {});
                } else {
                  this.$router.push(this.$route.path).catch(() => {});
                }
              } else if (this.$store.state.userRole == "Substitute teacher") {
                if (
                  [
                    "admin",
                    "manage",
                    "settings",
                    "educator",
                    "orphanedcourses",
                  ].includes(rootPath) ||
                  this.$route.path == "/"
                ) {
                  this.$router
                    .push({ path: "/substituteteacher" })
                    .catch(() => {});
                } else {
                  this.$router.push(this.$route.path).catch(() => {});
                }
              } else if (this.$store.state.userRole == "Support educator") {
                if (
                  [
                    "admin",
                    "manage",
                    "settings",
                    "substituteteacher",
                    "orphanedcourses",
                  ].includes(rootPath) ||
                  this.$route.path == "/"
                ) {
                  this.$router.push({ path: "/educator" }).catch(() => {});
                } else {
                  this.$router.push(this.$route.path).catch(() => {});
                }
              } else if (this.$store.state.userRole == "Admin") {
                if (
                  ["manage", "settings"].includes(rootPath) ||
                  this.$route.path == "/"
                ) {
                  this.$router.push({ path: "/admin" }).catch(() => {});
                } else {
                  this.$router.push(this.$route.path).catch(() => {});
                }
              } else {
                if (this.$route.path == "/") {
                  this.$router.push({ path: "/admin" }).catch(() => {});
                } else {
                  this.$router.push(this.$route.path).catch(() => {});
                }
              }
            } else {
              await this.signOut();
            }
          } catch (error) {
            if (error.response) {
              if (error.response.data.detail) {
                var detail = error.response.data.detail;
                if (
                  detail == "user_not_part_of_system" ||
                  detail == "user_not_part_of_org" ||
                  detail == "not_authenticated"
                ) {
                  await this.handleUnauthorisedError();
                  this.loading = false;
                  return;
                } else if (
                  detail == "invalid_token" ||
                  detail == "session_expired" ||
                  detail == "transport_error"
                ) {
                  await this.signOut();
                } else if (
                  detail == "no_domain_found" ||
                  detail == "unknown_customer" ||
                  detail == "unauthorized_client" ||
                  detail == "unknown_teachers_group"
                ) {
                  this.setSessionError(this.$t(detail));
                } else {
                  this.setSessionError(detail);
                }
              } else {
                this.setSessionError("Something went wrong!!");
              }
            } else {
              await this.signOut();
            }
          } finally {
            this.loading = false;
          }
        }
      } else {
        // if not signed in, clear the session and return to home page
        await this.clearSession();
        if (this.$route.path !== "/unauthorised" && this.$route.path !== "/") {
          this.$router.push("/").catch(() => {});
        }
      }
    },
  },
};
</script>

<style scoped>
.v-list-item--link:before {
  background-color: transparent;
}

.active {
  border-radius: 0px 42px 42px 0px;
}

.active-teacher {
  background: rgba(192, 228, 242, 0.3);
}

.active-educator {
  background: rgba(225, 209, 232, 0.3);
}

.active-admin {
  background: rgba(251, 207, 185, 0.3);
}

.active-manage {
  background: rgba(248, 204, 220, 0.3);
}

.user-dialog {
  margin: 0;
  position: absolute;
  right: 0;
  top: 64px;
}

.managetab {
  font-size: 16px !important;
}

.btncls {
  border: 2px solid #5e9c96;
}

.text-h5,
.text-subtitle-1 {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 400;
}

.v-tabs-slider-wrapper {
  height: 5px !important;
}

.v-tabs-slider {
  border-radius: 5px 5px 0 0;
}
</style>
