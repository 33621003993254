import { createStore } from "vuex";
import google from "./modules/google";
import * as types from "./mutation-types";
import sessionAPI from "../../src/api/sessions.js";
import router from "@/router/index";

export default createStore({
  modules: {
    google,
  },
  state() {
    return {
      sessionActive: false,
      sessionToken: null,
      userRole: null,
      sessionError: null,
      supportContact: null,
      enabledScan: false,
      tempTeacherSearch: null,
      tempStudentSearch: null,
      hasContacted: false,
    };
  },

  getters: {
    sessionActive: (state) => state.sessionActive,
    sessionToken: (state) => state.sessionToken,
    userRole: (state) => state.userRole,
    sessionError: (state) => state.sessionError,
    supportContact: (state) => state.supportContact,
    enabledScan: (state) => state.enabledScan,
    tempTeacherSearch: (state) => state.tempTeacherSearch,
    tempStudentSearch: (state) => state.tempStudentSearch,
    hasContacted: (state) => state.hasContacted,
  },

  actions: {
    async handleUnauthorisedError({ dispatch }) {
      await dispatch("clearSession");

      if (router.path !== "/unauthorised") {
        await router.push({ path: "/unauthorised" });
      }

      await dispatch("google/signOut");
    },

    async setTempTeacherSearch({ commit }, searchEmail) {
      commit(types.SET_TEMP_TEACHER_SEARCH, searchEmail);
    },

    async setTempStudentSearch({ commit }, searchEmail) {
      commit(types.SET_TEMP_STUDENT_SEARCH, searchEmail);
    },

    clearSession({ commit }) {
      commit(types.SESSION_ACTIVE_SET, false);
      commit(types.SESSION_TOKEN, {
        token: null,
        role: null,
        support_contact: null,
        has_contacted: false,
        enabled_scan: null,
      });
    },

    async signOut({ dispatch }) {
      await dispatch("google/signOut");

      if (router.path !== "/") {
        await router.push("/").catch(() => {});
      }

      await dispatch("clearSession");
    },

    async createSession({ commit, getters }) {
      const data = await sessionAPI.create(getters["google/idToken"]);
      commit(types.SESSION_TOKEN, data);
      commit(types.SESSION_ACTIVE_SET, true);
    },

    async setSessionError({ commit }, error) {
      commit(types.SET_SESSION_ERROR, error);
    },

    async setSupportContact({ commit }, supportContact) {
      commit(types.SET_SUPPORT_CONTACT, supportContact);
    },
  },

  mutations: {
    [types.SESSION_ACTIVE_SET](state, sessionActive) {
      state.sessionActive = sessionActive;
    },
    [types.SESSION_TOKEN](state, data) {
      state.sessionToken = data.token;
      state.userRole = data.role;
      state.supportContact = data.support_contact;
      state.hasContacted = data.has_contacted;
      state.enabledScan = data.enabled_scan;
    },
    [types.SET_SUPPORT_CONTACT](state, support_contact) {
      state.supportContact = support_contact;
    },
    [types.SET_SESSION_ERROR](state, error) {
      state.sessionError = error;
    },
    [types.SET_TEMP_TEACHER_SEARCH](state, searchEmail) {
      state.tempTeacherSearch = searchEmail.teacherEmail;
    },
    [types.SET_TEMP_STUDENT_SEARCH](state, searchEmail) {
      state.tempStudentSearch = searchEmail.studentEmail;
    },
  },
});
