import api from "../plugins/api.js";

export default function useGetTaskStatus() {
  function getTaskStatus(taskId, callback) {
    api
      .get("/customers/my_customer/tasks/" + taskId)
      .then((response) => {
        console.log("task_status: ", response.data.taskStatus);
        if (response.data.taskStatus === "in progress") {
          setTimeout(() => {
            getTaskStatus(taskId, callback);
          }, 5000);
        } else if (response.data.taskStatus === "completed") {
          callback(true);
        } else {
          callback(false);
        }
      })
      .catch((error) => {
        console.log(error);
        callback(false);
      });
  }

  function getOrphanedTaskStatus(taskId, callback) {
    api
      .get("/customers/my_customer/tasks/" + taskId, {
        params: {
          is_orphaned_scan_task: true,
        },
      })
      .then((response) => {
        console.log("task_status: ", response.data.taskStatus);
        if (response.data.taskStatus === "in progress") {
          setTimeout(() => {
            getOrphanedTaskStatus(taskId, callback);
          }, 180000);
        } else if (response.data.taskStatus === "completed") {
          callback(true);
        } else {
          callback(false);
        }
      })
      .catch((error) => {
        console.log(error);
        callback(false);
      });
  }

  return { getTaskStatus, getOrphanedTaskStatus };
}
