<template>
  <v-container style="max-width: 80%">
    <Error
      :pageError="pageError"
      v-if="pageError"
      @on-dismiss="pageError = null"
    ></Error>

    <SnackBar
      :text="alertInvitedText"
      color="#4BB543"
      v-if="alertInvited"
      @on-dismiss="
        alertInvited = false;
        snackbarText = '';
      "
    ></SnackBar>

    <div style="width: 100%">
      <AutocompleteStudents
        ref="teacherautocomplete"
        :disabledAction="loadingCourses"
        :placeholderName="$t('teacher', 1) + ' ' + $t('name')"
        :placeholderEmail="$t('teacher', 1) + ' ' + $t('email')"
        @select="onSelectTeacherEmail($event)"
        class="ml-0 pt-8"
      ></AutocompleteStudents>

      <AutocompleteStudents
        ref="studentautocomplete"
        :disabledAction="loadingCourses"
        :placeholderName="$t('student', 1) + ' ' + $t('name')"
        :placeholderEmail="$t('student', 1) + ' ' + $t('email')"
        @select="onSelectStudentEmail($event)"
        class="ml-0 mb-8"
      ></AutocompleteStudents>
    </div>
    <Headline
      :title="$t('course', 2)"
      color="dark-gray"
      iconName="mdi-account-group-outline"
    >
      <v-spacer></v-spacer>
      <span
        class="mr-2"
        v-if="
          totalSyncedConnections != null &&
          !searchByTeacherEmail &&
          !searchByStudentEmail
        "
      >
        <span v-t="'total_synced_connections'"></span>:
        <span>{{ totalSyncedConnections }}</span>
      </span>
    </Headline>

    <v-progress-linear
      v-if="loadingCourses || loadingSettings"
      indeterminate
      color="primary"
      height="2px"
    ></v-progress-linear>

    <v-chip-group
      v-if="searchByTeacherEmail || searchByStudentEmail"
      v-model="courseState"
      selected-class="bg-secondary"
      mandatory
    >
      <v-chip
        :disabled="loadingCourses"
        color="light-gray"
        class="text-dark-teal text-subtitle-2 rounded-pill"
        v-for="(state, index) in courseStates"
        :key="index"
        :value="state.value"
      >
        {{ $t(state.name) }}
      </v-chip>
    </v-chip-group>

    <div
      v-if="courses.length == 0 && !nextPageToken"
      class="d-flex justify-center"
    >
      <span
        v-if="loadingCourses"
        class="nodatacls"
        v-t="'loading_please_wait'"
      ></span>
      <span
        v-else-if="!searchByTeacherEmail && !searchByStudentEmail"
        style="font-size: 18px; margin-top: 25px"
        v-t="'search_tea_stu_text'"
      ></span>

      <span v-else class="fontcls" v-t="'no_data_found'"></span>
    </div>

    <v-row class="d-flex justify-center mb-10">
      <v-col cols="12" class="d-flex flex-wrap">
        <CardGrid v-for="item in courses" :key="item.courseId" :item="item">
          <template v-slot:title>
            <div class="cardtitle d-flex" style="width: 460px">
              <span class="coursename">{{ item.courseName }}</span>
              <v-spacer></v-spacer>

              <div class="d-flex">
                <!-- Archive action for the active course  -->
                <TooltipButton
                  v-if="item.courseState != 'ARCHIVED'"
                  iconName="mdi-archive-outline"
                  :tooltipMsg="$t('archive')"
                  :disabledAction="loadingCourses"
                  @action-handler="
                    changeCourseState(
                      item.courseId,
                      item.courseName,
                      'ARCHIVED',
                      $t('course_is_archived'),
                      $t('archive_confirm_msg'),
                    )
                  "
                >
                </TooltipButton>

                <!-- manage course teachers action  -->
                <v-menu
                  location="left"
                  transition="slide-y-transition"
                  class="rounded-md"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      icon
                      size="x-small"
                      variant="text"
                      color="transparent"
                    >
                      <span
                        ><v-icon color="dark-gray" size="22"
                          >mdi-cog-outline</v-icon
                        ></span
                      >
                    </v-btn>
                  </template>

                  <v-list density="compact">
                    <v-list-item
                      @click="
                        navigateToManageTeachers(item.courseName, item.courseId)
                      "
                      class="sfontcls"
                    >
                      <span v-t="'manage_course_teachers'"> </span>
                    </v-list-item>

                    <v-list-item
                      @click="
                        navigateToManageStudents(item.courseName, item.courseId)
                      "
                      class="sfontcls"
                    >
                      <span v-t="'manage_course_students'"> </span>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <span class="desctext">
              {{ item.courseSection }}
            </span>
          </template>

          <template v-slot:actions>
            <div v-if="item.connectionId" class="d-flex">
              <div class="d-flex align-center ml-1 mr-1">
                <v-icon size="30" color="black">mdi-link</v-icon>
              </div>
              <div class="d-flex flex-wrap align-center">
                <span class="cardtitle text-dark-gray mr-1"
                  >{{ item.groupName }}
                </span>
              </div>
            </div>

            <v-icon size="30" class="pl-2 pr-0 mr-0" color="black" v-else
              >mdi-link-off</v-icon
            >

            <v-spacer></v-spacer>

            <v-progress-circular
              v-if="item.taskStatus"
              indeterminate
              size="30"
              color="primary"
              class="mr-2"
            />

            <!-- re invite owner action for the declined course  -->
            <TooltipButton
              v-else-if="item.courseState == 'DECLINED'"
              :buttonName="$t('invite')"
              compStyle="margin-right: 8px;"
              :tooltipMsg="$t('re_invite_owner_tooltip')"
              :disabledAction="loadingCourses"
              @action-handler="
                changeCourseState(
                  item.courseId,
                  item.courseName,
                  'PROVISIONED',
                  $t('owner_successfully_invited_msg'),
                )
              "
            >
            </TooltipButton>

            <!-- Activate the archived course  -->
            <TooltipButton
              v-else-if="item.courseState == 'ARCHIVED'"
              :buttonName="$t('activate')"
              compStyle="margin-right: 8px;"
              :tooltipMsg="$t('activate_course_tooltip')"
              :disabledAction="loadingCourses"
              @action-handler="
                changeCourseState(
                  item.courseId,
                  item.courseName,
                  'ACTIVE',
                  $t('course_successfully_activated_msg'),
                  $t('activate_confirm_msg'),
                )
              "
            >
            </TooltipButton>

            <!--attach action for the unconnected courses -->
            <SearchGroupDialog
              v-else-if="
                hasContacted &&
                !item.connectionId &&
                item.courseState != 'DECLINED' &&
                item.courseState != 'ARCHIVED'
              "
              buttonIcon="mdi-plus"
              :buttonName="$t('attach')"
              :title="$t('choose') + ' ' + $t('group', 1)"
              :isAutoComplete="true"
              :forceCourseNameTool="forceCourseNameTool"
              :allowMultipleCourseConnections="allowMultipleCourseConnections"
              :existingCourseName="item.courseName"
              :courseId="item.courseId"
              @attach-course-handler="attachCourseHandler"
            ></SearchGroupDialog>

            <!-- detach the connected course  -->
            <TooltipButton
              v-if="hasContacted && item.connectionId"
              :buttonName="$t('detach')"
              :tooltipMsg="$t('detach_connection')"
              :loading="
                item.detachTaskStatus != null &&
                item.detachTaskStatus != undefined
              "
              compStyle="margin-right: 8px;"
              @action-handler="detachCourseHandler(item.connectionId)"
            />
          </template>
        </CardGrid>
      </v-col>

      <LoadMore
        v-if="nextPageToken"
        :loading="loadingCourses"
        @next-page="nextPage"
      ></LoadMore>
    </v-row>

    <vue3-confirm-dialog></vue3-confirm-dialog>
  </v-container>
</template>

<style scoped>
.cardtitle {
  font-size: 16px;
}

.coursename {
  font-size: 16px;
  width: 380px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: left;
}

.nodatacls {
  font-size: 14px;
  color: #686868;
}

.text-subtitle-2 {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 400 !important;
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import coursesAPI from "@/api/courses";
import connectionsAPI from "@/api/connections";
import settingsAPI from "@/api/customerSettings";
import useGetTaskStatus from "@/composables/getTaskStatus";

import Error from "@/components/error.vue";
import Headline from "@/components/headline";
import AutocompleteStudents from "@/components/autocompleteStudents.vue";
import CardGrid from "@/components/cardGrid";
import SnackBar from "@/components/snackBar.vue";
import LoadMore from "@/components/loadMoreButton";
import SearchGroupDialog from "@/components/searchGroupDialog";
import TooltipButton from "@/components/tooltipButton";

export default {
  setup() {
    const { getTaskStatus } = useGetTaskStatus();
    return { getTaskStatus };
  },

  middleware: "authenticated-access",

  components: {
    Headline,
    AutocompleteStudents,
    CardGrid,
    Error,
    SnackBar,
    LoadMore,
    SearchGroupDialog,
    TooltipButton,
  },

  data() {
    return {
      searchByTeacherEmail: "",
      searchByStudentEmail: "",
      loadingCourses: false,
      loadingSettings: false,
      courses: [],
      nextPageToken: null,
      pageError: null,
      forceCourseNameTool: false,
      allowMultipleCourseConnections: false,

      courseState: "ACTIVE",
      courseStates: [
        { name: "active", value: "ACTIVE" },
        { name: "provisioned", value: "PROVISIONED" },
        { name: "declined", value: "DECLINED" },
        { name: "archived", value: "ARCHIVED" },
      ],

      alertInvited: false,
      alertInvitedText: "",
      totalSyncedConnections: null,
    };
  },
  computed: {
    ...mapGetters({
      sessionActive: "sessionActive",
      tempTeacherSearch: "tempTeacherSearch",
      tempStudentSearch: "tempStudentSearch",
      hasContacted: "hasContacted",
    }),
  },

  watch: {
    async sessionActive(current) {
      if (current) {
        await this.getSettings();
        await this.getTotalSyncedConnections();
      }
    },

    pageError(val) {
      if (val) {
        window.scrollTo(0, 0);
      }
    },

    async searchByTeacherEmail(val) {
      if (
        (val != null && val != "") ||
        (this.searchByStudentEmail != null && this.searchByStudentEmail != "")
      ) {
        await this.getCourses({
          customerId: "my_customer",
          teacher_mail: val,
          student_mail: this.searchByStudentEmail,
          courseState: this.courseState,
        });
      } else {
        this.courses = [];
        this.nextPageToken = null;
      }
    },

    async searchByStudentEmail(val) {
      if (
        (val != null && val != "") ||
        (this.searchByTeacherEmail != null && this.searchByTeacherEmail != "")
      ) {
        await this.getCourses({
          customerId: "my_customer",
          teacher_mail: this.searchByTeacherEmail,
          student_mail: val,
          courseState: this.courseState,
        });
      } else {
        this.courses = [];
        this.nextPageToken = null;
      }
    },

    async courseState(val) {
      await this.getCourses({
        customerId: "my_customer",
        teacher_mail: this.searchByTeacherEmail,
        student_mail: this.searchByStudentEmail,
        courseState: val,
      });
    },
  },

  methods: {
    ...mapActions({
      handleUnauthorisedError: "handleUnauthorisedError",
      signOut: "signOut",
      setTempTeacherSearch: "setTempTeacherSearch",
      setTempStudentSearch: "setTempStudentSearch",
    }),

    async getSettings() {
      this.loadingSettings = true;
      try {
        const response = await settingsAPI.getSettings("my_customer");
        this.forceCourseNameTool = response.data.force_course_name_tool;
        this.allowMultipleCourseConnections =
          response.data.allow_multiple_course_connections;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingSettings = false;
      }
    },

    async getTotalSyncedConnections() {
      try {
        const response =
          await connectionsAPI.getTotalSyncedConnections("my_customer");
        this.totalSyncedConnections = response.data;
      } catch (error) {
        console.log(error);
        this.totalSyncedConnections = null;
      }
    },

    getCourses: function (params) {
      this.loadingCourses = true;

      coursesAPI
        .get(params)
        .then(
          ({
            data: {
              unconnectedCourses = [],
              connectedCourses = [],
              nextPageToken: nextPageId,
            },
          }) => {
            this.nextPageToken = nextPageId;
            this.courses = unconnectedCourses.concat(connectedCourses);
            this.loadingCourses = false;
          },
        )
        .catch((error) => {
          this.setPageError(error);
          this.loadingCourses = false;
        });
    },

    nextPage: function () {
      this.loadingCourses = true;
      coursesAPI
        .get({
          customerId: "my_customer",
          teacher_mail: this.searchByTeacherEmail,
          student_mail: this.searchByStudentEmail,
          nextPageToken: this.nextPageToken,
          courseState: this.courseState,
        })
        .then(
          ({
            data: {
              unconnectedCourses = [],
              connectedCourses = [],
              nextPageToken: nextPageId,
            },
          }) => {
            this.nextPageToken = nextPageId;
            this.courses = this.courses.concat(
              unconnectedCourses.concat(connectedCourses),
            );
            this.loadingCourses = false;
          },
        )
        .catch((error) => {
          this.setPageError(error);
          this.loadingCourses = false;
        });
    },

    attachCourseHandler: function (
      courseId,
      groupId,
      courseName,
      nameFromAdminSetting,
    ) {
      if (courseId && groupId && courseName) {
        this.loadingCourses = true;
        const course = this.courses.find((item) => item.courseId === courseId);
        course.taskStatus = "pending";

        this.pageError = null;

        connectionsAPI
          .connectGroupToCourse(
            "my_customer",
            groupId,
            courseId,
            courseName,
            nameFromAdminSetting,
          )
          .then((response) => {
            this.getTaskStatus(response.data.taskStatusId, async (success) => {
              if (success) {
                await this.getCourses({
                  customerId: "my_customer",
                  teacher_mail: this.searchByTeacherEmail,
                  student_mail: this.searchByStudentEmail,
                  courseState: this.courseState,
                });
                course.taskStatus = null;
              } else {
                course.taskStatus = null;
                this.loadingCourses = false;
                this.pageError = this.$t("action_failed_error");
                this.getCourses({
                  customerId: "my_customer",
                  teacher_mail: this.searchByTeacherEmail,
                  student_mail: this.searchByStudentEmail,
                  courseState: this.courseState,
                });
              }
            });
          })
          .catch((error) => {
            this.setPageError(error);
            course.taskStatus = null;
            this.loadingCourses = false;
          });
      }
    },

    async detachCourseHandler(connectionId) {
      if (connectionId) {
        this.$confirm({
          message: this.$t("detach_connection_ask_confirmation"),
          button: {
            no: this.$t("no"),
            yes: this.$t("yes"),
          },
          callback: async (confirm) => {
            if (confirm) {
              this.loadingCourses = true;
              const course = this.courses.find(
                (item) => item.connectionId === connectionId,
              );
              course.detachTaskStatus = "pending";
              this.pageError = null;

              await connectionsAPI
                .remove("my_customer", connectionId)
                .then((response) => {
                  if (response.data === "OK") {
                    course.connectionId = null;
                    course.groupId = null;
                    course.groupName = null;
                    course.detachTaskStatus = null;
                    this.loadingCourses = false;
                  } else {
                    course.detachTaskStatus = null;
                    this.loadingCourses = false;
                  }
                })
                .catch((error) => {
                  this.setPageError(error);
                  course.detachTaskStatus = null;
                  this.loadingCourses = false;
                });
            }
          },
        });
      }
    },

    async changeCourseState(
      courseId,
      courseName,
      courseStateTo,
      successMsg,
      confirmMessage = "",
    ) {
      this.$confirm({
        message: confirmMessage + " " + this.$t("continue?"),
        button: {
          no: this.$t("no"),
          yes: this.$t("yes"),
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loadingCourses = true;
            this.pageError = null;

            try {
              const response = await coursesAPI.changeCourseState(
                "my_customer",
                courseId,
                courseName,
                courseStateTo,
              );
              if (response.data && response.data.courseState == courseStateTo) {
                this.courses = this.courses.filter(
                  (item) => item.courseId !== courseId,
                );

                this.alertInvitedText = successMsg;
                this.alertInvited = true;
              }
            } catch (error) {
              this.setPageError(error);
            } finally {
              this.loadingCourses = false;
            }
          }
        },
      });
    },

    navigateToManageTeachers(courseName, courseId) {
      this.$router.push({
        name: "admin-courseName-courseId-teachers",
        params: {
          courseName: courseName,
          courseId: courseId,
        },
      });
    },

    navigateToManageStudents(courseName, courseId) {
      this.$router.push({
        name: "admin-courseName-courseId-students",
        params: {
          courseName: courseName,
          courseId: courseId,
        },
      });
    },

    async onSelectTeacherEmail(val) {
      await this.setTempTeacherSearch({
        teacherEmail: val,
      });
      this.searchByTeacherEmail = val ? val.primaryEmail : null;
    },

    async onSelectStudentEmail(val) {
      await this.setTempStudentSearch({
        studentEmail: val,
      });
      this.searchByStudentEmail = val ? val.primaryEmail : null;
    },

    setPageError(error) {
      if (error.response) {
        if (error.response.data.detail) {
          var detail = error.response.data.detail;
          if (
            detail === "unauthorized_client" ||
            detail == "unknown_customer"
          ) {
            this.pageError =
              "Error. You are not authorized to use this application. Application is not installed in gsuite marketplace for this customer. If the app is already installed, please make sure to grant access to the scopes. For more details follow the following steps- \n 1. From the Admin console Home page, go to Apps and then G Suite Marketplace apps. \n 2. Click the app's name-Online Partner Drive Admin Tool \n 3. Grant Access";
            this.handleUnauthorisedError();
          } else if (
            detail === "transport_error" ||
            detail == "invalid_token" ||
            detail == "session_expired"
          ) {
            this.signOut();
          } else {
            this.pageError = detail;
          }
          return;
        }
      }
      this.pageError = "action_failed_error";
    },
  },

  async created() {
    if (this.sessionActive) {
      await this.getSettings();
      await this.getTotalSyncedConnections();

      if (
        this.$refs &&
        this.$refs.teacherautocomplete &&
        this.tempTeacherSearch
      ) {
        this.$refs.teacherautocomplete.items = [this.tempTeacherSearch];
        this.$refs.teacherautocomplete.select = this.tempTeacherSearch;
      } else if (
        this.$refs &&
        this.$refs.studentautocomplete &&
        this.tempStudentSearch
      ) {
        this.$refs.studentautocomplete.items = [this.tempStudentSearch];
        this.$refs.studentautocomplete.select = this.tempStudentSearch;
      }
    }
  },
};
</script>
