<template>
  <v-container>
    <Headline
      :title="title"
      color="dark-gray"
      class="mt-10"
      iconName="mdi-account-cog-outline"
    >
      <v-spacer />

      <!-- add user dialog  -->
      <DialogButton
        color="primary"
        icon="mdi-account-plus-outline"
        :title="$t('add') + ' ' + $t(title)"
        :actionName="$t('add')"
        :fab="true"
        height="36"
        :disabledAction="newItemEmail == '' || newItemEmail == null"
        @action-handler="
          addItemHandler();
          newItemEmail = '';
        "
        @close-dialog="newItemEmail = ''"
        :tooltipMsg="$t('add') + ' ' + $t('user')"
      >
        <AutocompleteStudents
          class="pl-0 pb-0 pt-0"
          :placeholderName="$t('name')"
          :placeholderEmail="$t('email')"
          @select="newItemEmail = $event ? $event : null"
        ></AutocompleteStudents>
      </DialogButton>

      <!-- add group dialog  -->
      <v-btn
        variant="text"
        icon
        size="small"
        class="ml-2"
        :disabled="loadingGroups"
        @click="$refs.addDirectGroupComp.addGroupDialog = true"
      >
        <v-icon size="42" color="primary"
          >mdi-account-multiple-plus-outline</v-icon
        >
      </v-btn>

      <AddGroupDialog
        ref="addDirectGroupComp"
        :filteredGroupsList="filteredGroupsList"
        @addGroupHandler="addGroupHandler"
      ></AddGroupDialog>
    </Headline>

    <v-progress-linear
      v-if="loadingItems || loadingGroups"
      indeterminate
      color="primary"
      height="2px"
    ></v-progress-linear>
    <br />
    <br v-if="filteredList.length || filteredGroupsList.length" />

    <div
      v-if="
        filteredList.length === 0 &&
        filteredGroupsList.length === 0 &&
        !groupsNextPageToken &&
        !usersNextPageToken
      "
    >
      <span v-t="'no_data_found'" class="fontcls"></span>
    </div>

    <!--Display Users  -->
    <div class="d-flex align-center">
      <h3
        v-if="displayHeadline"
        class="text-dark-gray"
        style="font-weight: 600 !important"
      >
        <span v-t="'users'"></span>
      </h3>
      <v-spacer></v-spacer>
      <AutocompleteStudents
        v-if="displayHeadline"
        :disabledAction="loadingItems"
        :placeholderName="$t('search_by') + ' ' + $t('name') + '..'"
        :placeholderEmail="$t('search_by') + ' ' + $t('email') + '..'"
        @select="getSearchedUser"
        class="mb-2"
      ></AutocompleteStudents>

      <v-menu v-if="displayHeadline">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            variant="text"
            icon
            size="small"
            :disabled="loadingItems || filteredList.length === 0"
            class="d-flex align-center"
          >
            <IconsSort height="20" />
          </v-btn>
        </template>
        <v-list v-model:selected="selectedSorting" mandatory>
          <v-list-item
            v-for="(item, i) in sortingOption"
            :key="i"
            :value="item.value"
            color="primary"
          >
            <span class="fontcls">{{ item.text }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-divider v-if="displayHeadline"></v-divider>
    <div>
      <v-card v-if="filteredList.length !== 0" color="background">
        <ItemsList :filteredItemsList="filteredList" itemTitleCls="fontcls">
          <template #listaction="itemProps">
            <v-btn
              v-if="itemProps.item.role == 'Support educator'"
              icon
              variant="text"
              :disabled="loadingItems"
              @click="
                confirmRemoveUser(
                  itemProps.item.id,
                  $t('support_educator_delete_confirm'),
                )
              "
            >
              <v-icon color="primary">mdi-account-remove-outline</v-icon>
            </v-btn>

            <v-btn
              v-else
              icon
              variant="text"
              :disabled="loadingItems"
              @click="
                $emit('remove-item', itemProps.item.id, selectedSorting[0])
              "
            >
              <v-icon color="primary">mdi-account-remove-outline</v-icon>
            </v-btn>
          </template>
        </ItemsList>
      </v-card>

      <LoadMore
        v-if="usersNextPageToken"
        style="min-width: 100%"
        :loading="loadingItems"
        @next-page="fetchNextPageItems()"
      ></LoadMore>

      <div
        v-if="
          filteredList.length === 0 &&
          (filteredGroupsList.length !== 0 || groupsNextPageToken) &&
          !usersNextPageToken
        "
        class="mt-2"
      >
        <span v-t="'no_data_found'" class="text-gray"></span>
      </div>
    </div>

    <br />
    <br />

    <!--Display Groups  -->
    <div class="d-flex align-center">
      <h3
        v-if="displayHeadline"
        class="text-dark-gray"
        style="font-weight: 600 !important"
      >
        <span v-t="'groups'"></span>
      </h3>

      <v-spacer></v-spacer>

      <AutocompleteDomainGroups
        v-if="displayHeadline"
        :disabledAction="loadingGroups"
        :placeholderName="$t('search_by') + ' ' + $t('name') + '..'"
        :placeholderEmail="$t('search_by') + ' ' + $t('email') + '..'"
        @select="getSearchedGroup"
        class="mb-2"
      ></AutocompleteDomainGroups>

      <v-menu v-if="displayHeadline">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            variant="text"
            icon
            size="small"
            :disabled="loadingGroups || filteredGroupsList.length === 0"
            class="d-flex align-center"
          >
            <IconsSort height="20" />
          </v-btn>
        </template>
        <v-list v-model:selected="selectedGroupSorting" mandatory>
          <v-list-item
            v-for="(item, i) in sortingOption"
            :key="i"
            :value="item.value"
            color="primary"
          >
            <span class="fontcls">{{ item.text }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-divider v-if="displayHeadline"></v-divider>

    <div>
      <v-card v-if="filteredGroupsList.length !== 0" color="background">
        <ItemsList
          :filteredItemsList="filteredGroupsList"
          itemTitleCls="fontcls"
        >
          <template #listaction="itemProps">
            <v-btn
              v-if="itemProps.item.role == 'Support educator'"
              icon
              variant="text"
              :disabled="loadingGroups"
              @click="
                confirmRemoveGroup(
                  itemProps.item.id,
                  $t('support_educator_delete_confirm'),
                )
              "
            >
              <v-icon color="primary"
                >mdi-account-multiple-remove-outline</v-icon
              >
            </v-btn>

            <v-btn
              v-else
              icon
              variant="text"
              :disabled="loadingGroups"
              @click="
                $emit(
                  'remove-group',
                  itemProps.item.id,
                  selectedGroupSorting[0],
                )
              "
            >
              <v-icon color="primary"
                >mdi-account-multiple-remove-outline</v-icon
              >
            </v-btn>
          </template>
        </ItemsList>
      </v-card>

      <LoadMore
        v-if="groupsNextPageToken"
        style="min-width: 100%"
        :loading="loadingGroups"
        @next-page="fetchNextPageGroups()"
      ></LoadMore>

      <div
        v-if="
          filteredGroupsList.length === 0 &&
          (filteredList.length !== 0 || usersNextPageToken) &&
          !groupsNextPageToken
        "
        class="mt-2"
      >
        <span v-t="'no_data_found'" class="text-gray"></span>
      </div>
    </div>
    <vue3-confirm-dialog></vue3-confirm-dialog>
  </v-container>
</template>

<script>
import Headline from "./headline.vue";
import AddGroupDialog from "@/components/addGroupDialog.vue";
import AutocompleteStudents from "@/components/autocompleteStudents";
import AutocompleteDomainGroups from "@/components/autocompleteDomainGroups";
import DialogButton from "@/components/dialogButton";
import LoadMore from "@/components/loadMoreButton";
import ItemsList from "@/components/itemsList.vue";
import IconsSort from "@/components/icons/sort";

export default {
  name: "Manage",
  props: [
    "title",
    "list",
    "groupsList",
    "usersNextPageToken",
    "groupsNextPageToken",
    "loadingItems",
    "loadingGroups",
  ],

  emits: [
    "remove-item",
    "remove-group",
    "sort-users",
    "sort-groups",
    "add-group",
    "add-item",
    "fetch-users",
    "fetch-groups",
    "get-user",
    "get-searched-users",
    "get-group",
    "get-searched-groups",
  ],

  components: {
    Headline,
    AddGroupDialog,
    AutocompleteStudents,
    AutocompleteDomainGroups,
    DialogButton,
    LoadMore,
    ItemsList,
    IconsSort,
  },

  data() {
    return {
      sortingOption: [
        { text: this.$t("email") + "(A-Z)", value: "email" },
        { text: this.$t("email") + "(Z-A)", value: "-email" },
      ],
      selectedSorting: ["email"],
      selectedGroupSorting: ["email"],

      filteredList: this.list,
      filteredGroupsList: this.groupsList,

      newItemEmail: "",
    };
  },

  computed: {
    displayHeadline() {
      if (
        this.filteredList.length != 0 ||
        this.filteredGroupsList.length != 0 ||
        this.groupsNextPageToken ||
        this.usersNextPageToken
      )
        return true;
      else return false;
    },
  },

  watch: {
    list: {
      handler() {
        this.filteredList = this.list;
      },
      deep: true,
    },

    groupsList: {
      handler() {
        this.filteredGroupsList = this.groupsList;
      },
      deep: true,
    },

    // // select sorting for users list
    selectedSorting: {
      handler() {
        this.$emit("sort-users", this.selectedSorting[0]);
      },
      deep: true,
    },

    // select sorting for groups list
    selectedGroupSorting: {
      handler() {
        this.$emit("sort-groups", this.selectedGroupSorting[0]);
      },
      deep: true,
    },
  },

  methods: {
    // add group to groupsList
    async addGroupHandler(newGroupEmail) {
      if (newGroupEmail && newGroupEmail.email) {
        await this.$emit(
          "add-group",
          newGroupEmail.id,
          newGroupEmail.email,
          this.selectedGroupSorting[0],
        );
      }
    },

    // add user to users list
    async addItemHandler() {
      if (this.newItemEmail) {
        await this.$emit(
          "add-item",
          this.newItemEmail.id,
          this.newItemEmail.primaryEmail,
          this.selectedSorting[0],
        );
      }
    },

    async fetchNextPageItems() {
      await this.$emit("fetch-users", this.selectedSorting[0]);
    },

    async fetchNextPageGroups() {
      await this.$emit("fetch-groups", this.selectedGroupSorting[0]);
    },

    async getSearchedUser(val) {
      if (val) {
        await this.$emit("get-user", val.id);
      } else {
        await this.$emit("get-searched-users", this.selectedSorting[0]);
      }
    },

    async getSearchedGroup(val) {
      if (val) {
        await this.$emit("get-group", val.id);
      } else {
        await this.$emit("get-searched-groups", this.selectedGroupSorting[0]);
      }
    },

    confirmRemoveUser(itemId, message) {
      this.$confirm({
        message: message,
        button: {
          no: this.$t("no"),
          yes: this.$t("yes"),
        },
        callback: (confirm) => {
          if (confirm) {
            this.$emit("remove-item", itemId, this.selectedSorting[0]);
          }
        },
      });
    },

    async confirmRemoveGroup(groupId, message) {
      this.$confirm({
        message: message,
        button: {
          no: this.$t("no"),
          yes: this.$t("yes"),
        },
        callback: (confirm) => {
          if (confirm) {
            this.$emit("remove-group", groupId, this.selectedGroupSorting[0]);
          }
        },
      });
    },
  },
};
</script>

<style>
.vc-btn {
  color: #5e9c96 !important;
  min-height: 40px !important;
  border: none !important;
  font-weight: 400 !important;
}

.vc-btn-grid {
  max-height: 40px !important;
  grid-template-columns: 60px 60px !important;
  justify-content: right;
}

.vc-btn.left {
  color: #9e9e9e !important;
}

.vc-container {
  border-radius: 6px !important;
  width: 500px !important;
}

.vc-text {
  color: #464646 !important;
  line-height: 1.5rem !important;
  letter-spacing: 0.03125em !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: left !important;
}
</style>
