<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="slide-y-transition"
    v-model:return-value="value"
    height="fit-content"
  >
    <template v-slot:activator="{ props }">
      <v-text-field
        v-bind="props"
        v-model="value"
        :placeholder="$t('choose_date')"
        prepend-inner-icon="mdi-calendar"
        readonly
        clearable
        :disabled="isDisabled"
        :rules="textRules"
        variant="underlined"
      ></v-text-field>
    </template>

    <v-card width="fit-content">
      <v-date-picker
        color="primary"
        scrollable
        :min="nowDate"
        v-model="value"
        height="fit-content"
      >
      </v-date-picker>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          height="30px"
          elevation="0"
          variant="outlined"
          color="primary"
          class="rounded-md"
          @click="
            menu = false;
            value = null;
          "
        >
          <span v-t="'cancel'"></span>
        </v-btn>
        <v-btn
          height="30px"
          color="primary"
          class="rounded-md"
          variant="flat"
          elevation="0"
          @click="menu = false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: ["isDisabled", "textRules"],

  emits: ["updateValue"],

  data() {
    return {
      nowDate: new Date(new Date().setDate(new Date().getDate() + 1))
        .toISOString()
        .slice(0, 10),
      value: null,
      menu: false,
    };
  },
  watch: {
    value(val) {
      this.$emit("updateValue", val);
    },
  },
};
</script>
