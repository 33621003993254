import api from "../plugins/api";

export const get = async (customerId, searchval, searchby) => {
  const data = await api.get(`/customers/${customerId}/groups`, {
    params: { searchval: searchval, searchby: searchby },
  });
  return data;
};

export const getTeacherGroups = async (params) => {
  const data = await api.get(`/customers/${params.customerId}/groups/teacher`, {
    params: {
      me: params.me,
      name_or_email: params.name_or_email,
      nextPageToken: params.nextPageToken,
      sorterOrder: params.sorterOrder,
      show_disabled: params.showDisabled,
    },
  });
  return data;
};

export const getWhitelistedGroups = async (params) => {
  const data = await api.get(
    `/customers/${params.customerId}/groups/whitelisted`,
    {
      params: {
        name_or_email: params.name_or_email,
        searchby: params.searchby,
        nextPageToken: params.nextPageToken,
        sorterOrder: params.sorterOrder,
        show_disabled: params.showDisabled,
      },
    },
  );
  return data;
};

export const getUnconnectedGroups = async (params) => {
  const data = await api.get(
    `/customers/${params.customerId}/groups/unconnected`,
    {
      params: {
        name_or_email: params.name_or_email,
        searchby: params.searchby,
        nextPageToken: params.nextPageToken,
        sorterOrder: params.sorterOrder,
      },
    },
  );
  return data;
};

export default {
  get,
  getTeacherGroups,
  getWhitelistedGroups,
  getUnconnectedGroups,
};
