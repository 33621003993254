<template>
  <v-container class="pa-0">
    <v-autocomplete
      v-model="select"
      :loading="loading"
      :items="items"
      v-model:search="searchGroupEmailFunc"
      :item-title="'email'"
      :item-value="(item) => item"
      variant="outlined"
      density="compact"
      :placeholder="placeholderEmail"
      :no-data-text="$t('no_data_available')"
      hide-details
      clearable
      prepend-inner-icon="mdi-magnify "
      color="secondary"
      class="autocompcls mb-4 rounded-md"
    />

    <v-autocomplete
      v-model="select"
      :loading="loading"
      :items="items"
      v-model:search="searchGroupNameFunc"
      :item-title="'name'"
      :item-value="(item) => item"
      variant="outlined"
      density="compact"
      :placeholder="placeholderName"
      :no-data-text="$t('no_data_available')"
      hide-details
      clearable
      class="autocompcls rounded-md"
      prepend-inner-icon="mdi-magnify"
      color="secondary"
    />
  </v-container>
</template>

<script>
import groupsAPI from "@/api/groups";
import { debounce } from "lodash";

export default {
  props: {
    placeholderName: {
      type: String,
      default: "name",
    },

    placeholderEmail: {
      type: String,
      default: "email",
    },
  },

  emits: ["select"],

  data() {
    return {
      loading: false,
      items: [],
      search: null,
      select: null,
      searchGroupEmailFunc: null,
      searchGroupNameFunc: null,
    };
  },

  watch: {
    searchGroupEmailFunc: debounce(async function (val) {
      if (this.loading) return;

      await this.querySelections(val, "email");
    }, 500),

    searchGroupNameFunc: debounce(async function (val) {
      if (this.loading) return;

      var selectedItemField = this.select ? this.select.name : null;

      if (val && val.name !== selectedItemField) {
        await this.querySelections(val, "name");
      }
    }, 500),

    select(val) {
      this.$emit("select", val);
    },
  },

  methods: {
    querySelections(search, searchby) {
      this.loading = true;
      groupsAPI
        .getWhitelistedGroups({
          customerId: "my_customer",
          name_or_email: search,
          searchby: searchby,
          showDisabled: true,
        })
        .then((response) => {
          this.items = response.data.unconnectedGroups;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
