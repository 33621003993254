<template>
  <v-container style="max-width: 80%">
    <div v-if="sessionActive && hasContacted">
      <Headline
        :title="$t('settings')"
        color="dark-gray"
        class="mt-10"
        iconName="mdi-cog-outline"
      >
      </Headline>
      <v-progress-linear
        v-if="loadingSettings"
        indeterminate
        color="primary"
        height="2px"
      ></v-progress-linear>
      <br />

      <Error
        :pageError="pageError"
        v-if="pageError"
        @on-dismiss="pageError = null"
      ></Error>

      <v-card class="mx-auto" max-width="70%" color="background">
        <v-card-title><span v-t="'whitelist'"> </span> </v-card-title>

        <v-card-text>
          <div class="d-flex" style="width: fit-content">
            <!-- WHITELIST ALL GROUPS -->
            <v-checkbox
              v-model="whitelistAllGroups"
              color="primary"
              :disabled="loadingSettings"
              class="d-flex align-center mr-1"
              density="compact"
            ></v-checkbox>

            <label class="labelcls">
              {{ $t("whitelist") + " " + $t("all") + " " + $t("groups") }}
            </label>
          </div>

          <!-- WHITELIST GROUP CONDITIONS SECTION -->
          <div class="d-flex align-center">
            <label
              :style="
                whitelistAllGroups
                  ? 'color: gray; pointer-events: none; font-size: 16px; font-weight: 500 !important;'
                  : ''
              "
              :class="whitelistAllGroups ? '' : 'subtitlecls'"
            >
              <span v-t="'whitelist_group_conditions'"></span>
            </label>

            <v-spacer></v-spacer>

            <v-tooltip location="bottom" color="black" class="text-white">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  variant="text"
                  icon
                  class="pa-0"
                  :disabled="loadingSettings || whitelistAllGroups"
                  @click="
                    $refs.WhitelistConditionComp.clear();
                    $refs.WhitelistConditionComp.showDialog = true;
                  "
                  density="comfortable"
                >
                  <v-icon color="primary" size="x-large"
                    >mdi-account-group-outline</v-icon
                  >
                </v-btn>
              </template>
              <span v-t="'create'"></span>
            </v-tooltip>
          </div>

          <!-- add Whitelist condition dialog -->
          <FilterConditions
            ref="WhitelistConditionComp"
            :disabledAction="whitelistAllGroups"
            :loadingSettings="loadingSettings"
            :filterGroupConditions="filterWhitelistGroupConditions"
            @saveCondition="saveWhitelistCondition"
            @deleteCondition="deleteWhitelistCondition"
          ></FilterConditions>

          <!--WHITELIST DIRECT GROUPS SECTION -->
          <div class="d-flex align-center">
            <label
              :style="
                whitelistAllGroups
                  ? 'color: gray; pointer-events: none; font-size: 16px; font-weight: 500 !important;'
                  : ''
              "
              :class="whitelistAllGroups ? '' : 'subtitlecls'"
            >
              <span v-t="'whitelisted_groups'"></span>
            </label>

            <v-spacer></v-spacer>

            <v-tooltip location="bottom" color="black" class="text-white">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon
                  variant="text"
                  class="pa-0"
                  :disabled="loadingSettings || whitelistAllGroups"
                  @click="
                    $refs.addDirectWhitelistedGroupComp.addGroupDialog = true
                  "
                  density="comfortable"
                >
                  <v-icon size="x-large" color="primary"
                    >mdi-account-multiple-plus-outline</v-icon
                  >
                </v-btn>
              </template>
              <span v-t="'add'"></span>
            </v-tooltip>
          </div>

          <v-container class="pa-0">
            <!-- add Whitelisted group dialog -->
            <AddGroupDialog
              ref="addDirectWhitelistedGroupComp"
              :disabledAction="whitelistAllGroups"
              :loadingSettings="loadingSettings"
              :filteredGroupsList="filteredWhitelistedGroupsList"
              @addGroupHandler="addWhitelistedGroupHandler"
            ></AddGroupDialog>

            <v-divider></v-divider>

            <!-- Display added Whitelisted groups list  -->
            <ItemsList
              :disabledAction="whitelistAllGroups"
              :filteredItemsList="filteredWhitelistedGroupsList"
              itemIcon="mdi-account-multiple-outline"
              itemContentStyle="max-width: fit-content"
            >
              <template #listaction="deleteProps">
                <TooltipButton
                  iColor="#686868"
                  iconName="mdi-delete-outline"
                  :tooltipMsg="$t('remove')"
                  :disabledAction="loadingSettings || whitelistAllGroups"
                  @action-handler="deleteWhitelistedGroup(deleteProps.item.id)"
                />
              </template>
            </ItemsList>

            <div
              v-if="filteredWhitelistedGroupsList.length === 0"
              class="mt-0 ml-8"
            >
              <span v-t="'no_data_found'" class="text-gray"></span>
            </div>
          </v-container>
        </v-card-text>

        <v-divider class="border-opacity-100" color="primary"></v-divider>

        <v-card-title
          ><span class="mr-2" v-t="'exclude'"></span>
          <v-tooltip location="right">
            <template v-slot:activator="{ props }">
              <v-icon size="small" v-bind="props" color="orange"
                >mdi-information</v-icon
              >
            </template>
            <span v-t="'exclude_among_whitelist_info'"></span> </v-tooltip
        ></v-card-title>

        <v-card-text>
          <!-- EXCLUDE GROUP CONDITIONS SECTION -->
          <div class="d-flex align-center">
            <label class="subtitlecls">
              <span v-t="'exclude_group_conditions'"></span>
            </label>
            <v-spacer></v-spacer>

            <v-tooltip location="bottom" color="black" class="text-white">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  variant="text"
                  icon
                  class="pa-0"
                  :disabled="loadingSettings"
                  @click="
                    $refs.ExcludeConditionComp.clear();
                    $refs.ExcludeConditionComp.showDialog = true;
                  "
                  density="comfortable"
                >
                  <v-icon size="x-large" color="primary"
                    >mdi-account-group-outline</v-icon
                  >
                </v-btn>
              </template>
              <span v-t="'create'"></span>
            </v-tooltip>
          </div>

          <FilterConditions
            ref="ExcludeConditionComp"
            :loadingSettings="loadingSettings"
            :filterGroupConditions="filterGroupConditions"
            @saveCondition="saveExcludeCondition"
            @deleteCondition="deleteExcludeCondition"
          ></FilterConditions>

          <!-- EXCLUDE DIRECT GROUPS SECTION -->
          <div class="d-flex align-center">
            <label class="subtitlecls">
              <span v-t="'excluded_groups'"></span>
            </label>

            <v-spacer></v-spacer>

            <v-tooltip location="bottom" color="black" class="text-white">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  variant="text"
                  icon
                  class="pa-0"
                  :disabled="loadingSettings"
                  @click="
                    $refs.addDirectExcludedGroupComp.addGroupDialog = true
                  "
                  density="comfortable"
                >
                  <v-icon size="x-large" color="primary"
                    >mdi-account-multiple-plus-outline</v-icon
                  >
                </v-btn>
              </template>
              <span v-t="'add'"></span>
            </v-tooltip>
          </div>

          <!-- add direct excluded group dialog -->
          <v-container class="pa-0">
            <AddGroupDialog
              ref="addDirectExcludedGroupComp"
              :loadingSettings="loadingSettings"
              :filteredGroupsList="filteredExcludedGroupsList"
              @addGroupHandler="addExcludedGroupHandler"
            ></AddGroupDialog>

            <v-divider></v-divider>

            <!-- Display added excluded groups list  -->
            <ItemsList
              :filteredItemsList="filteredExcludedGroupsList"
              itemIcon="mdi-account-multiple-outline"
              itemContentStyle="max-width: fit-content"
            >
              <template #listaction="deleteProps">
                <TooltipButton
                  iColor="#686868"
                  iconName="mdi-delete-outline"
                  :tooltipMsg="$t('remove')"
                  :disabledAction="loadingSettings"
                  @action-handler="deleteExcludedGroup(deleteProps.item.id)"
                />
              </template>
            </ItemsList>

            <div
              v-if="filteredExcludedGroupsList.length === 0"
              class="mt-0 ml-8"
            >
              <span v-t="'no_data_found'" class="text-gray"></span>
            </div>
          </v-container>
        </v-card-text>

        <v-divider class="border-opacity-100" color="primary"></v-divider>

        <!-- COURSE NAME TOOL SECTION  -->
        <v-card-title>
          <span class="mr-2" v-t="'course_name_tool'"> </span>
          <v-tooltip location="right">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props" size="small" color="orange"
                >mdi-information</v-icon
              >
            </template>
            <span v-t="'course_name_tool_info'"></span>
          </v-tooltip>
        </v-card-title>

        <v-card-text>
          <div class="d-flex align-center mb-4" style="width: fit-content">
            <v-checkbox
              hide-details
              v-model="forceCourseNameTool"
              color="primary"
              :disabled="loadingSettings || allowMultipleCourseConnections"
              class="d-flex align-center mr-1"
              density="compact"
            ></v-checkbox>

            <label class="labelcls">
              <span v-t="'force_course_name_settings'"></span>
            </label>
          </div>

          <div class="d-flex pb-0">
            <v-select
              v-model="courseNameGroupField"
              :items="filterFieldItems"
              item-title="name"
              item-value="id"
              variant="outlined"
              density="compact"
              class="autocomp textcls mr-2 pb-0 mb-0 rounded-md"
              color="secondary"
              :label="$t('field')"
              hide-details
            />

            <v-tooltip location="bottom" color="black" class="text-white">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon
                  variant="text"
                  color="primary"
                  @click="$refs.chooseCourseName.newTool()"
                  density="comfortable"
                >
                  <v-icon>mdi-wrench-outline</v-icon>
                </v-btn>
              </template>
              <span v-t="'add'" class="mr-1"></span><span v-t="'tool'"></span>
            </v-tooltip>
          </div>

          <FilterButtonTool
            ref="chooseCourseName"
            class="ml-5 mt-5"
            v-if="courseNameGroupField"
            :fieldName="getFieldName(courseNameGroupField)"
            v-model="courseNameGroupFieldTools"
          />
        </v-card-text>

        <v-divider class="border-opacity-100" color="primary"></v-divider>

        <!-- CONTACT EMAIL SECTION  -->
        <v-card-title class="mb-4">
          <span v-t="'contact_email'"></span
        ></v-card-title>

        <v-card-text>
          <v-text-field
            hide-details
            v-model="contactEmailSelect"
            :placeholder="$t('email') + '..'"
            :loading="loadingSettings"
            variant="outlined"
            density="compact"
            class="autocomp mb-5 mr-2"
            prepend-inner-icon="mdi-magnify"
            color="secondary"
            clearable
          ></v-text-field>
        </v-card-text>

        <v-divider class="border-opacity-100" color="primary"></v-divider>

        <v-card-title> <span v-t="'connections'"></span></v-card-title>

        <v-card-text class="pb-0">
          <div class="d-flex align-center" style="width: fit-content">
            <v-checkbox
              hide-details
              v-model="allowMultipleCourseConnections"
              color="primary"
              :disabled="loadingSettings"
              class="d-flex align-center mr-1"
              density="compact"
            ></v-checkbox>

            <label class="labelcls">
              <span v-t="'allow_multiple_course_connections'"></span>
            </label>

            <TooltipButton
              v-if="!allowMultipleCourseConnections && forceCourseNameTool"
              iColor="orange"
              iconName="mdi-alert"
              :tooltipMsg="$t('auto_disable_force_setting_warn')"
            />
          </div>

          <div class="d-flex align-center" style="width: fit-content">
            <v-checkbox
              hide-details
              v-model="syncTeacherRemoval"
              color="primary"
              :disabled="loadingSettings"
              class="d-flex align-center mr-1"
              density="compact"
            ></v-checkbox>

            <label class="labelcls">
              <span v-t="'sync_teacher_removal_checkbox_label'"></span>
            </label>
          </div>

          <div class="d-flex align-center" style="width: fit-content">
            <v-checkbox
              hide-details
              v-model="syncStudentRemoval"
              color="primary"
              :disabled="loadingSettings"
              class="d-flex align-center mr-1"
              density="compact"
            ></v-checkbox>

            <label class="labelcls">
              <span v-t="'sync_student_removal_checkbox_label'"></span>
            </label>
          </div>
        </v-card-text>

        <v-card-text class="pb-0 pt-0">
          <v-radio-group
            v-model="autoArchiveCourseType"
            hide-details
            density="compact"
          >
            <div class="d-flex align-center mb-2">
              <v-checkbox
                color="primary"
                value="connected"
                v-model="autoArchiveCourseType"
                hide-details
                density="compact"
                class="mr-1"
              >
              </v-checkbox>

              <label class="labelcls" style="width: 100%; height: 44px">
                <span class="mr-2">
                  {{
                    $t("auto_archive_courses_at", {
                      coursetype: $t("connected").toLowerCase(),
                    })
                  }}
                </span>

                <DatePickerComp
                  ref="connectedCourseDatePicker"
                  :isDisabled="
                    autoArchiveCourseType != 'connected' || loadingSettings
                  "
                  v-on:updateValue="autoArchiveConnectedCoursesAt = $event"
                  :textRules="autoArchiveConnectedCourseAtRules"
                ></DatePickerComp>

                <TooltipButton
                  v-if="
                    autoArchiveConnectedCoursesAt &&
                    hasConnectedAutoArchivalOutdated
                  "
                  iColor="red"
                  iconName="mdi-alert"
                  :tooltipMsg="$t('auto_archival_outdated_warn')"
                />

                <TooltipButton
                  v-else-if="
                    autoArchiveConnectedCoursesAt &&
                    !hasConnectedAutoArchivalOutdated
                  "
                  iColor="red"
                  iconName="mdi-alert"
                  :tooltipMsg="$t('archive_connected_course_warning')"
                />
              </label>
            </div>

            <v-divider
              color="primary"
              class="mt-3 border-opacity-100"
            ></v-divider>

            <!-- SCAN SECTION  -->

            <v-card-title class="mb-1 ml-0 pl-0">
              <span v-t="'scan'"></span
            ></v-card-title>

            <div class="d-flex align-center">
              <v-checkbox
                color="primary"
                value="all"
                v-model="autoArchiveCourseType"
                hide-details
                density="compact"
                class="mr-1"
                :disabled="!enabledScan"
              >
              </v-checkbox>

              <label class="labelcls" style="width: 100%; height: 44px">
                <span class="mr-2">
                  {{
                    $t("auto_archive_courses_at", {
                      coursetype: $t("all").toLowerCase(),
                    })
                  }}
                </span>

                <DatePickerComp
                  ref="allCourseDatePicker"
                  :isDisabled="
                    autoArchiveCourseType != 'all' ||
                    loadingSettings ||
                    !enabledScan
                  "
                  v-on:updateValue="autoArchiveCoursesAt = $event"
                  :textRules="autoArchiveCourseAtRules"
                >
                </DatePickerComp>

                <TooltipButton
                  v-if="!enabledScan"
                  iColor="red"
                  iconName="mdi-alert"
                  tooltipMsg="The automatic scanning of courses feature is disabled. Contact sales@trelson.com to upgrade and use this feature!"
                />

                <TooltipButton
                  v-else-if="autoArchiveCoursesAt && hasAutoArchivalOutdated"
                  iColor="red"
                  iconName="mdi-alert"
                  :tooltipMsg="$t('auto_archival_outdated_warn')"
                />

                <TooltipButton
                  v-else-if="autoArchiveCoursesAt && !hasAutoArchivalOutdated"
                  iColor="red"
                  iconName="mdi-alert"
                  :tooltipMsg="$t('archive_all_course_warning')"
                />
              </label>
            </div>
          </v-radio-group>
        </v-card-text>

        <v-card-text>
          <div class="d-flex">
            <v-select
              variant="outlined"
              density="compact"
              :items="scanIntervals"
              :label="$t('schedule_scan_interval')"
              :disabled="!enabledScan"
              v-model="scheduleScan"
              hide-details
              color="secondary"
              class="autocomp textcls rounded-md"
            ></v-select>

            <TooltipButton
              v-if="!enabledScan"
              iColor="red"
              iconName="mdi-alert"
              tooltipMsg="The automatic scanning of courses feature is disabled. Contact sales@trelson.com to upgrade and use this feature!"
            />
          </div>
        </v-card-text>
      </v-card>
    </div>

    <Freemium
      v-else-if="sessionActive && !hasContacted"
      images="/settings.gif"
      :title="$t('settings')"
      :bodyText="$t('settings_freemium_text')"
    ></Freemium>
  </v-container>
</template>

<style scoped>
.autocomp {
  font-size: 14px;
  border-radius: 6px;
  border: 1px;
  background: white;
  width: auto;
}

.subtitlecls {
  font-size: 16px;
  color: #464646 !important;
  font-weight: 500 !important;
}

.labelcls {
  color: #464646 !important;
  font-size: 14px;
  display: flex !important;
  align-items: center !important;
}
</style>

<style>
.v-input.textcls.theme--light.v-text-field .v-input__control .v-input__slot {
  padding-left: 24px !important;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

import customersAPI from "@/api/customerSettings";

import Headline from "@/components/headline.vue";
import Error from "@/components/error.vue";
import FilterConditions from "@/components/filterConditions.vue";
import ItemsList from "@/components/itemsList.vue";
import AddGroupDialog from "@/components/addGroupDialog.vue";
import FilterButtonTool from "@/components/filterButtonTool.vue";
import Freemium from "@/components/freemium.vue";
import DatePickerComp from "@/components/DatePickerComp";
import TooltipButton from "@/components/tooltipButton";

export default {
  data() {
    return {
      pageError: null,
      loadingSettings: false,

      sortingOption: [
        { text: this.$t("email") + "(A-Z)", value: "email" },
        { text: this.$t("email") + "(Z-A)", value: "-email" },
        { text: this.$t("name") + "(A-Z)", value: "name" },
        { text: this.$t("name") + "(Z-A)", value: "-name" },
      ],
      selectedGroupSorting: "email",

      filterGroupConditions: [],
      excludedGroups: [],

      whitelistAllGroups: false,
      filterWhitelistGroupConditions: [],
      whitelistedGroups: [],

      contactEmailSelect: "",
      searchTimeOut: 0,

      filterFieldItems: [
        { name: this.$t("group") + " " + this.$t("name"), id: "name" },
        { name: this.$t("group") + " " + this.$t("email"), id: "email" },
      ],
      forceCourseNameTool: true,
      syncTeacherRemoval: false,
      syncStudentRemoval: false,

      courseNameGroupField: "name",
      courseNameGroupFieldTools: [],

      isCreatedCallCourseNameTool: false,
      isCreatedCallSettings: false,

      scheduleScan: null,
      scanIntervals: [
        { title: this.$t("never"), value: null },
        { title: this.$t("days", { number: "20" }), value: 19 },
      ],

      allowMultipleCourseConnections: false,

      autoArchiveCourseType: null,
      autoArchiveCoursesAt: null,
      autoArchiveConnectedCoursesAt: null,
    };
  },

  components: {
    Headline,
    Error,
    FilterConditions,
    ItemsList,
    AddGroupDialog,
    FilterButtonTool,
    Freemium,
    DatePickerComp,
    TooltipButton,
  },

  computed: {
    ...mapGetters({
      sessionActive: "sessionActive",
      hasContacted: "hasContacted",
      enabledScan: "enabledScan",
    }),

    hasAutoArchivalOutdated() {
      var today = new Date().setHours(0, 0, 0, 0);
      var archivalDate = new Date(this.autoArchiveCoursesAt).setHours(
        0,
        0,
        0,
        0,
      );

      if (archivalDate < today) {
        return true;
      }

      return false;
    },

    hasConnectedAutoArchivalOutdated() {
      var today = new Date().setHours(0, 0, 0, 0);
      var archivalDate = new Date(this.autoArchiveConnectedCoursesAt).setHours(
        0,
        0,
        0,
        0,
      );
      if (archivalDate < today) {
        return true;
      }

      return false;
    },

    autoArchiveCourseAtRules() {
      if (this.autoArchiveCourseType != "all") {
        return [];
      } else {
        return [!!this.autoArchiveCoursesAt || "required"];
      }
    },

    autoArchiveConnectedCourseAtRules() {
      if (this.autoArchiveCourseType != "connected") {
        return [];
      } else {
        return [!!this.autoArchiveConnectedCoursesAt || "required"];
      }
    },

    filteredWhitelistedGroupsList() {
      var sortedGroups = this.whitelistedGroups;

      if (this.selectedGroupSorting === "name") {
        sortedGroups = _.orderBy(this.whitelistedGroups, "name");
      } else if (this.selectedGroupSorting === "-name") {
        sortedGroups = _.orderBy(this.whitelistedGroups, "name", "desc");
      } else if (this.selectedGroupSorting === "email") {
        sortedGroups = _.orderBy(this.whitelistedGroups, "email");
      } else if (this.selectedGroupSorting === "-email") {
        sortedGroups = _.orderBy(this.whitelistedGroups, "email", "desc");
      }

      return sortedGroups;
    },

    filteredExcludedGroupsList() {
      var sortedGroups = this.excludedGroups;

      if (this.selectedGroupSorting === "name") {
        sortedGroups = _.orderBy(this.excludedGroups, "name");
      } else if (this.selectedGroupSorting === "-name") {
        sortedGroups = _.orderBy(this.excludedGroups, "name", "desc");
      } else if (this.selectedGroupSorting === "email") {
        sortedGroups = _.orderBy(this.excludedGroups, "email");
      } else if (this.selectedGroupSorting === "-email") {
        sortedGroups = _.orderBy(this.excludedGroups, "email", "desc");
      }

      return sortedGroups;
    },
  },

  middleware: "authenticated-access",

  methods: {
    ...mapActions({
      handleUnauthorisedError: "handleUnauthorisedError",
      signOut: "signOut",
      setSupportContact: "setSupportContact",
    }),

    getFieldName(fieldId) {
      return (
        {
          // Group-field
          email: this.$t("group") + " " + this.$t("email"),
          name: this.$t("group") + " " + this.$t("name"),
        }[fieldId] ||
        (fieldId.includes(".") ? "User attributes" : this.$t("error_occured"))
      );
    },

    async getSettings() {
      this.loadingSettings = true;
      await this.getWhitelistConditions();
      await this.getWhitelistedGroups();
      await this.getExcludedGroups();
      await this.getExcludeConditions();
      this.isCreatedCallSettings = true;
      await this.getSettingFields();
      this.isCreatedCallCourseNameTool = true;
      await this.getCourseNameTool();
      this.loadingSettings = false;
    },

    // contact setting methods
    async setSettingFields(
      support_contact,
      has_all_groups_whitelisted,
      force_course_name_tool,
      sync_teacher_removal,
      sync_student_removal,
      schedule_scan,
      allow_multiple_course_connections,
      auto_archive_courses_at,
      auto_archive_connected_courses_at,
    ) {
      this.contactEmailSelect = support_contact;
      this.whitelistAllGroups = has_all_groups_whitelisted;
      this.forceCourseNameTool = force_course_name_tool;
      this.syncTeacherRemoval = sync_teacher_removal;
      this.syncStudentRemoval = sync_student_removal;
      this.scheduleScan = schedule_scan;
      this.allowMultipleCourseConnections = allow_multiple_course_connections;

      this.autoArchiveCoursesAt = auto_archive_courses_at;
      this.autoArchiveConnectedCoursesAt = auto_archive_connected_courses_at;

      if (this.autoArchiveCoursesAt) {
        this.$refs.allCourseDatePicker.value = auto_archive_courses_at;
        this.autoArchiveCourseType = "all";
      } else if (this.autoArchiveConnectedCoursesAt) {
        this.$refs.connectedCourseDatePicker.value =
          auto_archive_connected_courses_at;
        this.autoArchiveCourseType = "connected";
      } else {
        this.autoArchiveCourseType = null;
      }
    },

    async getSettingFields() {
      try {
        const response = await customersAPI.getSettings("my_customer");
        const auto_archive_courses_at = response.data.auto_archive_courses_at
          ? new Date(response.data.auto_archive_courses_at)
          : response.data.auto_archive_courses_at;

        const auto_archive_connected_courses_at = response.data
          .auto_archive_connected_courses_at
          ? new Date(response.data.auto_archive_connected_courses_at)
          : response.data.auto_archive_connected_courses_at;

        await this.setSettingFields(
          response.data.support_contact,
          response.data.has_all_groups_whitelisted,
          response.data.force_course_name_tool,
          response.data.sync_teacher_removal,
          response.data.sync_student_removal,
          response.data.schedule_scan,
          response.data.allow_multiple_course_connections,
          auto_archive_courses_at,
          auto_archive_connected_courses_at,
        );
      } catch (error) {
        this.setPageError(error, "contact_fetch");
      } finally {
        this.isCreatedCallSettings = false;
      }
    },

    // whitelisted direct group setting methods

    async getWhitelistedGroups() {
      try {
        const response =
          await customersAPI.fetchWhitelistedGroups("my_customer");

        for (let i = 0; i < response.data.whitelisted_groups.length; i++) {
          this.whitelistedGroups.push({
            id: response.data.whitelisted_groups[i].id,
            name: response.data.whitelisted_groups[i].name,
            email: response.data.whitelisted_groups[i].email,
          });
        }
      } catch (error) {
        this.setPageError(error, "whitelisted_group_fetch");
      }
    },

    async deleteWhitelistedGroup(group_id) {
      try {
        this.loadingSettings = true;
        await customersAPI.removeWhitelistedGroup("my_customer", group_id);

        var index = this.whitelistedGroups.findIndex(function (f) {
          return f.id === group_id;
        });
        if (index !== -1) {
          this.whitelistedGroups.splice(index, 1);
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingSettings = false;
      }
    },

    async addWhitelistedGroupHandler(newGroupEmail) {
      try {
        this.loadingSettings = true;
        await customersAPI.addWhitelistedGroup(
          "my_customer",
          newGroupEmail.email,
          newGroupEmail.name,
          newGroupEmail.id,
        );

        this.whitelistedGroups.push({
          email: newGroupEmail.email,
          name: newGroupEmail.name,
          id: newGroupEmail.id,
        });
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingSettings = false;
      }
    },

    // excluded direct group setting methods

    async getExcludedGroups() {
      try {
        const response = await customersAPI.fetchExcludedGroups("my_customer");

        for (let i = 0; i < response.data.excluded_groups.length; i++) {
          this.excludedGroups.push({
            id: response.data.excluded_groups[i].id,
            name: response.data.excluded_groups[i].name,
            email: response.data.excluded_groups[i].email,
          });
        }
      } catch (error) {
        this.setPageError(error, "group_fetch");
      }
    },

    async deleteExcludedGroup(group_id) {
      try {
        this.loadingSettings = true;
        await customersAPI.removeExcludedGroup("my_customer", group_id);

        var index = this.excludedGroups.findIndex(function (f) {
          return f.id === group_id;
        });
        if (index !== -1) {
          this.excludedGroups.splice(index, 1);
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingSettings = false;
      }
    },

    async addExcludedGroupHandler(newGroupEmail) {
      try {
        this.loadingSettings = true;
        await customersAPI.addExcludedGroup(
          "my_customer",
          newGroupEmail.email,
          newGroupEmail.name,
          newGroupEmail.id,
        );

        this.excludedGroups.push({
          email: newGroupEmail.email,
          name: newGroupEmail.name,
          id: newGroupEmail.id,
        });
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingSettings = false;
      }
    },

    // excluded group condition setting methods

    async getExcludeConditions() {
      try {
        const response =
          await customersAPI.fetchExcludeConditions("my_customer");

        for (let i = 0; i < response.data.exclude_conditions.length; i++) {
          this.filterGroupConditions.push({
            id: response.data.exclude_conditions[i].id,
            filter: response.data.exclude_conditions[i].filter_field,
            operator: response.data.exclude_conditions[i].operator,
            textField: response.data.exclude_conditions[i].textField,
            tool: response.data.exclude_conditions[i].tool,
          });
        }
      } catch (error) {
        this.setPageError(error, "condition_fetch");
      }
    },

    async deleteExcludeCondition(conditionId) {
      this.loadingSettings = true;

      try {
        await customersAPI.removeExcludeCondition("my_customer", conditionId);
        this.filterGroupConditions = this.filterGroupConditions.filter(
          (x) => x["id"] !== conditionId,
        );
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingSettings = false;
      }
    },

    async saveExcludeCondition(conditionId, filter, operator, textField, tool) {
      this.loadingSettings = true;

      if (conditionId) {
        try {
          const response = await customersAPI.updateExcludeCondition(
            "my_customer",
            conditionId,
            filter,
            operator,
            textField,
            tool,
          );

          const condition = {
            id: response.data.id,
            operator: response.data.operator,
            filter: response.data.filter_field,
            textField: response.data.textField,
            tool: response.data.tool,
          };

          this.filterGroupConditions = [
            ...this.filterGroupConditions.filter(
              (x) => x["id"] !== condition["id"],
            ),
            condition,
          ];
        } catch (error) {
          if (error.response.status === 404) {
            this.filterGroupConditions = this.filterGroupConditions.filter(
              (x) => x["id"] !== conditionId,
            );
          }
          this.setPageError(error);
        } finally {
          this.loadingSettings = false;
        }
      } else {
        try {
          const response = await customersAPI.addExcludeCondition(
            "my_customer",
            filter,
            operator,
            textField,
            tool,
          );
          this.filterGroupConditions.push({
            id: response.data.id,
            operator: response.data.operator,
            filter: response.data.filter_field,
            textField: response.data.textField,
            tool: response.data.tool,
          });
        } catch (error) {
          this.setPageError(error);
        } finally {
          this.loadingSettings = false;
        }
      }
    },

    // whitelist group condition setting methods

    async getWhitelistConditions() {
      try {
        const response =
          await customersAPI.fetchWhitelistConditions("my_customer");

        for (let i = 0; i < response.data.whitelist_conditions.length; i++) {
          this.filterWhitelistGroupConditions.push({
            id: response.data.whitelist_conditions[i].id,
            filter: response.data.whitelist_conditions[i].filter_field,
            operator: response.data.whitelist_conditions[i].operator,
            textField: response.data.whitelist_conditions[i].textField,
            tool: response.data.whitelist_conditions[i].tool,
          });
        }
      } catch (error) {
        this.setPageError(error, "whitelisted_condition_fetch");
      }
    },

    async saveWhitelistCondition(
      conditionId,
      filter,
      operator,
      textField,
      tool,
    ) {
      this.loadingSettings = true;

      if (conditionId) {
        try {
          const response = await customersAPI.updateWhitelistCondition(
            "my_customer",
            conditionId,
            filter,
            operator,
            textField,
            tool,
          );

          const condition = {
            id: response.data.id,
            operator: response.data.operator,
            filter: response.data.filter_field,
            textField: response.data.textField,
            tool: response.data.tool,
          };

          this.filterWhitelistGroupConditions = [
            ...this.filterWhitelistGroupConditions.filter(
              (x) => x["id"] !== condition["id"],
            ),
            condition,
          ];
        } catch (error) {
          if (error.response.status === 404) {
            this.filterWhitelistGroupConditions =
              this.filterWhitelistGroupConditions.filter(
                (x) => x["id"] !== conditionId,
              );
          }
          this.setPageError(error);
        } finally {
          this.loadingSettings = false;
        }
      } else {
        try {
          const response = await customersAPI.addWhitelistCondition(
            "my_customer",
            filter,
            operator,
            textField,
            tool,
          );
          this.filterWhitelistGroupConditions.push({
            id: response.data.id,
            operator: response.data.operator,
            filter: response.data.filter_field,
            textField: response.data.textField,
            tool: response.data.tool,
          });
        } catch (error) {
          this.setPageError(error);
        } finally {
          this.loadingSettings = false;
        }
      }
    },

    async deleteWhitelistCondition(conditionId) {
      this.loadingSettings = true;

      try {
        await customersAPI.removeWhitelistCondition("my_customer", conditionId);
        this.filterWhitelistGroupConditions =
          this.filterWhitelistGroupConditions.filter(
            (x) => x["id"] !== conditionId,
          );
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingSettings = false;
      }
    },

    async updateSettings() {
      if (!this.isCreatedCallSettings) {
        this.loadingSettings = true;

        try {
          await customersAPI.updateSettings({
            customerId: "my_customer",
            contactEmailSelect: this.contactEmailSelect,
            hasAllWhitelisted: this.whitelistAllGroups,
            forceCourseNameTool: this.forceCourseNameTool,
            syncTeacherRemoval: this.syncTeacherRemoval,
            syncStudentRemoval: this.syncStudentRemoval,
            scheduleScan: this.scheduleScan,
            allowMultipleCourseConnections: this.allowMultipleCourseConnections,
            autoArchiveCoursesAt: this.autoArchiveCoursesAt,
            autoArchiveConnectedCoursesAt: this.autoArchiveConnectedCoursesAt,
          });
          this.setSupportContact(this.contactEmailSelect);
        } catch (error) {
          this.isCreatedCallSettings = true;
          await this.getSettingFields();
          this.isCreatedCallSettings = false;

          this.setPageError(error);
        } finally {
          this.loadingSettings = false;
        }
      }
    },

    async getCourseNameTool() {
      try {
        const response = await customersAPI.getCourseNameTool("my_customer");
        await this.setDataForCourseNameTool(response.field, response.tools);
      } catch (error) {
        this.setPageError(error, "course_name_tool_fetch");
      } finally {
        this.isCreatedCallCourseNameTool = false;
      }
    },

    async setDataForCourseNameTool(field, tools) {
      this.courseNameGroupField = field;
      this.courseNameGroupFieldTools = tools;
    },

    async updateCourseNameTool() {
      if (!this.isCreatedCallCourseNameTool) {
        this.loadingSettings = true;

        try {
          await customersAPI.updateCourseNameTool(
            "my_customer",
            this.courseNameGroupField,
            this.courseNameGroupFieldTools,
          );
        } catch (error) {
          this.isCreatedCallCourseNameTool = true;
          await this.getCourseNameTool();
          this.isCreatedCallCourseNameTool = false;
          this.setPageError(error);
        } finally {
          this.loadingSettings = false;
        }
      }
    },

    setPageError(error, section) {
      if (error.response) {
        if (error.response.data.detail) {
          var detail = error.response.data.detail;
          if (
            detail === "unauthorized_client" ||
            detail == "unknown_customer"
          ) {
            this.pageError =
              "Error. You are not authorized to use this application. Application is not installed in gsuite marketplace for this customer. If the app is already installed, please make sure to grant access to the scopes. For more details follow the following steps- \n 1. From the Admin console Home page, go to Apps and then G Suite Marketplace apps. \n 2. Click the app's name-Online Partner Drive Admin Tool \n 3. Grant Access";
            this.handleUnauthorisedError();
          } else if (
            detail === "transport_error" ||
            detail == "invalid_token" ||
            detail == "session_expired"
          ) {
            this.signOut();
          } else {
            console.log(error);
            this.pageError = "action_failed_error";
          }
          return;
        }
      }
      if (section == "group_fetch") {
        this.pageError = "Failed to fetch excluded groups! Try again.";
      } else if (section == "whitelisted_group_fetch") {
        this.pageError = "Failed to fetch whitelisted groups! Try again.";
      } else if (section == "condition_fetch") {
        this.pageError = "Failed to fetch exclude conditions! Try again.";
      } else if (section == "whitelisted_condition_fetch") {
        this.pageError = "Failed to fetch whitelist conditions! Try again.";
      } else if (section == "contact_fetch") {
        this.pageError = "Failed to fetch contact email! Try again.";
      } else if (section == "course_name_tool_fetch") {
        this.pageError = "Failed to fetch course name tool! Try again.";
      } else {
        this.pageError = "action_failed_error";
      }
    },
  },

  watch: {
    async sessionActive(current) {
      if (current && this.hasContacted) {
        await this.getSettings();
      }
    },

    async forceCourseNameTool() {
      await this.updateSettings();
    },

    async syncTeacherRemoval() {
      await this.updateSettings();
    },

    async syncStudentRemoval() {
      await this.updateSettings();
    },

    async courseNameGroupField() {
      await this.updateCourseNameTool();
    },

    courseNameGroupFieldTools: {
      handler() {
        this.updateCourseNameTool();
      },
      deep: true,
    },

    pageError(val) {
      if (val) {
        window.scrollTo(0, 0);
      }
    },

    whitelistAllGroups() {
      this.updateSettings();
    },

    selectedGroupSorting() {
      this.filteredExcludedGroupsList;
    },

    selectedWhitelistedGroupSorting() {
      this.filteredWhitelistedGroupsList;
    },

    contactEmailSelect() {
      clearTimeout(this.searchTimeOut);
      this.searchTimeOut = setTimeout(() => {
        this.updateSettings();
      }, 800);
    },

    async scheduleScan() {
      await this.updateSettings();
    },

    async autoArchiveCoursesAt() {
      await this.updateSettings();
    },

    async autoArchiveConnectedCoursesAt() {
      await this.updateSettings();
    },

    allowMultipleCourseConnections(val) {
      if (val) {
        this.forceCourseNameTool = false;
      }

      this.updateSettings();
    },

    autoArchiveCourseType(val) {
      if (val == "all") {
        this.autoArchiveConnectedCoursesAt = null;
        this.$refs.connectedCourseDatePicker.value = null;
      } else if (val == "connected") {
        this.autoArchiveCoursesAt = null;
        this.$refs.allCourseDatePicker.value = null;
      } else {
        this.autoArchiveConnectedCoursesAt = null;
        this.autoArchiveCoursesAt = null;
        if (this.$refs) {
          this.$refs.connectedCourseDatePicker.value = null;
          this.$refs.allCourseDatePicker.value = null;
        }
      }
    },
  },

  async created() {
    if (this.sessionActive && this.hasContacted) {
      await this.getSettings();
    }
  },
};
</script>
