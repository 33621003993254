<template>
  <v-container class="align-self-center" style="margin: 50px 100px 50px 100px">
    <v-row>
      <v-col
        cols="6"
        class="d-flex flex-column justify-center"
        style="padding: 0 0 0 100px"
      >
        <h1 class="mb-50 text-h3 text-dark-teal font-weight-bold">
          <span v-t="'sorry'"></span>
        </h1>
        <p class="text-h6 font-weight-regular text-justify">
          <span v-t="'insuffecient_permission'"></span><br /><br />
          <span v-t="'more_info_contact'"></span><br />
          admin@mail.com
        </p>

        <v-btn
          class="d-flex justify-start pa-0 ma-0"
          color="dark-teal"
          variant="text"
          @click="backToHome()"
          ><v-icon color="dark-teal">mdi-step-backward</v-icon>
          <span v-t="'back_to_home'"></span
        ></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.text-h6,
.text-h3 {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 400 !important;
}
</style>

<script>
import store from "../store";

export default {
  beforeRouteEnter(to, from, next) {
    if (!store.getters["google/idToken"]) {
      next("/");
    } else {
      next();
    }
  },

  methods: {
    backToHome() {
      this.$router.push("/").catch(() => {});
    },
  },
};
</script>
