<template>
  <v-container
    class="d-flex flex-column justify-center align-center align-self-center"
    style="max-width: 100%"
  >
    <div style="margin-top: 200px" class="mb-10">
      <v-img cover src="/Trelson-Logo-Brown-1080.png" width="500px" />
    </div>

    <div
      class="d-flex flex-column justify-center align-center text-h6 font-weight-regular mb-5"
      style="max-width: 1000px"
    >
      <span
        class="text-h6 font-weight-regular"
        v-t="'cm_home_page'"
        style="text-align: center"
      ></span>
    </div>

    <div v-if="!idToken" class="d-flex">
      <div id="buttonDiv"></div>
    </div>

    <div
      v-else-if="idToken && !sessionError"
      class="d-flex flex-column align-center"
    >
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <span v-t="'retrieving_user_role'" class="mt-5"></span>
    </div>

    <div v-else>
      <v-alert
        density="compact"
        style="width: 1000px"
        variant="outlined"
        type="error"
        v-if="sessionError"
        closable
      >
        {{ $t(sessionError) }}
      </v-alert>
    </div>
  </v-container>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();

const idToken = computed(() => store.getters["google/idToken"]);
const sessionError = computed(() => store.getters.sessionError);

onMounted(() => {
  if (window.google) {
    window.google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      {
        text: "Sign in with Google",
        logo_alignment: "left",
      },
    );
  }
});
</script>

<style scoped>
.mb-50 {
  margin-bottom: 50px !important;
}
.text-h6 {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 400 !important;
}
</style>
