<template>
  <v-container>
    <Error
      :pageError="pageError"
      v-if="pageError"
      @on-dismiss="pageError = null"
    ></Error>

    <!-- Headline  -->
    <div class="mt-10">
      <div :class="`d-flex align-center`" style="position: relative">
        <div class="d-flex flex-column">
          <!-- Display the page title  -->
          <div style="height: 35px">
            <span class="text-dark-gray" style="font-size: 30px">
              {{ $t("course", 2) }}
            </span>
          </div>

          <!-- Display the last scanned timestamp  -->
          <div class="captionfont" v-if="lastScannedTimestamp">
            <span v-t="'last_scanned'"></span>: {{ lastScannedTimestamp }}

            <span
              v-if="
                compTitle === 'orphanedCourse' &&
                scanningStatus != 'in progress'
              "
            >
              <!-- Display alert if the last scan has failed -->
              <TooltipButton
                v-if="scanningStatus === 'failed'"
                iColor="red"
                iconName="mdi-alert"
                :tooltipMsg="$t('failed_scan_warning')"
                iconSize="18"
              />

              <!-- Display alert if scan results are outdated -->
              <TooltipButton
                v-else-if="isScanOutdated"
                iColor="red"
                iconName="mdi-alert"
                :tooltipMsg="$t('outdated_scan_warning')"
                iconSize="18"
              />
            </span>
          </div>
        </div>
        <v-spacer></v-spacer>

        <!-- Select view by functionality valididty -->
        <v-select
          v-if="compTitle === 'orphanedCourse'"
          :label="$t('view_by')"
          style="max-width: 400px"
          class="myselect mb-2 mr-4"
          :items="[
            { text: $t('full_functionality'), value: false },
            { text: $t('limited_functionality'), value: true },
          ]"
          item-title="text"
          item-value="value"
          :mandatory="true"
          v-model="showFullCapabilityExpired"
          variant="outlined"
          density="compact"
          hide-details
        />

        <v-text-field
          v-model="search"
          hide-details
          density="compact"
          variant="outlined"
          :placeholder="$t('search_by') + ' ' + $t('name')"
          prepend-inner-icon="mdi-magnify"
          clearable
          color="secondary"
          class="myselect mb-2 mr-4"
          :disabled="
            (!nextCursor && courses.length == 0) ||
            loadingNextPage ||
            scanningStatus === 'in progress'
          "
          style="width: 200px"
        >
        </v-text-field>

        <!-- Action: Scan now -->
        <v-btn
          v-if="compTitle === 'orphanedCourse'"
          color="primary"
          variant="flat"
          @click="scannow()"
          class="d-flex align-self-end mb-2 mr-2"
          :disabled="
            loadingNextPage || scanningStatus === 'in progress' || !enabledScan
          "
          ><span v-t="'scan_now'"></span
          ><v-icon size="small">mdi-scanner</v-icon></v-btn
        >

        <!-- Action: Refresh table content -->
        <v-btn
          color="primary"
          variant="flat"
          @click="getFirstPage()"
          class="d-flex align-self-end mb-2 mr-2"
          :disabled="
            loadingNextPage || scanningStatus === 'in progress' || !enabledScan
          "
        >
          <span v-t="'refresh'"> </span>
          <v-icon size="small">mdi-refresh</v-icon>
        </v-btn>

        <!-- More actions -->
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              color="primary"
              variant="flat"
              :disabled="
                loadingNextPage || selected.length == 0 || !enabledScan
              "
              class="d-flex align-self-end mb-2"
            >
              <span v-t="'more'"> </span>
              <v-icon class="">mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <!-- Action: Delete selected courses  -->
          <v-list>
            <v-list-item
              @click="
                deleteCourseDialog = true;
                errorCourseMessage = '';
              "
              :disabled="selected.length == 0"
            >
              <span class="fontcls" v-t="'delete_course'"></span>
            </v-list-item>

            <!-- Action: Change the course state of selected courses  -->
            <v-list-item
              @click="
                changeCourseStateDialog = true;
                changeCourseStateTo = null;
                errorCourseMessage = '';
              "
              :disabled="selected.length == 0"
            >
              <span class="fontcls" v-t="'change_course_state'"></span>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="divider bg-secondary"></div>
    </div>
    <!-- End of headline  -->

    <v-progress-linear
      v-if="loadingNextPage || scanningStatus === 'in progress'"
      indeterminate
      color="primary"
      height="2px"
    ></v-progress-linear>

    <!-- Course states selection for display -->
    <div
      class="d-flex justify-space-between"
      v-if="scanningStatus != 'in progress' && enabledScan"
    >
      <v-chip-group
        v-model="currentCourseState"
        selected-class="bg-secondary"
        mandatory
      >
        <v-chip
          :disabled="loadingNextPage || scanningStatus === 'in progress'"
          color="light-gray"
          class="text-dark-teal text-subtitle-2 rounded-pill"
          v-for="(state, index) in courseStates"
          :key="index"
          :value="state.value"
        >
          {{ $t(state.name) }}
        </v-chip>
      </v-chip-group>

      <div class="d-flex">
        <!-- Display the total orphaned courses recorded during last scan  -->
        <span class="captionfont mr-4" v-if="compTitle === 'orphanedCourse'">
          <span v-t="'total_orphaned_courses'"></span>:
          <span>{{ totalOrphanedCourses }}</span>
        </span>

        <!-- Display the total courses processed during last scan  -->
        <span class="captionfont" v-if="compTitle === 'orphanedCourse'">
          <span v-t="'total_courses_processed'"></span>:
          <span>{{ totalCoursesProcessed }}</span>
        </span>
      </div>
    </div>

    <span
      class="d-flex justify-center text-dark-gray mt-2"
      v-if="compTitle === 'orphanedCourse' && scanningStatus === 'in progress'"
      ><i><span v-t="'scanning_in_progress'"></span>..</i></span
    >

    <!-- datatable for the list of courses  -->
    <v-data-table
      v-else
      v-model:sort-by="sortBy"
      :headers="headers"
      v-model="selected"
      :items="courses"
      :show-select="courses.length != 0"
      :search="search"
      item-key="id"
      return-object
      class="mydatatable bg-background"
      color="primary"
      :loading-text="$t('loading_please_wait')"
      :items-per-page="-1"
      v-model:page="page"
      fixed-header
      height="550"
      :hover="true"
    >
      <!-- name -->
      <template v-slot:item.name="{ item }">
        <a
          target="_blank"
          class="text-dark-teal"
          :href="item.alternate_link"
          v-if="item.alternate_link"
        >
          {{ item.name }}
        </a>

        <a v-else class="text-dark-teal">{{ item.name }}</a>
      </template>

      <!-- description -->
      <template
        v-slot:item.description="{ item }"
        v-if="compTitle === 'orphanedCourse'"
      >
        <span
          v-if="item.description"
          style="max-width: 200px"
          class="d-inline-block text-truncate"
        >
          {{ item.description }}
        </span>

        <span v-else>_</span>
      </template>

      <!-- has co teachers -->
      <template v-slot:item.has_co_teachers="{ item }">
        <span v-if="item.has_co_teachers">Yes</span>
        <span v-else>No</span>
      </template>
      <!-- has students -->
      <template v-slot:item.has_students="{ item }">
        <span v-if="item.has_students == true">Yes</span>
        <span v-else-if="item.has_students == false">No</span>
        <span v-else>_</span>
      </template>

      <!-- update time -->
      <template v-slot:item.update_time="{ item }">
        <span v-if="item.update_time">{{
          (
            new Date(item.update_time).toLocaleDateString() +
            ", " +
            new Date(item.update_time).toTimeString()
          ).replace(/GMT.*$/, "")
        }}</span>

        <span v-else>_</span>
      </template>

      <!-- Actions -->
      <template v-slot:item.actions="{ item }">
        <v-tooltip location="bottom" color="dark-grey" class="text-white">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="mr-2"
              variant="text"
              icon
              size="x-small"
              :to="{
                name: 'admin-courseName-courseId-teachers',
                params: {
                  courseName: item.name,
                  courseId: item.id,
                },
              }"
            >
              <span
                ><v-icon color="dark-gray" size="22">mdi-head-cog</v-icon></span
              >
            </v-btn>
          </template>
          <span>
            <span
              v-if="compTitle == 'orphanedCourse' && showFullCapabilityExpired"
              v-t="'view'"
            ></span>
            <span v-else v-t="'manage'"></span>
            {{ $t("course", 1) }}
            <span>{{ $t("teacher", 2) }}</span>
          </span>
        </v-tooltip>

        <v-tooltip location="bottom" color="dark-grey" class="text-white">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="text"
              icon
              size="x-small"
              :to="{
                name: 'admin-courseName-courseId-students',
                params: {
                  courseName: item.name,
                  courseId: item.id,
                },
              }"
            >
              <span
                ><v-icon color="dark-gray" size="24"
                  >mdi-account-cog</v-icon
                ></span
              >
            </v-btn>
          </template>
          <span>
            <span v-t="'manage'"></span>
            {{ $t("course", 1) }}
            <span>{{ $t("student", 2) }}</span>
          </span>
        </v-tooltip>
      </template>

      <template v-slot:bottom></template>
    </v-data-table>

    <!-- load more for the next page of courses  -->
    <div class="d-flex justify-center">
      <LoadMore
        v-if="
          compTitle == 'orphanedCourse' &&
          nextCursor &&
          scanningStatus != 'in progress' &&
          enabledScan
        "
        :loading="loadingNextPage"
        @next-page="getNextPage"
      ></LoadMore>
    </div>

    <!-- Delete course dialog  -->
    <DialogBox
      :dialog="deleteCourseDialog"
      :disabledCancel="loadingNextPage"
      :title="$t('ask_to_confirm_to_delete_course')"
      :actionName="$t('confirm')"
      :dialogErrMsg="errorCourseMessage"
      :loading="loadingNextPage"
      dialogWidth="600px"
      @close-dialog="cancel()"
      @action-handler="deleteCourses()"
      @close-dialog-err-msg="errorCourseMessage = ''"
    >
      <div class="listcontent">
        <v-list class="pa-0" v-for="(course, index) in selected" :key="index">
          <v-list-item density="compact">
            <span class="fontcls">{{ course.name }} </span>
          </v-list-item>
        </v-list>
      </div>
    </DialogBox>

    <!-- Change course state dialog  -->
    <DialogBox
      :dialog="changeCourseStateDialog"
      :disabledAction="!changeCourseStateTo"
      :disabledCancel="loadingNextPage"
      :title="$t('ask_to_confirm_change_course_state')"
      :actionName="$t('confirm')"
      :dialogErrMsg="errorCourseMessage"
      :loading="loadingNextPage"
      dialogWidth="600px"
      @close-dialog="cancel()"
      @action-handler="changeCourseState()"
      @close-dialog-err-msg="errorCourseMessage = ''"
    >
      <div class="listcontent">
        <v-list class="pa-0" v-for="(course, index) in selected" :key="index">
          <v-list-item density="compact">
            <span class="fontcls">{{ course.name }}</span>
          </v-list-item>
        </v-list>
      </div>

      <v-divider class="mb-4"></v-divider>

      <label
        ><span v-t="'change_course_state_to'" class="fontcls"></span
      ></label>

      <v-radio-group
        v-model="changeCourseStateTo"
        :mandatory="false"
        hide-details
      >
        <v-radio
          :label="$t('active')"
          color="primary"
          value="ACTIVE"
          v-if="
            currentCourseState == 'PROVISIONED' ||
            currentCourseState == 'ARCHIVED'
          "
        ></v-radio>
        <v-radio
          :label="$t('provisioned')"
          color="primary"
          value="PROVISIONED"
          v-if="currentCourseState == 'DECLINED'"
        ></v-radio>
        <v-radio
          :label="$t('declined')"
          color="primary"
          value="DECLINED"
          v-if="currentCourseState == 'PROVISIONED'"
        ></v-radio>
        <v-radio
          :label="$t('archived')"
          color="primary"
          value="ARCHIVED"
          v-if="currentCourseState == 'ACTIVE'"
        ></v-radio>
      </v-radio-group>
    </DialogBox>

    <!-- Add owner dialog  -->
    <DialogBox
      :dialog="addOwnerDialog"
      :disabledAction="!newOwner"
      :disabledCancel="loadingNextPage"
      :title="$t('ask_to_confirm_to_add_owner')"
      :actionName="$t('confirm')"
      :dialogErrMsg="errorCourseMessage"
      :loading="loadingNextPage"
      dialogWidth="700px"
      @close-dialog="cancel()"
      @action-handler="addOwnerToCourse()"
      @close-dialog-err-msg="errorCourseMessage = ''"
    >
      <div class="listcontent">
        <v-list class="pa-0" v-for="(course, index) in selected" :key="index">
          <v-list-item density="compact">
            <span class="fontcls"> {{ course.name }}</span>
          </v-list-item>
        </v-list>
      </div>

      <v-divider class="mb-4"></v-divider>

      <label class="fontcls"><span v-t="'choose_desired_owner'"></span> </label>
      <AutocompleteTeachers
        :placeholderName="$t('name')"
        :placeholderEmail="$t('email')"
        @select="newOwner = $event"
        class="mt-2 mb-4"
      />
    </DialogBox>

    <SnackBar
      :text="alertStatusText"
      :color="alertStatusColor"
      v-if="alertStatus"
      @on-dismiss="
        alertStatus = false;
        alertStatusText = '';
      "
    ></SnackBar>

    <vue3-confirm-dialog></vue3-confirm-dialog>
  </v-container>
</template>

<style>
.v-input--checkbox .v-label {
  font-size: 20px;
}

div.v-data-table.background.v-data-table--fixed-header.theme--light
  div
  table
  tbody
  tr:not(:last-child)
  td {
  border-bottom: 0;
}

.v-table .v-table__wrapper > table > tbody > tr > td {
  color: #464646;
}

div.v-data-table.background.v-data-table--fixed-header.theme--light
  div
  table
  tbody
  tr
  td {
  color: #464646;
}

.mdi-arrow-up::before {
  color: white;
}
.mdi-arrow-up::after {
  color: transparent;
}

.mydatatable thead th:first-child {
  border-radius: 6px 0 0 6px;
}
.mydatatable thead th:last-child {
  border-radius: 0 6px 6px 0;
}

.v-input.myselect .v-input__control .v-field {
  height: 38px !important;
}

.v-data-table-header th {
  white-space: nowrap;
}
</style>

<style scoped>
.myselect {
  border-radius: 6px;
  border: 1px;
  background: white;
  align-self: end;
}

.listcontent {
  max-height: 300px;
  font-size: 16px;
  overflow-y: auto !important;
}

.captionfont {
  letter-spacing: 0.1px !important;
  font-size: 12px !important;
  color: #464646 !important;
  font-weight: 500 !important;
  margin-top: 4px !important;
}

.text-subtitle-2 {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 400 !important;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import useGetTaskStatus from "@/composables/getTaskStatus";

import orphanedCoursesAPI from "@/api/orphanedCourses";
import coursesAPI from "@/api/courses";

import AutocompleteTeachers from "@/components/autocompleteTeachers";

import Error from "@/components/error.vue";

import SnackBar from "@/components/snackBar.vue";
import DialogBox from "@/components/dialog.vue";
import LoadMore from "@/components/loadMoreButton";
import TooltipButton from "@/components/tooltipButton";

export default {
  setup() {
    const { getOrphanedTaskStatus } = useGetTaskStatus();
    return { getOrphanedTaskStatus };
  },

  components: {
    Error,
    SnackBar,
    DialogBox,
    AutocompleteTeachers,
    LoadMore,
    TooltipButton,
  },

  props: {
    compTitle: {
      type: String,
    },
  },

  data() {
    return {
      pageError: null,
      selected: [],
      courses: [],
      currentCourseState: "ACTIVE",
      courseStates: [
        { name: "active", value: "ACTIVE" },
        { name: "provisioned", value: "PROVISIONED" },
        { name: "declined", value: "DECLINED" },
        { name: "archived", value: "ARCHIVED" },
      ],
      nextCursor: null,
      loadingNextPage: false,
      search: "",
      sortBy: [],

      page: 1,
      lastScannedTimestamp: null,

      totalCoursesProcessed: 0,
      totalOrphanedCourses: 0,
      scanningStatus: "completed",

      errorCourseMessage: "",

      changeCourseStateDialog: false,
      changeCourseStateTo: null,

      addOwnerDialog: false,
      newOwner: false,

      deleteCourseDialog: false,

      alertStatus: false,
      alertStatusText: "",
      alertStatusColor: "black",

      showFullCapabilityExpired: true,
    };
  },

  computed: {
    ...mapGetters({
      sessionActive: "sessionActive",
      hasContacted: "hasContacted",
      enabledScan: "enabledScan",
    }),

    headers() {
      var headersData = [
        {
          title: "",
          key: "data-table-select",
          width: "4%",
        },
        {
          title: this.$t("name"),
          value: "name",
          align: "start",
          width: "10%",
          sortable: true,
        },
        {
          title: this.$t("description"),
          value: "description",
          align: "start",
          width: "10%",
          sortable: false,
        },
        {
          title: this.$t("update_time"),
          value: "update_time",
          align: "start",
          width: "10%",
          sortable: true,
        },
        {
          title: this.$t("has_co_teachers"),
          value: "has_co_teachers",
          align: "start",
          width: "10%",
          sortable: true,
        },
        {
          title: this.$t("has_students"),
          value: "has_students",
          align: "start",
          width: "10%",
          sortable: true,
        },
        {
          title: this.$t("actions"),
          value: "actions",
          sortable: false,
          align: "start",
          width: "15%",
        },
      ];

      if (this.courses.length == 0) {
        return [];
      } else if (this.compTitle == "orphanedCourse") {
        return headersData;
      } else {
        return [];
      }
    },

    isScanOutdated() {
      if (this.lastScannedTimestamp) {
        try {
          var diff =
            new Date().getTime() -
            new Date(this.lastScannedTimestamp).getTime();

          if (diff / (1000 * 3600 * 24) >= 0.5) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          return false;
        }
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions({
      handleUnauthorisedError: "handleUnauthorisedError",
      signOut: "signOut",
    }),

    getFirstPage() {
      this.selected = [];
      this.nextCursor = null;
      this.courses = [];

      if (this.compTitle == "orphanedCourse") {
        this.getNextPage();
      }
    },

    async getNextPage() {
      this.loadingNextPage = true;

      try {
        var sortby = this.sortBy.length
          ? this.sortBy[0].order == "asc"
            ? this.sortBy[0].key
            : "-" + this.sortBy[0].key
          : null;

        const response = await orphanedCoursesAPI.get(
          "my_customer",
          this.nextCursor,
          this.currentCourseState,
          sortby,
          this.showFullCapabilityExpired,
        );

        this.lastScannedTimestamp = response.data.last_scanned_timestamp
          ? new Date(response.data.last_scanned_timestamp).toLocaleString()
          : null;
        this.totalCoursesProcessed = response.data.total_courses_processed;
        this.totalOrphanedCourses =
          response.data.total_orphaned_courses_recorded;

        // Add response courses to datatable rows
        if (response.data.courses.length !== 0) {
          this.courses = this.courses.concat(response.data.courses);
        }
        this.nextCursor = response.data.next_page_token;

        this.scanningStatus = response.data.task_status;
        if (this.scanningStatus === "in progress") {
          this.pollTaskStatus();
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingNextPage = false;
      }
    },

    async pollTaskStatus() {
      this.pageError = null;

      await this.getOrphanedTaskStatus(1, (success) => {
        if (success) {
          this.scanningStatus = "completed";
          this.getFirstPage();
        } else {
          this.pageError = this.$t("failed_scan_warning");
          this.scanningStatus = "failed";
          this.getFirstPage();
        }
      });
    },

    async scannow() {
      this.$confirm({
        message: this.$t("continue?"),
        button: {
          no: this.$t("no"),
          yes: this.$t("yes"),
        },
        callback: async (confirm) => {
          if (confirm) {
            this.scanningStatus = "in progress";
            this.pageError = null;

            await orphanedCoursesAPI
              .scannow("my_customer")
              .then((response) => {
                this.getOrphanedTaskStatus(
                  response.data.taskStatusId,
                  (success) => {
                    if (success) {
                      this.scanningStatus = "completed";
                      this.getFirstPage();
                    } else {
                      this.pageError = this.$t("failed_scan_warning");
                      this.scanningStatus = "failed";
                      this.getFirstPage();
                    }
                  },
                );
              })
              .catch((error) => {
                this.setPageError(error);
                this.lastScannedTimestamp = new Date().toLocaleString();
                this.scanningStatus = "failed";
              });
          }
        },
      });
    },

    async deleteCourses() {
      this.loadingNextPage = true;
      const scheduledPromises = [];

      for (let i = 0; i < this.selected.length; i++) {
        scheduledPromises.push(
          coursesAPI.deleteCourse("my_customer", this.selected[i].id),
        );
      }
      const scheduledResults = await Promise.allSettled(scheduledPromises);

      this.handleScheduledResults(
        scheduledResults,
        this.$t("course_deleted_successfully"),
      );
    },

    async changeCourseState() {
      if (this.changeCourseStateTo) {
        this.loadingNextPage = true;
        const scheduledPromises = [];

        for (let i = 0; i < this.selected.length; i++) {
          scheduledPromises.push(
            coursesAPI.changeCourseState(
              "my_customer",
              this.selected[i].id,
              this.selected[i].name,
              this.changeCourseStateTo,
            ),
          );
        }
        const scheduledResults = await Promise.allSettled(scheduledPromises);

        this.handleScheduledResults(
          scheduledResults,
          this.$t("course_state_changed_successfully"),
        );
      }
    },

    async addOwnerToCourse() {
      if (this.newOwner && this.newOwner.id) {
        this.loadingNextPage = true;
        const scheduledPromises = [];

        for (let i = 0; i < this.selected.length; i++) {
          scheduledPromises.push(
            coursesAPI.transferOwnership(
              "my_customer",
              this.selected[i].id,
              this.newOwner.id,
            ),
          );
        }
        const scheduledResults = await Promise.allSettled(scheduledPromises);

        this.handleScheduledResults(
          scheduledResults,
          this.$t("owner_added_successfully"),
          "addOwner",
        );
      }
    },

    async handleScheduledResults(scheduledResults, successMsg, action = null) {
      this.errorCourseMessage = "";
      this.pageError = null;
      var tempSingleCourseErrMsg = "";
      var selectedResp = [];

      for (let i = 0; i < scheduledResults.length; i++) {
        const { status, value } = scheduledResults[i];

        if (status === "rejected") {
          selectedResp.push(this.selected[i]);

          tempSingleCourseErrMsg = await this.getErrMsgFromRejectedPromise(
            scheduledResults[i],
          );
        }
        if (status === "fulfilled") {
          const course = this.courses.find((c) => c.id === this.selected[i].id);

          if (course) {
            if (this.compTitle !== "orphanedCourse" && action == "addOwner") {
              course.owner_id = value.data.ownerId;
              course.owner_email = value.data.ownerEmail;
              course.update_time = value.data.updateTime;
            } else {
              var index = this.courses.findIndex(function (c) {
                return c.id === course.id;
              });

              if (index !== -1) {
                this.courses.splice(index, 1);
              }
            }
          }
        }
      }

      this.selected = selectedResp;
      this.loadingNextPage = false;

      this.handleCourseActionError(tempSingleCourseErrMsg, successMsg);
    },

    getErrMsgFromRejectedPromise(scheduledResult) {
      if (
        scheduledResult &&
        scheduledResult.reason &&
        scheduledResult.reason.response &&
        scheduledResult.reason.response.data
      ) {
        return scheduledResult.reason.response.data.detail;
      } else {
        return "";
      }
    },

    async handleCourseActionError(tempSingleCourseErrMsg, successMsg) {
      if (this.selected.length === 0) {
        this.cancel();
        this.alertStatusText = successMsg;
        this.alertStatusColor = "#4BB543";
        this.alertStatus = true;
      } else if (this.selected.length == 1 && tempSingleCourseErrMsg) {
        this.errorCourseMessage = tempSingleCourseErrMsg;
      } else {
        this.errorCourseMessage = this.$t("action_failed_error");
      }

      if (this.compTitle === "orphanedCourse") {
        await this.getOrphanedCoursesCount();
      }
    },

    async getOrphanedCoursesCount() {
      try {
        const response = await orphanedCoursesAPI.getTotalCount("my_customer");
        this.totalOrphanedCourses =
          response.data.total_orphaned_courses_recorded;
      } catch (error) {
        this.setPageError(error);
      }
    },

    cancel() {
      this.changeCourseStateDialog = false;
      this.errorCourseMessage = "";
      this.changeCourseStateTo = null;
      this.newOwner = null;

      this.addOwnerDialog = false;
      this.deleteCourseDialog = false;
    },

    setPageError(error) {
      if (error.response) {
        if (error.response.data.detail) {
          var detail = error.response.data.detail;
          if (
            detail === "unauthorized_client" ||
            detail == "unknown_customer"
          ) {
            this.pageError =
              "Error. You are not authorized to use this application. Application is not installed in gsuite marketplace for this customer. If the app is already installed, please make sure to grant access to the scopes. For more details follow the following steps- \n 1. From the Admin console Home page, go to Apps and then G Suite Marketplace apps. \n 2. Click the app's name-Online Partner Drive Admin Tool \n 3. Grant Access";
            this.handleUnauthorisedError();
          } else if (
            detail === "transport_error" ||
            detail == "invalid_token" ||
            detail == "session_expired"
          ) {
            this.signOut();
          } else {
            this.pageError = detail;
          }
          return;
        }
      }
      this.pageError = "action_failed_error";
    },
  },

  watch: {
    async sessionActive(current) {
      if (current && this.hasContacted) {
        await this.getFirstPage();
      }
    },

    async showFullCapabilityExpired() {
      this.getFirstPage();
    },

    sortBy: {
      handler() {
        this.getFirstPage();
      },
      deep: true,
    },

    currentCourseState() {
      this.getFirstPage();
    },
  },

  async created() {
    if (this.sessionActive && this.hasContacted) {
      await this.getFirstPage();
    }
  },
};
</script>
