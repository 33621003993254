import api from "../plugins/api";

export const get = async (params) => {
  const data = await api.get(`/customers/${params.customerId}/courses`, {
    params: {
      me: params.me,
      teacher_mail: params.teacher_mail,
      student_mail: params.student_mail,
      nextPageToken: params.nextPageToken,
      courseState: params.courseState,
    },
  });
  return data;
};

export const create = async (
  customerId,
  className,
  subject,
  section,
  room,
  description,
  coursestate,
) => {
  const response = await api.post(`/customers/${customerId}/courses`, {
    name: className,
    subject: subject,
    section: section,
    room: room,
    description: description,
    coursestate: coursestate,
  });
  return response;
};

export const getMemberCourses = async (
  customerId,
  teacherEmail,
  studentEmail,
  nextPageToken,
  me,
) => {
  const data = await api.get(`/customers/${customerId}/courses/member`, {
    params: {
      teacher_email: teacherEmail,
      student_email: studentEmail,
      nextPageToken: nextPageToken,
      me: me,
    },
  });
  return data;
};

export const addSubstituteTeacherToCourse = async (
  customerId,
  courseId,
  courseName,
  alternateLink,
  addedTill,
) => {
  const data = await api.post(
    `/customers/${customerId}/courses/${courseId}/substituteteacher`,
    {
      course_name: courseName,
      alternate_link: alternateLink,
      added_till: addedTill,
    },
  );
  return data;
};

export const removeSubstituteTeacherFromCourse = async (
  customerId,
  courseId,
) => {
  const data = await api.delete(
    `/customers/${customerId}/courses/${courseId}/substituteteacher`,
  );
  return data;
};

export const addSupportEducatorToCourse = async (customerId, courseId) => {
  const data = await api.post(
    `/customers/${customerId}/courses/${courseId}/educator`,
  );
  return data;
};

export const removeSupportEducatorFromCourse = async (customerId, courseId) => {
  const data = await api.delete(
    `/customers/${customerId}/courses/${courseId}/educator`,
  );
  return data;
};

export const getCourseTeachers = async (
  customerId,
  courseId,
  nextPageToken,
) => {
  const data = await api.get(
    `/customers/${customerId}/courses/${courseId}/teachers`,
    {
      params: {
        next_page_token: nextPageToken,
      },
    },
  );
  return data;
};

export const getCourseStudents = async (
  customerId,
  courseId,
  nextPageToken,
) => {
  const data = await api.get(
    `/customers/${customerId}/courses/${courseId}/students`,
    {
      params: {
        next_page_token: nextPageToken,
      },
    },
  );
  return data;
};

export const getCourseInvitations = async (
  customerId,
  courseId,
  roles,
  nextPageToken,
) => {
  var roles_param = "";
  for (var i = 0; i < roles.length; i++) {
    if (i == 0) {
      roles_param += "roles=" + roles[i];
    } else {
      roles_param += "&roles=" + roles[i];
    }
  }
  const data = await api.get(
    `/customers/${customerId}/courses/${courseId}/invitations?` + roles_param,
    {
      params: {
        next_page_token: nextPageToken,
      },
    },
  );
  return data;
};

export const deleteCourseInvitation = async (
  customerId,
  courseId,
  invitationId,
  userEmail,
  role,
) => {
  const data = await api.delete(
    `/customers/${customerId}/courses/${courseId}/invitations/${invitationId}`,
    {
      params: {
        user_email: userEmail,
        role: role,
      },
    },
  );
  return data;
};

export const createCourseInvitation = async (
  customerId,
  courseId,
  userId,
  userEmail,
  userName,
  role,
) => {
  const data = await api.post(
    `/customers/${customerId}/courses/${courseId}/invitations/${userId}/roles/${role}`,
    {
      user_email: userEmail,
      user_name: userName,
    },
  );
  return data;
};

export const addCourseTeacher = async (customerId, courseId, email) => {
  const data = await api.post(
    `/customers/${customerId}/courses/${courseId}/teachers/${email}`,
  );
  return data;
};

export const addCourseStudent = async (customerId, courseId, email) => {
  const data = await api.post(
    `/customers/${customerId}/courses/${courseId}/students/${email}`,
  );
  return data;
};

export const addGroupOfMembersToCourse = async (
  customerId,
  courseId,
  groupEmail,
  addOnly,
) => {
  const data = await api.post(
    `/customers/${customerId}/courses/${courseId}/groups/${groupEmail}`,
    {
      add_only: addOnly,
    },
  );
  return data;
};

export const deleteCourseTeacher = async (customerId, courseId, email) => {
  const data = await api.delete(
    `/customers/${customerId}/courses/${courseId}/teachers/${email}`,
  );
  return data;
};

export const deleteCourseStudent = async (
  customerId,
  courseId,
  studentId,
  studentEmail,
) => {
  const data = await api.delete(
    `/customers/${customerId}/courses/${courseId}/students/${studentId}`,
    { params: { student_email: studentEmail } },
  );
  return data;
};

export const transferOwnership = async (customerId, courseId, teacherId) => {
  const data = await api.post(
    `/customers/${customerId}/courses/${courseId}/teachers/${teacherId}/owner`,
  );
  return data;
};

export const getCourseName = async (customerId, group_id) => {
  const data = await api.get(`/customers/${customerId}/courses/name`, {
    params: {
      group_id: group_id,
    },
  });
  return data;
};

export const getCourse = async (customerId, courseId) => {
  const data = await api.get(
    `/customers/${customerId}/courses/${courseId}/get`,
  );
  return data;
};

export const deleteCourse = async (customerId, courseId) => {
  const data = await api.delete(`/customers/${customerId}/courses/${courseId}`);
  return data;
};

export const changeCourseState = async (
  customerId,
  courseId,
  courseName,
  courseState,
) => {
  const data = await api.post(
    `/customers/${customerId}/courses/${courseId}/state`,
    {
      name: courseName,
      course_state: courseState,
    },
  );
  return data;
};

export const whitelistFromAutoArchival = async (
  customerId,
  courseId,
  courseName,
  alternateLink,
  ownerId,
  whitelistFromAutoArchival,
) => {
  const data = await api.post(
    `/customers/${customerId}/courses/${courseId}/whitelist-from-auto-archival`,
    {
      course_name: courseName,
      alternate_link: alternateLink,
      owner_id: ownerId,
      whitelist_from_auto_archival: whitelistFromAutoArchival,
    },
  );

  return data;
};

export default {
  get,
  create,
  getMemberCourses,
  addSubstituteTeacherToCourse,
  removeSubstituteTeacherFromCourse,
  addSupportEducatorToCourse,
  removeSupportEducatorFromCourse,
  getCourseTeachers,
  addCourseTeacher,
  addGroupOfMembersToCourse,
  deleteCourseTeacher,
  deleteCourseStudent,
  getCourseStudents,
  addCourseStudent,
  transferOwnership,
  getCourseName,
  getCourse,
  deleteCourse,
  changeCourseState,
  getCourseInvitations,
  deleteCourseInvitation,
  createCourseInvitation,
  whitelistFromAutoArchival,
};
