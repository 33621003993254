import { createVuetify } from "vuetify";

import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import "@/assets/variables.scss";

const bp = {
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#5e9c96",
          "dark-teal": "#395f5b",
          secondary: "#5e9c96",
          "active-teal": "#88d5ce",
          "active-light-teal": "#a5dfda",
          "light-gray": "#EFEFEF",
          gray: "#797979",
          "dark-gray": "#464646",
          blue: "#0077BC",
          background: "#eff6f5",
        },
      },
    },
  },
};

export default createVuetify({
  components,
  directives,
  blueprint: bp,
  theme: {
    defaultTheme: "light",
  },
  icons: {
    defaultSet: "mdi", // default
  },
});
