<template>
  <v-dialog
    v-model="dialog"
    content-class="elevation-2"
    max-width="750px"
    persistent
  >
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        :variant="buttonOutlined ? 'outlined' : 'elevated'"
        elevation="0"
        class="mr-2 rounded-md"
        :height="height"
        :color="color"
      >
        <v-icon size="small">{{ buttonIcon }}</v-icon
        ><span>{{ buttonName }}</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-dark-teal">{{ title }}</v-card-title>
      <v-card-text>
        <!-- set course name dialog -->
        <DialogBox
          :dialog="courseNameDialog"
          dialogWidth="650px"
          :title="$t('set_course_name')"
          @close-dialog="closeSetCourseNameDialog()"
          :actionName="$t('choose')"
          @action-handler="attachCourseAndCloseDialog()"
          :disabledAction="isSetCourseNameDialogDisabled"
        >
          <ChooseCourseName
            v-model="courseNameInput"
            :forceCourseNameTool="forceCourseNameTool"
            :allowMultipleCourseConnections="allowMultipleCourseConnections"
            :dialog="courseNameDialog"
            :groupId="selectedGroupId"
            :titleDisplay="false"
            :existingCourseName="existingCourseName"
            :newCourse="newCourse"
            @isDisabledCreateButtonfn="isDisabledButton = $event"
          />
        </DialogBox>

        <!-- search for any group in the domain  -->
        <AutocompleteWhitelistedGroups
          :placeholderName="$t('name')"
          :placeholderEmail="$t('email')"
          @select="selectedGroupIds = [$event ? $event.id : null]"
          v-if="isAutoComplete"
        />

        <!-- search for only teacher's groups -->
        <div v-else>
          <div class="d-flex">
            <v-text-field
              v-model="search"
              hide-details
              density="compact"
              variant="outlined"
              :placeholder="$t('name') + ' ' + $t('or') + ' ' + $t('email')"
              prepend-inner-icon="mdi-magnify"
              clearable
              color="secondary"
              class="rounded-md mb-1"
              @update:modelValue="setGroupSearchName($event)"
              :disabled="loadingGroups"
            >
            </v-text-field>
            <v-spacer></v-spacer>

            <Sort
              :selectedSort="selectedSorting"
              :sortingOption="sortingOption"
              :disabledAction="loadingGroups"
              @selectedSorting="selectedSorting = $event"
            ></Sort>
          </div>

          <div :class="`divider bg-secondary`" style="height: 2px"></div>

          <v-progress-linear
            v-if="loadingGroups"
            indeterminate
            color="primary"
            height="2px"
          ></v-progress-linear>

          <div class="d-flex mt-1 mb-0" style="height: 30px">
            <v-spacer></v-spacer>
            <v-checkbox
              :disabled="loadingGroups"
              v-model="showDisabled"
              color="primary"
              class="d-flex align-center justify-end"
            >
              <template #label>
                <span class="text-dark-teal text-subtitle-1">{{
                  $t("show") + " " + $t("excluded_groups")
                }}</span>
              </template>
            </v-checkbox>
          </div>

          <div v-if="groups.length === 0 && !groupsNextPageToken">
            <span
              v-if="loadingGroups"
              v-t="'loading_please_wait'"
              class="d-flex justify-center text-gray"
            ></span>

            <div v-else>
              <span v-t="'no_data_found'" class="text-gray"></span>
              <v-tooltip location="right" v-if="!search">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="orange">mdi-information</v-icon>
                </template>
                <span v-t="'no_groups_found'"></span>
              </v-tooltip>
            </div>
          </div>

          <v-list v-model:selected="selectedGroupIds" color="primary" v-else>
            <v-virtual-scroll
              :bench="0"
              :items="groups"
              height="200"
              item-height="40"
            >
              <template v-slot:default="{ item }">
                <v-list-item
                  :key="item.groupId"
                  :value="item.groupId"
                  :disabled="
                    item.disabled || groupsConnInProgress.includes(item.groupId)
                  "
                >
                  <v-list-item-title class="fontcls"
                    >{{ item.groupName }}
                  </v-list-item-title>

                  <template v-slot:append>
                    <v-alert
                      v-if="
                        selectedGroupId == item.groupId &&
                        item.directMembersCount > 50
                      "
                      type="warning"
                      icon="mdi-alert"
                      density="compact"
                      class="ma-0 d-flex align-center"
                      variant="outlined"
                    >
                      <span v-t="'group_member_limit_warning'"></span>
                    </v-alert>

                    <v-alert
                      v-else-if="groupsConnInProgress.includes(item.groupId)"
                      type="warning"
                      icon="mdi-alert"
                      density="compact"
                      class="ma-0 d-flex align-center"
                      variant="outlined"
                    >
                      <span v-t="'group_conn_in_progress_warn'"></span>
                    </v-alert>
                  </template>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-list>

          <LoadMore
            class="d-flex mx-auto"
            v-if="groupsNextPageToken"
            :loading="loadingGroups"
            @next-page="
              $emit(
                'groups-next-page',
                search,
                sortingOption[selectedSorting].value,
                showDisabled,
              )
            "
            :titleFont="false"
          ></LoadMore>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          height="30px"
          elevation="0"
          variant="outlined"
          color="primary"
          class="rounded-md"
          @click="closeAttachCourseDialog()"
          ><span v-t="'cancel'"></span
        ></v-btn>

        <v-btn
          height="30px"
          color="primary"
          class="rounded-md"
          variant="flat"
          :disabled="
            !selectedGroupId ||
            !selectedGroupId ||
            selectedGroupId == '' ||
            loadingGroups
          "
          @click="courseNameDialog = true"
        >
          <span v-t="'choose'"></span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.text-subtitle-1 {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 400 !important;
}
</style>

<script setup>
import { ref, reactive, computed, watch } from "vue";
import Sort from "@/components/sort";
import DialogBox from "./dialog.vue";
import LoadMore from "@/components/loadMoreButton";
import ChooseCourseName from "@/components/chooseCourseName";
import AutocompleteWhitelistedGroups from "@/components/autocompleteWhitelistedGroups";

const props = defineProps({
  buttonOutlined: {
    type: Boolean,
    default: false,
  },

  buttonIcon: {
    type: String,
  },

  buttonName: {
    type: String,
  },

  height: {
    type: String,
    default: "30px",
  },

  color: {
    type: String,
    default: "primary",
  },

  title: {
    type: String,
    required: true,
  },

  groups: {
    type: Array,
  },

  groupsNextPageToken: {
    type: String,
    default: null,
  },

  loadingGroups: {
    type: Boolean,
    default: false,
  },

  courseId: {
    type: String,
    default: null,
  },

  existingCourseName: {
    type: String,
    default: null,
  },

  newCourse: {
    type: Boolean,
    default: false,
  },

  forceCourseNameTool: {
    type: Boolean,
    default: false,
  },

  allowMultipleCourseConnections: {
    type: Boolean,
    default: false,
  },

  isAutoComplete: {
    type: Boolean,
    default: false,
  },

  groupsConnInProgress: {
    type: Array,
    default: function () {
      return [];
    },
  },
});

const emit = defineEmits([
  "refresh",
  "attach-course-handler",
  "groups-next-page",
]);

const dialog = ref(false);
const search = ref(null);
const searchTimeOut = ref(0);
const sortingOption = reactive([
  { name: "A-Z", value: "ASC" },
  { name: "Z-A", value: "DESC" },
]);
const selectedSorting = ref(0);
const courseNameDialog = ref(false);
const courseNameInput = ref(null);
const isDisabledButton = ref(false);
const showDisabled = ref(false);

const selectedGroupIds = ref([]);
const selectedGroupId = computed(() => {
  return selectedGroupIds.value.length > 0 ? selectedGroupIds.value[0] : null;
});

const isSetCourseNameDialogDisabled = computed(() => {
  if (
    isDisabledButton.value ||
    selectedGroupId.value == null ||
    courseNameInput.value == null
  ) {
    return true;
  } else {
    return false;
  }
});

watch(dialog, (val) => {
  if (val) {
    if (!props.isAutoComplete) {
      emit(
        "refresh",
        search.value,
        sortingOption[selectedSorting.value].value,
        showDisabled.value,
      );
    }
  }
});

watch(selectedSorting, () => {
  selectedGroupIds.value = [];

  if (!props.isAutoComplete) {
    emit(
      "refresh",
      search.value,
      sortingOption[selectedSorting.value].value,
      showDisabled.value,
    );
  }
});

watch(showDisabled, () => {
  selectedGroupIds.value = [];

  if (!props.isAutoComplete) {
    emit(
      "refresh",
      search.value,
      sortingOption[selectedSorting.value].value,
      showDisabled.value,
    );
  }
});

const attachCourseAndCloseDialog = async () => {
  if (
    selectedGroupId.value &&
    courseNameInput.value &&
    courseNameInput.value.courseName
  ) {
    await emit(
      "attach-course-handler",
      props.courseId,
      selectedGroupId.value,
      courseNameInput.value.courseName,
      courseNameInput.value.nameFromAdminSetting,
    );

    courseNameDialog.value = false;
    selectedGroupIds.value = [];
    courseNameInput.value = null;
    dialog.value = false;
  }
};

const setGroupSearchName = async (val) => {
  clearTimeout(searchTimeOut.value);
  searchTimeOut.value = setTimeout(() => {
    selectedGroupIds.value = [];
    search.value = val;

    if (!props.isAutoComplete) {
      emit(
        "refresh",
        search.value,
        sortingOption[selectedSorting.value].value,
        showDisabled.value,
      );
    }
  }, 600);
};

const closeAttachCourseDialog = async () => {
  courseNameDialog.value = false;
  selectedGroupIds.value = [];
  courseNameInput.value = null;

  if (!props.isAutoComplete) {
    emit(
      "refresh",
      search.value,
      sortingOption[selectedSorting.value].value,
      showDisabled.value,
    );
  }
  dialog.value = false;
};

const closeSetCourseNameDialog = async () => {
  courseNameInput.value = null;
  courseNameDialog.value = false;
};
</script>
