<template>
  <v-container style="max-width: 80%">
    <div v-if="sessionActive && hasContacted && enabledScan">
      <OrphanedComp compTitle="orphanedCourse"></OrphanedComp>
    </div>

    <Freemium
      v-else-if="sessionActive && (!hasContacted || !enabledScan)"
      images="/orphaned.gif"
      :title="$t('orphaned_courses')"
      :bodyText="$t('orphaned_freemium_text')"
    ></Freemium>
  </v-container>
</template>

<script>
import Freemium from "@/components/freemium.vue";
import OrphanedComp from "@/components/orphanedComp.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Freemium,
    OrphanedComp,
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      sessionActive: "sessionActive",
      hasContacted: "hasContacted",
      enabledScan: "enabledScan",
    }),
  },
};
</script>
