<template>
  <v-container class="d-flex pb-0">
    <v-autocomplete
      :disabled="disabledAction"
      prepend-inner-icon="mdi-magnify"
      v-model="searchedGroupSelect"
      :loading="loadingSearchedGroup"
      :items="searchedGroupsList"
      v-model:search="searchGroupEmailFunc"
      item-title="email"
      variant="outlined"
      density="compact"
      :placeholder="placeholderEmail"
      hide-details
      :no-data-text="$t('no_data_available')"
      clearable
      class="autocompcls mr-4 rounded-md"
      color="secondary"
      return-object
      style="width: 50%"
    />

    <v-autocomplete
      :disabled="disabledAction"
      prepend-inner-icon="mdi-magnify"
      v-model="searchedGroupSelect"
      :loading="loadingSearchedGroup"
      :items="searchedGroupsList"
      v-model:search="searchGroupNameFunc"
      item-title="name"
      variant="outlined"
      density="compact"
      :placeholder="placeholderName"
      hide-details
      :no-data-text="$t('no_data_available')"
      clearable
      class="autocompcls rounded-md"
      color="secondary"
      return-object
      style="width: 50%"
    />
  </v-container>
</template>

<script>
import groupsAPI from "@/api/groups";
import { debounce } from "lodash";

export default {
  props: {
    disabledAction: {
      type: Boolean,
      default: false,
    },

    placeholderName: {
      type: String,
      default: "Name..",
    },

    placeholderEmail: {
      type: String,
      default: "Email..",
    },
  },

  emits: ["select"],

  data() {
    return {
      loadingSearchedGroup: false,
      searchedGroupsList: [],
      searchedGroupSelect: null,
      searchGroupNameFunc: null,
      searchGroupEmailFunc: null,
    };
  },

  watch: {
    searchGroupEmailFunc: debounce(async function (val) {
      if (this.loadingSearchedGroup) return;

      await this.searchGroupFunc(val, "email");
    }, 500),

    searchGroupNameFunc: debounce(async function (val) {
      if (this.loadingSearchedGroup) return;

      var selectedItemField = this.searchedGroupSelect
        ? this.searchedGroupSelect.name
        : null;

      if (val && val.name !== selectedItemField) {
        await this.searchGroupFunc(val, "name");
      }
    }, 500),

    searchedGroupSelect(val) {
      this.$emit("select", val);
    },
  },

  methods: {
    async searchGroupFunc(val, searchby) {
      if (val) {
        this.loadingSearchedGroup = true;

        try {
          const response = await groupsAPI.get("my_customer", val, searchby);
          this.searchedGroupsList = response.data;
        } catch (error) {
          console.log(error);
        } finally {
          this.loadingSearchedGroup = false;
        }
      }
    },
  },
};
</script>
