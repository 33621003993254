<template>
  <v-container class="pa-0">
    <v-autocomplete
      v-model="select"
      :loading="loading"
      :items="items"
      v-model:search="searchUserEmailFunc"
      item-title="email"
      :item-value="(item) => item"
      variant="outlined"
      prepend-inner-icon="mdi-magnify"
      density="compact"
      :placeholder="placeholderEmail"
      :no-data-text="$t('no_data_available')"
      hide-details
      class="autocompcls mb-4 rounded-md"
      color="secondary"
      clearable
    />

    <v-autocomplete
      v-model="select"
      :loading="loading"
      :items="items"
      v-model:search="searchUserNameFunc"
      item-title="name"
      :item-value="(item) => item"
      variant="outlined"
      class="autocompcls rounded-md"
      prepend-inner-icon="mdi-magnify"
      density="compact"
      :placeholder="placeholderName"
      :no-data-text="$t('no_data_available')"
      hide-details
      color="secondary"
      clearable
    />
  </v-container>
</template>

<script>
import teachersAPI from "@/api/teachers";
import { debounce } from "lodash";

export default {
  props: {
    placeholderEmail: {
      type: String,
      default: "..",
    },

    placeholderName: {
      type: String,
      default: "..",
    },
  },

  emits: ["select"],

  data() {
    return {
      loading: false,
      items: [],
      search: null,
      select: null,
      searchUserNameFunc: null,
      searchUserEmailFunc: null,
    };
  },

  watch: {
    searchUserEmailFunc: debounce(async function (val) {
      if (this.loading) return;

      if (val) {
        await this.querySelections(val);
      }
    }, 500),

    searchUserNameFunc: debounce(async function (val) {
      if (this.loading) return;

      if (val) {
        await this.querySelections(val);
      }
    }, 500),

    select(val) {
      this.$emit("select", val);
    },
  },

  methods: {
    querySelections(search) {
      this.loading = true;
      teachersAPI
        .get("my_customer", search)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
