<template>
  <div class="d-flex align-center">
    <div v-if="modelValue">
      <v-tooltip location="bottom">
        <template v-slot:activator="{ props }">
          <v-chip
            v-bind="props"
            variant="flat"
            @click="editTool()"
            color="active-teal"
          >
            <span>{{ getToolName(modelValue.type) }}</span>
            <v-icon size="small" class="ml-1" color="dark-teal"
              >mdi-pencil</v-icon
            >
          </v-chip>
        </template>
        <span>{{
          getToolDescriptionShort(
            modelValue.type,
            modelValue.primary,
            modelValue.secondary,
          )
        }}</span>
      </v-tooltip>
    </div>

    <div v-else class="d-flex align-center">
      <v-btn
        icon
        density="compact"
        variant="text"
        color="primary"
        @click.stop="newTool()"
      >
        <v-icon>mdi-wrench</v-icon>
      </v-btn>
    </div>

    <v-dialog persistent v-model="showDialog" max-width="500">
      <v-card>
        <v-card-title class="text-dark-teal d-flex bg-active-teal">
          <div class="pr-4 d-flex align-center">
            <v-icon size="large" color="dark-teal">mdi-wrench</v-icon>
          </div>

          <div class="d-flex flex-column">
            <div class="text-h6"><span v-t="'tool'"></span></div>
            <div class="text-subtitle-1">{{ fieldName }}</div>
          </div>

          <v-spacer></v-spacer>

          <v-btn
            v-if="modelValue"
            icon
            variant="text"
            v-on:click="deleteTool()"
          >
            <v-icon color="dark-teal">mdi-delete</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text style="font-size: 16px">
          <div class="text-dark-gray">
            <span v-t="'tool_message_1'"></span>
            <span v-t="'tool_message_4'"></span>
          </div>

          <v-select
            @update:modelValue="onToolChange()"
            :items="toolOptions().filter((d) => d.value !== 'Lowercase')"
            v-model="type"
            :label="$t('tool')"
            color="primary"
            variant="underlined"
            class="mt-2"
          />

          <div v-if="type == 'Slice'">
            <v-text-field
              v-model="primary"
              :label="$t('starting_at')"
              type="number"
              color="primary"
              variant="underlined"
            />
            <v-text-field
              v-model="secondary"
              :label="$t('ends_at')"
              type="number"
              color="primary"
              variant="underlined"
            />
          </div>

          <div v-else-if="type == 'Split'">
            <v-text-field
              v-model="primary"
              :label="$t('divider')"
              variant="underlined"
            />
            <v-select
              :items="toolSplitOptions()"
              v-model="secondary"
              :label="$t('keep_only')"
              color="primary"
              variant="underlined"
            />
          </div>

          <div v-else-if="type == 'Replace'">
            <v-text-field
              v-model="primary"
              :label="this.$t('search_content')"
              variant="underlined"
            />
            <v-text-field
              v-model="secondary"
              :label="this.$t('replace_with')"
              variant="underlined"
            />
          </div>

          <div v-else-if="type == 'Regex'" class="d-flex">
            <v-text-field
              v-model="primary"
              :label="this.$t('regex_pattern')"
              variant="underlined"
            />

            <div class="d-flex align-center">
              <v-btn
                variant="flat"
                icon
                @click.stop="
                  showRegexDialog = true;
                  regexReset();
                "
                ><v-icon color="primary">mdi-tune</v-icon></v-btn
              >
            </div>
          </div>

          <v-alert
            v-if="type.length"
            :value="true"
            variant="outlined"
            color="primary"
            class="my-0"
          >
            <v-icon color="orange" class="mr-1">mdi-information</v-icon>
            <span v-if="type == 'Slice'">
              <span v-t="'slice_info'"></span>
              <div>
                {{ this.$t("example") }}: text = "abcdefg",
                {{ $t("starting_at") }} = 1, {{ $t("ends_at") }} = -1,
                {{ this.$t("result") }} = "bcdef"
              </div>
            </span>

            <span v-else-if="type == 'Split'">
              <span v-t="'split_info'"></span>
              <div>
                {{ this.$t("example") }}: text = "a - b - c" ,
                {{ this.$t("divider") }} = '-', {{ $t("keep_only") }} = last ,
                {{ this.$t("result") }} = "c"
              </div>
            </span>

            <span v-else-if="type == 'Regex'">
              <span v-t="'regex_validate_info_1'" class="mr-1"></span>
              <span v-t="'with'" class="mr-1"></span>Regex<span
                class="ml-1"
                v-t="'calculator'"
              ></span>
              (<v-icon size="small">mdi-tune</v-icon>)
            </span>

            <span v-else-if="type == 'Replace'" v-t="'replace_info'"> </span>
          </v-alert>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-spacer />

          <v-btn
            height="30px"
            elevation="0"
            variant="outlined"
            color="primary"
            class="rounded-md"
            @click.stop="showDialog = false"
            ><span v-t="'cancel'"></span
          ></v-btn>

          <v-btn
            height="30px"
            elevation="0"
            color="primary"
            class="rounded-md"
            variant="flat"
            v-on:click="addTool()"
            :disabled="disableSave"
            ><span v-t="'save'"></span
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="showRegexDialog" max-width="500">
      <v-card>
        <v-card-title class="text-dark-teal bg-active-teal">
          <span class="mr-1">RegEx</span
          ><span class="ml-1" v-t="'calculator'"></span
        ></v-card-title>

        <v-card-text style="font-size: 16px">
          <div class="text-dark-gray mb-3">
            <div style="text-align: justify">
              <span v-t="'regex_validate_info_1'"></span>(<v-icon size="x-small"
                >mdi-refresh</v-icon
              >)
            </div>
            <span
              v-t="'regex_validate_info_2'"
              style="text-align: justify"
            ></span>
          </div>

          <div class="d-flex align-center">
            <v-text-field
              v-model="regex_input"
              label="Test-text"
              variant="underlined"
            />
            <v-icon size="large">mdi-arrow-right</v-icon>
            <v-text-field
              v-model="regexSample"
              readonly
              single-line
              variant="underlined"
            />

            <div class="d-flex align-center">
              <TooltipButton
                iconName="mdi-refresh"
                :tooltipMsg="$t('regex_refresh_tooltip')"
                :disabledAction="!regex_input || !primary"
                @action-handler="validateRegExAction(getRegexBody())"
                :loading="regexLoading"
              />
            </div>
          </div>

          <v-text-field
            v-model="primary"
            :label="$t('regex_pattern')"
            variant="underlined"
          />
          <v-checkbox
            class="small-check"
            color="primary"
            v-model="regex_m"
            hide-details
            :label="$t('multi_level_support')"
          />
          <v-checkbox
            class="small-check"
            color="primary"
            v-model="regex_s"
            hide-details
            :label="$t('s_regex_secondary_input')"
          />
          <v-checkbox
            class="small-check"
            color="primary"
            v-model="regex_x"
            hide-details
            :label="$t('x_regex_seconday_input')"
          />
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-spacer />
          <v-btn
            height="30px"
            elevation="0"
            variant="outlined"
            color="primary"
            class="rounded-md"
            @click.stop="showRegexDialog = false"
            ><span v-t="'cancel'"></span
          ></v-btn>
          <v-btn
            height="30px"
            elevation="0"
            variant="flat"
            color="primary"
            class="rounded-md"
            @click.stop="showRegexDialog = false"
            ><span v-t="'save'"></span
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.text-h6,
.text-subtitle-1 {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 400 !important;
}

.v-input.small-check label {
  color: #464646;
  font-size: 16px !important;
}
</style>
<script>
import settingsAPI from "@/api/customerSettings";
import TooltipButton from "@/components/tooltipButton";

export default {
  name: "FilterButtonTool",

  components: {
    TooltipButton,
  },

  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    fieldName: {
      type: String,
      default: "",
    },
    isCondition: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      showDialog: false,
      showRegexDialog: false,
      type: "",
      primary: "",
      secondary: "",
      regex_input: "",
      regex_output: "",
      regex_m: false,
      regex_s: false,
      regex_x: false,

      regexSample: "",
      regexLoading: false,
      tools: ["Lowercase", "Regex", "Replace", "Slice", "Split"],
      toolValuesSplit: ["first", "second", "third", "fourth", "fifth", "last"],
    };
  },
  computed: {
    disableSave: function () {
      if (this.type.length === 0) {
        return true;
      }
      if (this.type === "Slice") {
        return (
          (!this.primary || parseInt(this.primary) === 0) &&
          (!this.secondary || parseInt(this.secondary) === 0)
        );
      }
      if (this.type === "Split") {
        if (this.primary.length && this.secondary) {
          return false;
        } else {
          return true;
        }
      }
      if (this.type === "Regex") {
        if (this.primary.length) {
          return false;
        } else {
          return true;
        }
      }
      if (this.type === "Replace") {
        if (this.primary.length) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
  },

  methods: {
    addTool() {
      let emitValue = null;
      if (["Slice", "Split", "Replace"].includes(this.type)) {
        emitValue = {
          type: this.type,
          primary: this.primary,
          secondary: this.secondary,
        };
      } else if (this.type === "Regex") {
        let flags = "";
        if (this.regex_m) {
          flags += "M";
        }
        if (this.regex_s) {
          flags += "S";
        }
        if (this.regex_x) {
          flags += "X";
        }
        emitValue = {
          type: this.type,
          primary: this.primary,
          secondary: flags,
        };
      }
      this.$emit("update:modelValue", emitValue);
      this.clear();
    },

    clear() {
      this.type = "";
      this.showDialog = false;
    },

    deleteTool() {
      this.$emit("update:modelValue", null);
      this.clear();
    },

    editTool() {
      this.type = this.modelValue.type;
      this.primary = this.modelValue.primary;
      this.secondary = this.modelValue.secondary;
      if (this.type === "Regex") {
        this.regex_m = this.secondary.indexOf("M") !== -1;
        this.regex_s = this.secondary.indexOf("S") !== -1;
        this.regex_x = this.secondary.indexOf("X") !== -1;
      }
      this.showDialog = true;
    },

    newTool() {
      this.clear();
      this.showDialog = true;
    },

    onToolChange() {
      this.primary = "";
      this.secondary = "";
      this.regex_m = false;
      this.regex_s = false;
      this.regex_x = false;
    },

    getRegexBody() {
      return {
        primary: this.primary,
        secondary:
          (this.regex_m ? "M" : "") +
          (this.regex_s ? "S" : "") +
          (this.regex_x ? "X" : ""),
        sample: this.regex_input,
      };
    },

    getToolName(toolId) {
      return (
        {
          Lowercase: this.$t("common"),
          Regex: "RegEx",
          Replace: this.$t("search_replace"),
          Slice: this.$t("prune"),
          Split: this.$t("split"),
        }[toolId] || this.$t("error_occured")
      );
    },

    toolOptions() {
      let options = [];
      this.tools.forEach((v) =>
        options.push({ value: v, title: this.getToolName(v) }),
      );
      return options;
    },

    toolSplitOptions() {
      let options = [];
      this.toolValuesSplit.forEach((v) =>
        options.push({ value: v, title: this.getToolSplitName(v) }),
      );
      return options;
    },

    getToolDescriptionShort(toolId, primary, secondary) {
      if (toolId === "Lowercase") {
        return this.$t("lowercase_rule_for_field");
      } else if (toolId === "Regex") {
        if (secondary) {
          return (
            this.$t("regex_pattern") +
            ": " +
            primary +
            " " +
            this.$t("applied_with_flags") +
            ": " +
            secondary
          );
        } else {
          return (
            this.$t("regex_pattern") + ": " + primary + " " + this.$t("applied")
          );
        }
      } else if (toolId === "Replace") {
        return (
          this.$t("replaces_each_text") +
          " " +
          primary +
          " " +
          this.$t("with") +
          " " +
          '"' +
          secondary +
          '"'
        );
      } else if (toolId === "Slice") {
        if (primary && secondary) {
          return (
            this.$t("slice_tooltip") +
            " " +
            primary +
            " " +
            this.$t("and") +
            " " +
            secondary +
            " " +
            this.$t("used")
          );
        } else if (primary) {
          return (
            this.$t("slice_tool_tip_p_1") +
            " " +
            primary +
            " " +
            this.$t("slice_tool_tip_p_2")
          );
        } else if (secondary) {
          return (
            this.$t("slice_tool_tip_s_1") +
            " " +
            secondary +
            " " +
            this.$t("used")
          );
        } else {
          return this.$t("error_occured");
        }
      } else if (toolId === "Split") {
        return (
          this.$t("split_tooltip_1") +
          " " +
          primary +
          " " +
          this.$t("whereupon") +
          " " +
          this.getToolSplitName(secondary).toLowerCase() +
          " " +
          this.$t("split_tooltip_3")
        );
      } else {
        return this.$t("error_occured");
      }
    },

    async validateRegExAction(postBody) {
      try {
        this.regexLoading = true;
        let resp = await settingsAPI.validateRegEx("my_customer", postBody);

        this.regexSample = resp.validation;
      } catch (error) {
        console.log(error);
      } finally {
        this.regexLoading = false;
      }
    },

    regexReset() {
      this.regexLoading = false;
      this.regexSample = "";
    },

    getToolSplitName(toolValueId) {
      return (
        {
          first: this.$t("first"),
          second: this.$t("second"),
          third: this.$t("third"),
          fourth: this.$t("fourth"),
          fifth: this.$t("fifth"),
          last: this.$t("last"),
        }[toolValueId] || ""
      );
    },
  },
};
</script>
