<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="5000"
    location="bottom left"
    :color="color"
  >
    {{ text }}

    <template v-slot:actions>
      <v-btn color="blue" variant="text" @click="snackbar = false">
        <span v-t="'close'"></span>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { ref, watch } from "vue";

defineProps({
  color: {
    type: String,
    default: "black",
  },
  text: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["on-dismiss"]);

const snackbar = ref(true);

watch(snackbar, (current) => {
  if (current == false) {
    emit("on-dismiss", false);
  }
});
</script>
