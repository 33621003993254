<template>
  <v-container style="height: 100%; max-width: 80%">
    <Error
      :pageError="pageError"
      v-if="pageError"
      @on-dismiss="pageError = null"
    ></Error>

    <TitleTile color="primary" textColor="white">
      <span>{{ courseName }}</span>

      <!-- Display alert if functionaity is limited for orphaned course -->
      <TooltipButton
        v-if="courseName && isFunctionalityExpired"
        iColor="yellow"
        iconName="mdi-alert"
        :tooltipMsg="
          $t('manage_orphaned_course_teachers_warning') +
          ' ' +
          $t('limited_functionality_warning')
        "
      />
    </TitleTile>

    <Headline :title="$t('student', 2)" color="dark-gray">
      <div class="d-flex mt-1">
        <TooltipButton
          :disabledAction="loadingAddStudent"
          :loading="loadingCourseStudents || loadingStudentInvitations"
          @action-handler="
            getAllCourseStudents();
            getAllCourseStudentInvitations();
          "
          iconName="mdi-refresh"
          :tooltipMsg="$t('refresh')"
          iconSize="26"
        />
      </div>
      <v-spacer />

      <Search
        @set-search-by-name="setSearchByName($event)"
        @set-search-by-email="setSearchByEmail($event)"
        :disabledAction="loadingCourseStudents"
      />

      <DialogButton
        color="primary"
        icon="mdi-account-plus-outline"
        :title="$t('add') + ' ' + $t('student', 1)"
        :actionName="$t('add')"
        :fab="true"
        height="40"
        :disabledAction="!newStudentEmail"
        v-if="!isFunctionalityExpired"
        @action-handler="addStudentHandler(newStudentEmail)"
        @close-dialog="newStudentEmail = ''"
        :tooltipMsg="$t('add') + ' ' + $t('student', 1)"
      >
        <AutocompleteStudents
          class="pl-0 pb-0 pt-0"
          :placeholderName="$t('name')"
          :placeholderEmail="$t('email')"
          @select="newStudentEmail = $event ? $event.primaryEmail : null"
        />
      </DialogButton>
      <DialogButton
        color="primary"
        icon="mdi-account-multiple-plus-outline"
        :title="$t('add') + ' ' + $t('group')"
        :actionName="$t('add')"
        :fab="true"
        height="44"
        :disabledAction="!newGroupEmail"
        v-if="!isFunctionalityExpired"
        @action-handler="addGroupOfStudentsHandler(newGroupEmail)"
        @close-dialog="newGroupEmail = ''"
        :tooltipMsg="$t('add') + ' ' + $t('group')"
      >
        <AutocompleteDomainGroups
          class="pl-0 pb-0 pt-0"
          :placeholderName="$t('name')"
          :placeholderEmail="$t('email')"
          @select="newGroupEmail = $event ? $event.email : null"
        />
      </DialogButton>

      <Sort
        :selectedSort="selectedSorting"
        :sortingOption="sortingOption"
        :disabledAction="loadingCourseStudents"
        iconCompHeight="25px"
        @selectedSorting="selectedSorting = $event"
      ></Sort>
    </Headline>

    <v-progress-linear
      v-if="loadingCourseStudents"
      indeterminate
      color="primary"
      height="2px"
    ></v-progress-linear>

    <ItemsList
      :filteredItemsList="filteredStudents"
      itemTitleCls="fontcls mr-2"
    >
      <template #listaction="itemProps">
        <v-progress-circular
          v-if="itemProps.item.taskStatus"
          indeterminate
          size="30"
          color="primary"
        />

        <v-btn
          v-else
          elevation="0"
          color="primary"
          height="25px"
          class="rounded-md"
          size="small"
          @click="removeStudentHandler(itemProps.item.id, itemProps.item.email)"
          :disabled="loadingCourseStudents || loadingAddStudent"
        >
          <v-icon size="20" class="mr-1">mdi-account-remove</v-icon>
          <span v-t="'remove'"></span>
        </v-btn>
      </template>
    </ItemsList>

    <NoDataText
      :itemLoading="loadingCourseStudents"
      :nextPageToken="nextPageToken"
      :items="filteredStudents"
    ></NoDataText>

    <div class="d-flex justify-center">
      <LoadMore
        v-if="nextPageToken"
        :loading="loadingCourseStudents"
        @next-page="getCourseStudentsPage"
      ></LoadMore>
    </div>

    <!-- invited students section  -->

    <Headline
      class="mt-10"
      :title="$t('invited') + ' ' + $t('student', 2)"
      color="dark-gray"
    >
      <v-spacer />

      <Search
        @set-search-by-name="setInvitationSearchByName($event)"
        @set-search-by-email="setInvitationSearchByEmail($event)"
        :disabledAction="loadingStudentInvitations"
      />

      <DialogButton
        color="primary"
        icon="mdi-account-plus-outline"
        :title="$t('create') + ' ' + $t('invitation')"
        :actionName="$t('add')"
        :fab="true"
        height="40"
        :disabledAction="!newStudentInvitationEmail"
        v-if="!isFunctionalityExpired"
        @action-handler="addStudentInvitationHandler()"
        @close-dialog="newStudentInvitationEmail = null"
        :tooltipMsg="$t('create') + ' ' + $t('invitation')"
      >
        <AutocompleteStudents
          class="pl-0 pb-0 pt-0"
          :placeholderName="$t('name')"
          :placeholderEmail="$t('email')"
          @select="newStudentInvitationEmail = $event"
        />
      </DialogButton>

      <Sort
        :selectedSort="selectedInvitationSorting"
        :sortingOption="sortingOption"
        :disabledAction="loadingCourseStudents || loadingStudentInvitations"
        iconCompHeight="25px"
        @selectedSorting="selectedInvitationSorting = $event"
      ></Sort>
    </Headline>

    <v-progress-linear
      v-if="loadingStudentInvitations"
      indeterminate
      color="primary"
      height="2px"
    ></v-progress-linear>

    <ItemsList
      :filteredItemsList="filteredStudentInvitations"
      itemTitleCls="fontcls mr-2"
    >
      <template #listaction="itemProps">
        <v-progress-circular
          v-if="itemProps.item.taskStatus"
          indeterminate
          size="30"
          color="primary"
        />

        <v-btn
          v-else
          variant="flat"
          color="primary"
          height="25px"
          class="rounded-md"
          size="small"
          @click="
            removeStudentInvitationHandler(
              itemProps.item.id,
              itemProps.item.userEmail,
              itemProps.item.role,
            )
          "
          :disabled="
            loadingCourseStudents ||
            loadingStudentInvitations ||
            loadingAddStudent
          "
        >
          <v-icon size="20" class="mr-1">mdi-account-remove</v-icon>
          <span v-t="'remove'"></span>
        </v-btn>
      </template>
    </ItemsList>

    <NoDataText
      :itemLoading="loadingStudentInvitations"
      :nextPageToken="nextInvitationPageToken"
      :items="filteredStudentInvitations"
    ></NoDataText>

    <div class="d-flex justify-center">
      <LoadMore
        v-if="nextInvitationPageToken"
        :loading="loadingStudentInvitations"
        @next-page="getCourseInvitedStudentsPage"
      ></LoadMore>
    </div>

    <v-row class="align-self-end">
      <v-col class="d-flex justify-end">
        <v-btn
          elevation="0"
          color="primary"
          style="background-color: white"
          height="25px"
          class="rounded-md"
          variant="outlined"
          @click="$router.go(-1)"
          ><span v-t="'back'"></span
        ></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import useGetTaskStatus from "@/composables/getTaskStatus";
import coursesAPI from "@/api/courses";
import orphanedCoursesAPI from "@/api/orphanedCourses";

import Error from "@/components/error.vue";
import Search from "@/components/search";
import Headline from "@/components/headline";
import TitleTile from "@/components/titleTile";
import DialogButton from "@/components/dialogButton";
import Sort from "@/components/sort";
import AutocompleteStudents from "@/components/autocompleteStudents";
import AutocompleteDomainGroups from "@/components/autocompleteDomainGroups";
import LoadMore from "@/components/loadMoreButton";
import NoDataText from "@/components/noDataText.vue";
import TooltipButton from "@/components/tooltipButton";
import ItemsList from "@/components/itemsList.vue";

export default {
  setup() {
    const { getTaskStatus } = useGetTaskStatus();
    return { getTaskStatus };
  },

  middleware: "authenticated-access",
  components: {
    Search,
    Headline,
    TitleTile,
    DialogButton,
    Sort,
    AutocompleteStudents,
    AutocompleteDomainGroups,
    Error,
    LoadMore,
    NoDataText,
    TooltipButton,
    ItemsList,
  },
  data() {
    return {
      newStudentEmail: "",
      newGroupEmail: "",
      selectedSorting: 0,
      sortingOption: ["A-Z", "Z-A"],
      search: "",
      searchby: "name",

      courseName: null,
      courseId: null,
      students: [],
      pageError: null,

      nextPageToken: null,

      loadingCourseStudents: false,
      loadingAddStudent: false,

      loadingStudentInvitations: false,
      invitationSearch: "",
      invitationSearchBy: "name",
      newStudentInvitationEmail: null,
      selectedInvitationSorting: 0,
      nextInvitationPageToken: null,
      invitedStudents: [],
      isFunctionalityExpired: false,
    };
  },

  computed: {
    ...mapGetters({
      sessionActive: "sessionActive",
    }),

    filteredStudents() {
      var tempStudents = [];

      if (this.search) {
        if (this.searchby == "name") {
          tempStudents = _.filter(this.students, (item) => {
            if (item.name) {
              return item.name
                .toLowerCase()
                .includes(this.search.toLowerCase());
            } else {
              return false;
            }
          });
        } else {
          tempStudents = _.filter(this.students, (item) =>
            item.email.toLowerCase().includes(this.search.toLowerCase()),
          );
        }
      } else {
        tempStudents = this.students;
      }

      if (this.sortingOption[this.selectedSorting] === "A-Z") {
        tempStudents = _.orderBy(tempStudents, "email");
      } else if (this.sortingOption[this.selectedSorting] === "Z-A") {
        tempStudents = _.orderBy(tempStudents, "email", "desc");
      }

      return tempStudents;
    },

    filteredStudentInvitations() {
      var tempInvitations = [];

      if (this.invitationSearch) {
        if (this.invitationSearchBy == "name") {
          tempInvitations = _.filter(this.invitedStudents, (item) => {
            if (item.userName) {
              return item.userName
                .toLowerCase()
                .includes(this.invitationSearch.toLowerCase());
            } else {
              return false;
            }
          });
        } else {
          tempInvitations = _.filter(this.invitedStudents, (item) =>
            item.userEmail
              .toLowerCase()
              .includes(this.invitationSearch.toLowerCase()),
          );
        }
      } else {
        tempInvitations = this.invitedStudents;
      }

      if (this.sortingOption[this.selectedInvitationSorting] === "A-Z") {
        tempInvitations = _.orderBy(tempInvitations, "userEmail");
      } else if (this.sortingOption[this.selectedInvitationSorting] === "Z-A") {
        tempInvitations = _.orderBy(tempInvitations, "userEmail", "desc");
      }

      return tempInvitations;
    },
  },

  watch: {
    async sessionActive(current) {
      if (current) {
        this.courseId = this.$route.params.courseId;
        this.courseName = this.$route.params.courseName;
        await this.getOrphanedCourseOwnerDeletionTime();
        await this.getAllCourseStudents();
        await this.getAllCourseStudentInvitations();
      }
    },

    pageError(val) {
      if (val) {
        window.scrollTo(0, 0);
      }
    },
  },

  methods: {
    ...mapActions({
      handleUnauthorisedError: "handleUnauthorisedError",
      signOut: "signOut",
    }),

    async getOrphanedCourseOwnerDeletionTime() {
      try {
        const response = await orphanedCoursesAPI.getCourse(
          "my_customer",
          this.courseId,
        );
        if (response.data) {
          if (response.data.owner_id != "105250506097979753968") {
            this.isFunctionalityExpired = false;
          } else {
            this.isFunctionalityExpired = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getAllCourseStudents() {
      this.loadingCourseStudents = true;
      try {
        const response = await coursesAPI.getCourseStudents(
          "my_customer",
          this.courseId,
        );

        this.students = response.data.students;
        this.nextPageToken = response.data.nextPageToken;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingCourseStudents = false;
      }
    },

    async getCourseStudentsPage() {
      this.loadingCourseStudents = true;

      try {
        const response = await coursesAPI.getCourseStudents(
          "my_customer",
          this.courseId,
          this.nextPageToken,
        );
        this.pageError = null;
        this.students = this.students.concat(response.data.students);
        this.nextPageToken = response.data.nextPageToken;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingCourseStudents = false;
      }
    },

    setSearchByName: function (val) {
      this.searchby = "name";
      this.search = val;
    },

    setSearchByEmail: function (val) {
      this.searchby = "email";
      this.search = val;
    },

    async getAllCourseStudentInvitations() {
      this.loadingStudentInvitations = true;
      try {
        const response = await coursesAPI.getCourseInvitations(
          "my_customer",
          this.courseId,
          ["STUDENT"],
        );
        this.invitedStudents = response.data.invitations;
        this.nextInvitationPageToken = response.data.nextPageToken;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingStudentInvitations = false;
      }
    },

    async getCourseInvitedStudentsPage() {
      this.loadingStudentInvitations = true;

      try {
        const response = await coursesAPI.getCourseInvitations(
          "my_customer",
          this.courseId,
          ["STUDENT"],
          this.nextInvitationPageToken,
        );
        this.pageError = null;
        this.invitedStudents = this.invitedStudents.concat(
          response.data.invitations,
        );
        this.nextInvitationPageToken = response.data.nextPageToken;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingStudentInvitations = false;
      }
    },

    setInvitationSearchByName: function (val) {
      this.invitationSearchBy = "name";
      this.invitationSearch = val;
    },

    setInvitationSearchByEmail: function (val) {
      this.invitationSearchBy = "email";
      this.invitationSearch = val;
    },

    async removeStudentInvitationHandler(invitationId, userEmail, role) {
      this.loadingStudentInvitations = true;
      try {
        await coursesAPI.deleteCourseInvitation(
          "my_customer",
          this.courseId,
          invitationId,
          userEmail,
          role,
        );

        this.invitedStudents = this.invitedStudents.filter(
          (item) => item.id !== invitationId,
        );
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingStudentInvitations = false;
      }
    },

    async addStudentInvitationHandler() {
      if (
        this.newStudentInvitationEmail &&
        this.newStudentInvitationEmail.primaryEmail
      ) {
        this.loadingStudentInvitations = true;
        try {
          const response = await coursesAPI.createCourseInvitation(
            "my_customer",
            this.courseId,
            this.newStudentInvitationEmail.id,
            this.newStudentInvitationEmail.primaryEmail,
            this.newStudentInvitationEmail.fullName,
            "STUDENT",
          );
          if (response.data) {
            this.invitedStudents.push(response.data);
          }
        } catch (error) {
          this.setPageError(error);
        } finally {
          this.newStudentInvitationEmail = null;
          this.loadingStudentInvitations = false;
        }
      }
    },

    async removeStudentHandler(studentId, email) {
      if (email) {
        this.loadingCourseStudents = true;
        const student = this.students.find((item) => item.id === studentId);
        student.taskStatus = "pending";

        try {
          await coursesAPI.deleteCourseStudent(
            "my_customer",
            this.courseId,
            studentId,
            email,
          );
          this.pageError = null;
          this.students = this.students.filter((item) => item.id !== studentId);
        } catch (error) {
          this.setPageError(error);
        } finally {
          student.taskStatus = null;
          this.loadingCourseStudents = false;
          this.getAllCourseStudents();
          this.getAllCourseStudentInvitations();
        }
      }
    },

    async addStudentHandler(email) {
      if (email && !this.students.find((item) => item.email === email)) {
        try {
          this.pageError = null;
          this.loadingAddStudent = true;
          const student = { email: email, taskStatus: "pending" };
          this.students.push(student);
          await coursesAPI.addCourseStudent(
            "my_customer",
            this.courseId,
            email,
          );
          await this.getAllCourseStudents();
          this.getAllCourseStudentInvitations();
        } catch (error) {
          this.students = this.students.filter((item) => item.email !== email);
          this.setPageError(error);
        } finally {
          this.newStudentEmail = "";
          this.loadingAddStudent = false;
        }
      }
      this.newStudentEmail = "";
    },

    addGroupOfStudentsHandler: function (email) {
      if (email && !this.students.find((item) => item.email === email)) {
        this.loadingAddStudent = true;
        const student = { email: email, taskStatus: "pending" };
        this.students.push(student);

        coursesAPI
          .addGroupOfMembersToCourse(
            "my_customer",
            this.courseId,
            email,
            "students",
          )
          .then((response) => {
            this.pageError = null;
            this.getTaskStatus(response.data.taskStatusId, (success) => {
              if (success) {
                this.getAllCourseStudents();
                this.getAllCourseStudentInvitations();
                this.loadingAddStudent = false;
              } else {
                this.students = this.students.filter(
                  (item) => item.email !== email,
                );
                this.loadingAddStudent = false;
                this.pageError = this.$t("action_failed_error");
                this.getAllCourseStudents();
                this.getAllCourseStudentInvitations();
              }
            });
          })
          .catch((error) => {
            this.setPageError(error);
            this.students = this.students.filter(
              (item) => item.email !== email,
            );
            this.loadingAddStudent = false;
          });
      }
      this.newStudentEmail = "";
    },

    setPageError(error) {
      if (error.response) {
        if (error.response.data.detail) {
          var detail = error.response.data.detail;
          if (
            detail === "unauthorized_client" ||
            detail == "unknown_customer"
          ) {
            this.pageError =
              "Error. You are not authorized to use this application. Application is not installed in gsuite marketplace for this customer. If the app is already installed, please make sure to grant access to the scopes. For more details follow the following steps- \n 1. From the Admin console Home page, go to Apps and then G Suite Marketplace apps. \n 2. Click the app's name-Online Partner Drive Admin Tool \n 3. Grant Access";
            this.handleUnauthorisedError();
          } else if (
            detail === "transport_error" ||
            detail == "invalid_token" ||
            detail == "session_expired"
          ) {
            this.signOut();
          } else {
            this.pageError = detail;
          }
          return;
        }
      }
      this.pageError = "action_failed_error";
    },
  },

  async created() {
    if (this.sessionActive) {
      this.courseId = this.$route.params.courseId;
      this.courseName = this.$route.params.courseName;

      await this.getOrphanedCourseOwnerDeletionTime();

      await this.getAllCourseStudents();
      await this.getAllCourseStudentInvitations();
    }
  },
};
</script>

<style>
.v-input__append-inner {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.scrollist {
  overflow-y: auto;
  max-height: 250px;
  scroll-behavior: smooth;
}
</style>
