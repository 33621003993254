import { createApp } from "vue";
import App from "./views/default.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import http from "./plugins/api";
import i18n from "./i18n";
import Vue3ConfirmDialog from "vue3-confirm-dialog";
import "vue3-confirm-dialog/style";

import { requestInterceptor } from "./plugins/api";

const app = createApp(App);

store.dispatch("google/init");

app.use(i18n);
app.use(vuetify);
app.use(Vue3ConfirmDialog);
app.use(store);
app.use(router);
app.component("vue3-confirm-dialog", Vue3ConfirmDialog.default);

app.mount("#app");

http.interceptors.request.use(requestInterceptor);
