import api from "../plugins/api";

export const getSettings = async (customerId) => {
  const data = await api.get(`/customers/${customerId}/settings/`);
  return data;
};

// Exclude Groups

export const addExcludedGroup = async (customerId, email, name, id) => {
  const data = await api.post(
    `/customers/${customerId}/settings/excludedgroups`,
    {
      email: email,
      name: name,
      id: id,
    },
  );
  return data;
};

export const removeExcludedGroup = async (customerId, id) => {
  const data = await api.delete(
    `/customers/${customerId}/settings/excludedgroups/${id}`,
  );
  return data;
};

export const fetchExcludedGroups = async (customerId) => {
  const data = await api.get(
    `/customers/${customerId}/settings/excludedgroups`,
  );
  return data;
};

// Exclude Conditions

export const addExcludeCondition = async (
  customerId,
  filterField,
  operator,
  textField,
  tool,
) => {
  const data = await api.post(
    `/customers/${customerId}/settings/excludeconditions`,
    {
      filter_field: filterField,
      operator: operator,
      textField: textField,
      tool: tool,
    },
  );
  return data;
};

export const updateExcludeCondition = async (
  customerId,
  id,
  filterField,
  operator,
  textField,
  tool,
) => {
  const data = await api.post(
    `/customers/${customerId}/settings/excludeconditions/${id}`,
    {
      id: id,
      filter_field: filterField,
      operator: operator,
      textField: textField,
      tool: tool,
    },
  );
  return data;
};

export const removeExcludeCondition = async (customerId, id) => {
  const data = await api.delete(
    `/customers/${customerId}/settings/excludeconditions/${id}`,
  );
  return data;
};

export const fetchExcludeConditions = async (customerId) => {
  const data = await api.get(
    `/customers/${customerId}/settings/excludeconditions`,
  );
  return data;
};

// whitelist Groups

export const addWhitelistedGroup = async (customerId, email, name, id) => {
  const data = await api.post(
    `/customers/${customerId}/settings/whitelistedgroups`,
    {
      email: email,
      name: name,
      id: id,
    },
  );
  return data;
};

export const removeWhitelistedGroup = async (customerId, id) => {
  const data = await api.delete(
    `/customers/${customerId}/settings/whitelistedgroups/${id}`,
  );
  return data;
};

export const fetchWhitelistedGroups = async (customerId) => {
  const data = await api.get(
    `/customers/${customerId}/settings/whitelistedgroups`,
  );
  return data;
};

// Whitelist Conditions

export const addWhitelistCondition = async (
  customerId,
  filterField,
  operator,
  textField,
  tool,
) => {
  const data = await api.post(
    `/customers/${customerId}/settings/whitelistconditions`,
    {
      filter_field: filterField,
      operator: operator,
      textField: textField,
      tool: tool,
    },
  );
  return data;
};

export const updateWhitelistCondition = async (
  customerId,
  id,
  filterField,
  operator,
  textField,
  tool,
) => {
  const data = await api.post(
    `/customers/${customerId}/settings/whitelistconditions/${id}`,
    {
      id: id,
      filter_field: filterField,
      operator: operator,
      textField: textField,
      tool: tool,
    },
  );
  return data;
};

export const removeWhitelistCondition = async (customerId, id) => {
  const data = await api.delete(
    `/customers/${customerId}/settings/whitelistconditions/${id}`,
  );
  return data;
};

export const fetchWhitelistConditions = async (customerId) => {
  const data = await api.get(
    `/customers/${customerId}/settings/whitelistconditions`,
  );
  return data;
};

export const updateSettings = async (params) => {
  const data = await api.post(`/customers/${params.customerId}/settings/`, {
    support_contact: params.contactEmailSelect,
    has_all_groups_whitelisted: params.hasAllWhitelisted,
    force_course_name_tool: params.forceCourseNameTool,
    sync_teacher_removal: params.syncTeacherRemoval,
    sync_student_removal: params.syncStudentRemoval,
    schedule_scan: params.scheduleScan,
    allow_multiple_course_connections: params.allowMultipleCourseConnections,
    auto_archive_courses_at: params.autoArchiveCoursesAt,
    auto_archive_connected_courses_at: params.autoArchiveConnectedCoursesAt,
  });
  return data;
};

export const validateRegEx = async (customerId, bodyData) => {
  const { data } = await api.post(
    `/customers/${customerId}/settings/validateregex`,
    {
      primary: bodyData.primary,
      secondary: bodyData.secondary,
      sample: bodyData.sample,
    },
  );
  return data;
};

export const updateCourseNameTool = async (customerId, field, tools) => {
  const { data } = await api.post(
    `/customers/${customerId}/settings/coursenametool`,
    {
      field: field,
      tools: tools,
    },
  );
  return data;
};

export const getCourseNameTool = async (customerId) => {
  const { data } = await api.get(
    `/customers/${customerId}/settings/coursenametool`,
  );
  return data;
};

export default {
  getSettings,

  addExcludedGroup,
  removeExcludedGroup,
  fetchExcludedGroups,
  addExcludeCondition,
  removeExcludeCondition,
  updateExcludeCondition,
  fetchExcludeConditions,

  addWhitelistedGroup,
  removeWhitelistedGroup,
  fetchWhitelistedGroups,
  addWhitelistCondition,
  updateWhitelistCondition,
  removeWhitelistCondition,
  fetchWhitelistConditions,

  updateSettings,
  validateRegEx,

  updateCourseNameTool,
  getCourseNameTool,
};
