<template>
  <div class="d-flex flex-column">
    <div>
      <Error
        :pageError="getCourseNameError"
        v-if="getCourseNameError"
        @on-dismiss="getCourseNameError = null"
        class="pa-0"
      ></Error>
    </div>

    <div :disabled="isDisabledCreateButton"></div>

    <!-- if admin has forced to choose the course name from setting  -->
    <div v-if="!allowMultipleCourseConnections && forceCourseNameTool">
      <div v-if="newCourse" class="d-flex text-subtitle-1">
        <span v-if="courseNameFromSetting">{{ courseNameFromSetting }}</span>
      </div>

      <div v-else class="pb-2 text-subtitle-1">
        <span
          v-if="courseNameFromSetting"
          v-t="'updates_course_name_to'"
        ></span>
        <span v-if="courseNameFromSetting" class="mr-1"></span>

        <span
          v-if="courseNameFromSetting"
          style="font-weight: 500 !important"
          >{{ courseNameFromSetting }}</span
        >
      </div>
    </div>

    <!-- if users are given an option to choose the course name  -->
    <div v-else class="text-subtitle-1">
      <label v-if="titleDisplay" class="font-weight-bold"
        ><span v-t="'set'" class="mr-1"></span>{{ $tc("course", 1)
        }}<span class="ml-1" v-t="'name'"></span
      ></label>
      <v-radio-group
        v-model="courseNameToolOption"
        :mandatory="true"
        hide-details
      >
        <!-- teacher chooses to get course name based on admin setting  -->
        <div class="d-flex">
          <v-radio
            color="primary"
            value="adminSetting"
            class="mb-4 d-flex align-center"
          >
            <template #label>
              <label
                >{{ $t("set_based_on_admin_settings") }}

                <TooltipButton
                  v-if="
                    courseNameToolOption == 'adminSetting' &&
                    !getCourseNameError
                  "
                  iColor="orange"
                  iconName="mdi-information"
                  :tooltipMsg="$t('name_from_admin_setting_warn')"
                />
              </label>
            </template>
          </v-radio>

          <v-text-field
            :disabled="courseNameToolOption != 'adminSetting'"
            v-model="courseNameFromSetting"
            v-if="courseNameFromSetting"
            :placeholder="$t('course', 1) + ' ' + $t('name')"
            variant="filled"
            density="compact"
            readonly
            class="ml-8"
          ></v-text-field>

          <span v-else :disabled="courseNameToolOption != 'adminSetting'">
            {{ courseNameFromSetting }}
          </span>
        </div>

        <!-- teacher chooses to specify custom course name -->
        <div class="d-flex">
          <v-radio
            :label="$t('set_custom_course_name')"
            color="primary"
            value="customCourseName"
            class="mb-3"
          ></v-radio>
          <v-text-field
            :disabled="courseNameToolOption != 'customCourseName'"
            v-model="customCourseName"
            :placeholder="$t('course', 1) + ' ' + $t('name') + '...'"
            clearable
            variant="filled"
            density="compact"
            class="ml-8"
          ></v-text-field>
        </div>

        <!-- teacher chooses to keep the course name as it is -->
        <div class="d-flex" v-if="existingCourseName">
          <v-radio
            :label="$t('set_same_as_existing')"
            color="primary"
            value="existingCourseName"
          ></v-radio>
          <v-text-field
            readonly
            class="ml-8"
            :model-value="existingCourseName"
            variant="filled"
            density="compact"
            :disabled="courseNameToolOption != 'existingCourseName'"
          >
          </v-text-field>
        </div>
      </v-radio-group>
    </div>
  </div>
</template>

<style scoped>
.text-subtitle-1 {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 400 !important;
}

.v-text-field :deep(.v-input__slot::before) {
  border-color: #5e9c96 !important;
}
</style>

<script setup>
import { ref, watch, computed, onMounted } from "vue";
import Error from "@/components/error.vue";
import coursesAPI from "@/api/courses";
import TooltipButton from "@/components/tooltipButton";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const store = useStore();

const props = defineProps({
  modelValue: {
    type: Object,
    default: null,
  },

  forceCourseNameTool: {
    type: Boolean,
  },

  allowMultipleCourseConnections: {
    type: Boolean,
    default: false,
  },

  dialog: {
    type: Boolean,
    default: false,
  },
  groupId: {
    type: String,
    default: null,
  },

  titleDisplay: {
    type: Boolean,
    default: true,
  },

  existingCourseName: {
    type: String,
    default: null,
  },

  newCourse: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue", "isDisabledCreateButtonfn"]);

const courseNameToolOption = ref("adminSetting");
const customCourseName = ref("");

const courseNameFromSetting = ref(null);
const getCourseNameError = ref(null);

const isDisabledCreateButton = computed(() => {
  if (!props.allowMultipleCourseConnections && props.forceCourseNameTool) {
    if (
      courseNameFromSetting.value == null ||
      courseNameFromSetting.value == "" ||
      !courseNameFromSetting.value.replace(/\s/g, "").length
    ) {
      emit("update:modelValue", null);
      emit("isDisabledCreateButtonfn", true);
      return true;
    }
  } else {
    if (
      courseNameToolOption.value == "adminSetting" &&
      (courseNameFromSetting.value == null ||
        courseNameFromSetting.value == "" ||
        !courseNameFromSetting.value.replace(/\s/g, "").length)
    ) {
      emit("update:modelValue", null);
      emit("isDisabledCreateButtonfn", true);
      return true;
    } else if (
      courseNameToolOption.value == "customCourseName" &&
      (customCourseName.value == null ||
        customCourseName.value == "" ||
        !customCourseName.value.replace(/\s/g, "").length)
    ) {
      emit("update:modelValue", null);
      emit("isDisabledCreateButtonfn", true);
      return true;
    } else if (
      courseNameToolOption.value == "existingCourseName" &&
      props.existingCourseName == null
    ) {
      emit("update:modelValue", null);
      emit("isDisabledCreateButtonfn", true);
      return true;
    }
  }

  if (!props.allowMultipleCourseConnections && props.forceCourseNameTool) {
    emit("update:modelValue", {
      courseName: courseNameFromSetting.value,
      nameFromAdminSetting: true,
    });
  } else if (courseNameToolOption.value == "adminSetting") {
    emit("update:modelValue", {
      courseName: courseNameFromSetting.value,
      nameFromAdminSetting: true,
    });
  } else if (courseNameToolOption.value == "customCourseName") {
    emit("update:modelValue", {
      courseName: customCourseName.value,
      nameFromAdminSetting: false,
    });
  } else if (courseNameToolOption.value == "existingCourseName") {
    emit("update:modelValue", {
      courseName: props.existingCourseName,
      nameFromAdminSetting: false,
    });
  } else {
    emit("update:modelValue", null);
  }

  emit("isDisabledCreateButtonfn", false);

  return false;
});

const handleUnauthorisedError = () => store.dispatch("handleUnauthorisedError");
const signOut = () => store.dispatch("signOut");

const getCourseNameFromSetting = async () => {
  if (props.groupId) {
    getCourseNameError.value = null;

    try {
      const response = await coursesAPI.getCourseName(
        "my_customer",
        props.groupId,
      );

      if (response.data.course_name) {
        courseNameFromSetting.value = response.data.course_name;
      } else {
        courseNameFromSetting.value = null;
        setCourseNameError();
      }
    } catch (error) {
      courseNameFromSetting.value = null;
      setPageError(error);
    }
  }
};

const setCourseNameError = async (error = null) => {
  if (!error) {
    error = t("error_getting_course_name_from_setting");
  }
  const isCourseNameFromSettingNull =
    courseNameFromSetting.value == null ||
    courseNameFromSetting.value == "" ||
    !courseNameFromSetting.value.replace(/\s/g, "").length;

  if (!props.allowMultipleCourseConnections && props.forceCourseNameTool) {
    if (isCourseNameFromSettingNull) {
      getCourseNameError.value = error;
    }
  } else if (courseNameToolOption.value == "adminSetting") {
    if (isCourseNameFromSettingNull) {
      getCourseNameError.value = error;
    }
  } else {
    getCourseNameError.value = null;
  }
};

const setPageError = async (error) => {
  if (error.response) {
    if (error.response.data.detail) {
      var detail = error.response.data.detail;
      if (detail === "unauthorized_client" || detail == "unknown_customer") {
        handleUnauthorisedError();
      } else if (
        detail === "transport_error" ||
        detail == "invalid_token" ||
        detail == "session_expired"
      ) {
        signOut();
      } else if (detail == "unknown_course_name_setting") {
        await setCourseNameError(t("unknown_course_name_setting"));
      } else {
        await setCourseNameError();
      }
      return;
    }
  }
  setCourseNameError();
};

onMounted(async () => {
  await getCourseNameFromSetting();
});

watch(courseNameToolOption, async () => {
  await setCourseNameError();
});

watch(
  () => props.dialog,
  async (val) => {
    if (val) {
      await getCourseNameFromSetting();
    } else {
      courseNameFromSetting.value = null;
      getCourseNameError.value = null;
      customCourseName.value = null;
    }
  },
);
</script>
