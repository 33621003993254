import * as types from "@/store/mutation-types";

/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

export const state = {
  initialized: false,
  signedIn: false,
  user: {
    idToken: null,
    profile: {},
  },
};

export const getters = {
  initialized: (state) => state.initialized,
  signedIn: (state) => state.signedIn,
  userId: (state) => state.user.profile.id,
  userEmail: (state) => state.user.profile.emailAddress,
  userName: (state) => state.user.profile.fullName,
  userImageUrl: (state) => state.user.profile.imageUrl,
  profile: (state) => state.user.profile,
  name: (state) => state.user.profile.fullName,
  idToken: (state) => state.user.idToken,
};

export const actions = {
  init({ commit, dispatch }) {
    const handleResponse = (response) => {
      const idToken = response.credential;
      try {
        const user = JSON.parse(window.atob(idToken.split(".")[1]));
        commit(types.GOOGLE_USER_CHANGED, { user, idToken: idToken });
      } catch (e) {
        return null;
      }
    };

    window.onload = async function () {
      window.google.accounts.id.initialize({
        client_id: process.env.VUE_APP_CLIENT_ID,
        auto_select: true,
        callback: handleResponse,
      });

      await dispatch("renderSignInButton");

      window.google.accounts.id.prompt();
      commit(types.GOOGLE_AUTH_INITIALIZED);
    };
  },

  renderSignInButton() {
    window.google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { text: "Sign in with Google", logo_alignment: "left" },
    );
  },

  async signOut({ commit, dispatch }) {
    await commit(types.SIGN_OUT);
    window.google.accounts.id.disableAutoSelect();
    await dispatch("renderSignInButton");
  },
};

export const mutations = {
  [types.GOOGLE_USER_CHANGED](state, { user, idToken }) {
    if (user) {
      state.signedIn = true;

      state.user = {
        idToken,
        profile: {
          id: user.sub,
          emailAddress: user.email,
          fullName: user.name,
          imageUrl: user.picture,
        },
      };
    } else {
      state.user = {
        idToken: null,
        profile: {},
      };
    }
  },
  [types.GOOGLE_AUTH_INITIALIZED](state) {
    state.initialized = true;
  },

  [types.SIGN_OUT](state) {
    state.initialized = false;
    state.user = {
      idToken: null,
      profile: {},
    };
    state.signedIn = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
