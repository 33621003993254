<template>
  <v-dialog
    v-model="addGroupDialog"
    max-width="750px"
    persistent
    content-class="elevation-2"
  >
    <v-card>
      <v-card-title class="text-dark-teal"
        ><span v-t="'add'" class="mr-1"> </span><span v-t="'group'"> </span
      ></v-card-title>

      <v-card-text>
        <v-autocomplete
          v-model="groupSelect"
          :loading="loading"
          :items="groupItems"
          v-model:search="searchGroup"
          :rules="groupKeyRules"
          item-title="email"
          variant="outlined"
          density="compact"
          :placeholder="$t('email') + '..'"
          :no-data-text="$t('no_data_available')"
          class="autocompcls mb-4"
          clearable
          return-object
          color="secondary"
          prepend-inner-icon="mdi-magnify"
          :hide-details="
            !(
              newGroupEmail != null &&
              newGroupEmail != '' &&
              this.filteredGroupsList.some(
                (group) => group.id === newGroupEmail.id,
              )
            )
          "
        />

        <v-autocomplete
          v-model="groupSelect"
          :loading="loading"
          :items="groupItems"
          v-model:search="searchGroupName"
          :rules="groupKeyRules"
          item-title="name"
          variant="outlined"
          density="compact"
          :placeholder="$t('name') + '..'"
          :no-data-text="$t('no_data_available')"
          class="autocompcls"
          clearable
          return-object
          color="secondary"
          prepend-inner-icon="mdi-magnify"
          :hide-details="
            !(
              newGroupEmail != null &&
              newGroupEmail != '' &&
              this.filteredGroupsList.some(
                (group) => group.id === newGroupEmail.id,
              )
            )
          "
        />
      </v-card-text>

      <!-- add group dialog actions -->
      <v-card-actions class="d-flex justify-end">
        <v-btn
          height="30px"
          elevation="0"
          variant="outlined"
          color="primary"
          class="rounded-md"
          @click="
            addGroupDialog = false;
            groupSelect = null;
            newGroupEmail = '';
          "
          ><span v-t="'cancel'"> </span
        ></v-btn>

        <v-btn
          height="30px"
          elevation="0"
          variant="flat"
          color="primary"
          class="rounded-md"
          @click="addGroupHandler()"
          :disabled="
            disabledAction ||
            newGroupEmail == '' ||
            newGroupEmail == null ||
            this.filteredGroupsList.some(
              (group) => group.id === newGroupEmail.id,
            )
          "
        >
          <span v-t="'add'"> </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.autocompcls {
  border-radius: 6px !important;
  border: 1px !important;
  background: white !important;
}
</style>

<script>
import groupsAPI from "@/api/groups";
import { debounce } from "lodash";

export default {
  props: {
    filteredGroupsList: {
      type: Array,
    },

    loadingSettings: {
      type: Boolean,
      default: false,
    },

    disabledAction: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["addGroupHandler"],

  data() {
    return {
      addGroupDialog: false,
      groupSelect: null,
      newGroupEmail: "",
      loading: false,
      groupItems: [],
      searchGroup: null,
      searchGroupName: null,
      groupKeyRules: [
        (v) => this.validateGroupExists(v) || this.$t("group_already_added"),
      ],
    };
  },

  methods: {
    async addGroupHandler() {
      await this.$emit("addGroupHandler", this.newGroupEmail);
      this.newGroupEmail = "";
      this.addGroupDialog = false;
      this.groupSelect = null;
    },

    validateGroupExists(v) {
      if (v) {
        if (this.filteredGroupsList.length !== 0) {
          if (this.filteredGroupsList.some((group) => group.id === v.id)) {
            return false;
          }
        }
      }
      return true;
    },

    async getGroups(val, searchby) {
      if (val) {
        this.loading = true;

        try {
          const response = await groupsAPI.get("my_customer", val, searchby);
          this.groupItems = response.data;
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      }
    },
  },

  watch: {
    groupSelect(val) {
      if (val) {
        this.newGroupEmail = val;
      } else {
        this.newGroupEmail = "";
      }
    },
    // autocomplete group by email while adding group
    searchGroup: debounce(async function (val) {
      if (this.loading) return;

      await this.getGroups(val, "email");
    }, 500),

    // autocomplete group by name while adding group
    searchGroupName: debounce(async function (val) {
      if (this.loading) return;

      var selectedItemField = this.groupSelect ? this.groupSelect.name : null;

      if (val && val.name !== selectedItemField) {
        await this.getGroups(val, "name");
      }
    }, 500),
  },
};
</script>
