import api from "../plugins/api";

export const get = async (customerId, nameOrEmail) => {
  const data = await api.get(`/customers/${customerId}/teachers`, {
    params: {
      name_or_email: nameOrEmail,
    },
  });
  return data;
};

export default {
  get,
};
