<template>
  <v-tooltip location="bottom" color="black" class="text-white">
    <template v-slot:activator="{ props }">
      <svg
        v-bind="props"
        :width="width"
        :height="height"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 22.97"
        style="border-color: white; outline: none"
      >
        <defs></defs>
        <g id="Warstwa_2" data-name="Warstwa 2">
          <g id="Warstwa_1-2" data-name="Warstwa 1">
            <line :style="style" y1="1" x2="30" y2="1" />
            <line :style="style" x1="19.26" y1="18.97" x2="29.98" y2="18.97" />
            <line :style="style" x1="8.52" y1="9.74" x2="29.97" y2="9.74" />
          </g>
        </g>
      </svg>
    </template>
    <span>{{ $t("sort_by") }}</span>
  </v-tooltip>
</template>

<style>
.cls-1 {
  fill: none;
  stroke: #010101;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
</style>

<script setup>
import { ref } from "vue";
const props = defineProps({
  color: {
    type: [String],
    default: "#5e9c96",
  },
  width: {
    type: [Number, String],
    default: 30,
  },
  height: {
    type: [Number, String],
    default: 30,
  },
});

const style = ref(
  `fill:none;stroke:${props.color};stroke-miterlimit:10;stroke-width:3px;`,
);
</script>
