<template>
  <v-container style="max-width: 80%" id="container">
    <div v-if="sessionActive && hasContacted">
      <SnackBar
        :text="snackbarText"
        :color="snackBarColor"
        v-if="snackbar"
        @on-dismiss="
          snackbar = false;
          snackbarText = '';
        "
      ></SnackBar>

      <Error
        :pageError="pageError"
        v-if="pageError"
        @on-dismiss="pageError = null"
      ></Error>

      <manage
        :title="$t('admins')"
        :list="admins"
        :groupsList="adminsGroups"
        :usersNextPageToken="adminsNextPageToken"
        :groupsNextPageToken="adminGroupsNextPageToken"
        :loadingItems="loadingItems"
        :loadingGroups="loadingGroups"
        @add-item="addAdminHandler"
        @add-group="addGroupHandler"
        @remove-item="removeAdmin"
        @remove-group="removeAdminGroup"
        @fetch-users="getAllAdmins"
        @fetch-groups="getAllAdminGroups"
        @get-searched-users="getSearchedAdmins"
        @get-searched-groups="getSearchedGroups"
        @get-user="getAdmin"
        @get-group="getGroup"
        @sort-users="sortAdmins"
        @sort-groups="sortAdminGroups"
      />
    </div>

    <Freemium
      v-else-if="sessionActive && !hasContacted"
      images="/manageusers.gif"
      :title="$t('manage_users_freemium_title')"
      :bodyText="$t('manage_users_freemium_text')"
    ></Freemium>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import roleAssignmentsAPI from "@/api/roleAssignments";

import Error from "@/components/error.vue";
import manage from "@/components/manage";
import SnackBar from "@/components/snackBar.vue";
import Freemium from "@/components/freemium.vue";

export default {
  components: {
    manage,
    Error,
    SnackBar,
    Freemium,
  },

  data() {
    return {
      admins: [],
      adminsGroups: [],

      adminsNextPageToken: null,
      adminGroupsNextPageToken: null,
      pageError: null,
      loadingItems: false,
      loadingGroups: false,

      snackbar: false,
      snackbarText: "",
      snackBarColor: "black",
    };
  },

  computed: {
    ...mapGetters({
      sessionActive: "sessionActive",
      hasContacted: "hasContacted",
    }),
  },

  middleware: "authenticated-access",

  methods: {
    ...mapActions({
      handleUnauthorisedError: "handleUnauthorisedError",
      signOut: "signOut",
    }),

    async getAllAdmins(sortUserBy) {
      this.loadingItems = true;
      try {
        const response = await roleAssignmentsAPI.getUsers(
          "my_customer",
          "Admin",
          this.adminsNextPageToken,
          sortUserBy,
        );
        this.pageError = null;
        const adminsResp = response.data.role_assignments;

        if (this.adminsNextPageToken == null) {
          this.admins = adminsResp;
        } else {
          this.admins = this.admins.concat(adminsResp);
        }

        this.adminsNextPageToken = response.data.next_page_token;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingItems = false;
      }
    },

    async getAllAdminGroups(sortGroupBy) {
      this.loadingGroups = true;
      try {
        const response = await roleAssignmentsAPI.getGroups(
          "my_customer",
          "Admin",
          this.adminGroupsNextPageToken,
          sortGroupBy,
        );
        this.pageError = null;
        const adminsGroupsResp = response.data.role_assignments;

        if (this.adminGroupsNextPageToken == null) {
          this.adminsGroups = adminsGroupsResp;
        } else {
          this.adminsGroups = this.adminsGroups.concat(adminsGroupsResp);
        }
        this.adminGroupsNextPageToken = response.data.next_page_token;
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingGroups = false;
      }
    },

    async addAdminHandler(userId, email, sortUserBy) {
      this.loadingItems = true;

      try {
        const response = await roleAssignmentsAPI.createUser(
          "my_customer",
          userId,
          email,
          "Admin",
        );
        if (response.status === 200) {
          this.pageError = null;
          this.adminsNextPageToken = null;
          await this.getAllAdmins(sortUserBy);
          this.snackbarText = this.$t("user_successfully_added");
          this.snackBarColor = "#4BB543";
          this.snackbar = true;
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingItems = false;
      }
    },

    async addGroupHandler(group_id, group_email, sortGroupBy) {
      this.loadingGroups = true;

      try {
        const response = await roleAssignmentsAPI.createGroup(
          "my_customer",
          group_id,
          group_email,
          "Admin",
        );
        if (response.status === 200) {
          this.pageError = null;
          this.adminGroupsNextPageToken = null;
          await this.getAllAdminGroups(sortGroupBy);
          this.snackbarText = this.$t("group_successfully_added");
          this.snackBarColor = "#4BB543";
          this.snackbar = true;
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingGroups = false;
      }
    },

    async removeAdmin(id, sortUserBy) {
      this.loadingItems = true;

      try {
        const response = await roleAssignmentsAPI.removeUser("my_customer", id);
        if (response.status === 200) {
          this.pageError = null;

          if (!this.adminsNextPageToken && this.admins.length <= 1) {
            this.adminsNextPageToken = null;
            await this.getAllAdmins(sortUserBy);
          } else {
            this.admins = this.admins.filter((item) => item.id !== id);
          }

          this.snackbarText = this.$t("admin_user_deleted");
          this.snackBarColor = "#4BB543";
          this.snackbar = true;
        }
      } catch (error) {
        var detail = await this.setPageError(error);
        if (detail == "admin_email_not_found") {
          this.pageError = "admin_email_not_found";

          if (!this.adminsNextPageToken && this.admins.length <= 1) {
            this.adminsNextPageToken = null;
            await this.getAllAdmins(sortUserBy);
          } else {
            this.admins = this.admins.filter((item) => item.id !== id);
          }
        }
      } finally {
        this.loadingItems = false;
      }
    },

    async removeAdminGroup(id, sortGroupBy) {
      this.loadingGroups = true;

      try {
        const response = await roleAssignmentsAPI.removeGroup(
          "my_customer",
          id,
        );

        if (response.status === 200) {
          this.pageError = null;

          if (!this.adminGroupsNextPageToken && this.adminsGroups.length <= 1) {
            this.adminGroupsNextPageToken = null;
            await this.getAllAdminGroups(sortGroupBy);
          } else {
            this.adminsGroups = this.adminsGroups.filter(
              (item) => item.id !== id,
            );
          }

          this.snackbarText = this.$t("admin_group_deleted");
          this.snackBarColor = "#4BB543";
          this.snackbar = true;
        }
      } catch (error) {
        var detail = await this.setPageError(error);
        if (detail == "admin_email_not_found") {
          this.pageError = "admin_email_not_found";

          if (!this.adminGroupsNextPageToken && this.adminsGroups.length <= 1) {
            this.adminGroupsNextPageToken = null;
            await this.getAllAdminGroups(sortGroupBy);
          } else {
            this.adminsGroups = this.adminsGroups.filter(
              (item) => item.id !== id,
            );
          }
        }
      } finally {
        this.loadingGroups = false;
      }
    },

    async getAdmin(userId) {
      this.loadingItems = true;
      try {
        const response = await roleAssignmentsAPI.getUser(
          "my_customer",
          userId,
          "Admin",
        );

        if (response.data != null) {
          this.adminsNextPageToken = null;
          this.admins = [
            {
              id: response.data.id,
              user_id: response.data.user_id,
              email: response.data.email,
              role: response.data.role,
            },
          ];
        } else {
          this.snackbarText = this.$t("admin_user_not_found");
          this.snackBarColor = "#D22B2B";
          this.snackbar = true;
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingItems = false;
      }
    },

    async getSearchedAdmins(sortUserBy) {
      this.adminsNextPageToken = null;
      await this.getAllAdmins(sortUserBy);
    },

    async getGroup(groupId) {
      this.loadingGroups = true;
      try {
        const response = await roleAssignmentsAPI.getGroup(
          "my_customer",
          groupId,
          "Admin",
        );

        if (response.data != null) {
          this.adminGroupsNextPageToken = null;
          this.adminsGroups = [
            {
              id: response.data.id,
              group_id: response.data.group_id,
              email: response.data.email,
              role: response.data.role,
            },
          ];
        } else {
          this.snackbarText = this.$t("admin_group_not_found");
          this.snackBarColor = "#D22B2B";
          this.snackbar = true;
        }
      } catch (error) {
        this.setPageError(error);
      } finally {
        this.loadingGroups = false;
      }
    },

    async getSearchedGroups(sortGroupBy) {
      this.adminGroupsNextPageToken = null;
      await this.getAllAdminGroups(sortGroupBy);
    },

    async sortAdmins(sortUserBy) {
      this.adminsNextPageToken = null;
      await this.getAllAdmins(sortUserBy);
    },

    async sortAdminGroups(sortGroupBy) {
      this.adminGroupsNextPageToken = null;
      await this.getAllAdminGroups(sortGroupBy);
    },

    setPageError(error) {
      if (error.response) {
        if (error.response.data.detail) {
          var detail = error.response.data.detail;
          if (
            detail === "unauthorized_client" ||
            detail == "unknown_customer"
          ) {
            this.pageError =
              "Error. You are not authorized to use this application. Application is not installed in gsuite marketplace for this customer. If the app is already installed, please make sure to grant access to the scopes. For more details follow the following steps- \n 1. From the Admin console Home page, go to Apps and then G Suite Marketplace apps. \n 2. Click the app's name-Online Partner Drive Admin Tool \n 3. Grant Access";
            this.handleUnauthorisedError();
          } else if (
            detail === "transport_error" ||
            detail == "invalid_token" ||
            detail == "session_expired"
          ) {
            this.signOut();
          } else if (detail == "email_not_found") {
            return "admin_email_not_found";
          } else {
            this.pageError = detail;
          }
          return;
        }
      }
      this.pageError = "action_failed_error";
    },
  },

  watch: {
    async sessionActive(current) {
      if (current && this.hasContacted) {
        await this.getAllAdmins("email");
        await this.getAllAdminGroups("email");
      }
    },
  },

  async created() {
    if (this.sessionActive && this.hasContacted) {
      await this.getAllAdmins("email");
      await this.getAllAdminGroups("email");
    }
  },
};
</script>
