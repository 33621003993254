<template>
  <!--  <v-container class="pa-0 d-flex flex-column align-self-center" style="margin:  0 100px 50px 100px">-->
  <v-container class="pa-0" style="max-width: 80%">
    <div class="d-flex justify-center">
      <span
        v-t="'how_can_we_help'"
        class="mt-12 font-size-30 font-weight-extra-bold text-dark-teal"
      ></span>
    </div>
    <v-row class="mt-16" style="margin-left: -100px">
      <v-col
        class="pa-0 wrap-col d-flex align-center flex-column"
        style="margin-left: 100px"
      >
        <span
          v-t="'what_is_class_manager'"
          class="text-h5 text-dark-teal"
        ></span>
        <div class="py-16 text-center text-h6 text-dark-gray">
          <span v-t="'cm_definition'"></span>
        </div>
      </v-col>

      <v-col
        v-if="$store.state.userRole"
        class="pa-0 wrap-col d-flex align-center flex-column"
        style="margin-left: 100px"
      >
        <span v-t="'how_does_it_work'" class="text-h5 text-dark-teal"></span>
        <div class="mt-16 d-flex justify-between flex-wrap tile-container">
          <v-btn
            v-for="(item, i) in items.filter((item) =>
              item.showFor.includes($store.state.userRole),
            )"
            :key="i"
            :to="item.to"
            elevation="0"
            class="d-flex flex-column text-wrap tile btn-font text-h6 text-dark-gray"
          >
            <span style="white-space: normal !important">{{
              $t(item.title)
            }}</span>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row class="ma-0 mt-16">
      <v-col cols="12" class="pa-0 d-flex align-center flex-column">
        <span v-t="'contact_us'" class="text-h5 text-dark-teal"></span>
        <p class="mt-5 text-h6 text-center text-dark-gray">
          <span v-t="'furthur_questions'"></span><br />
          <span v-t="'possible_help'"></span><br /><br />
          <span v-t="'our_email'"></span>:<br />
          <span class="text-h5" v-if="$store.state.supportContact">{{
            $store.state.supportContact
          }}</span>

          <span class="text-h5" v-else>support@onlinepartner.se</span>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  middleware: "authenticated-access",
  data() {
    return {
      items: [
        {
          title: "teacher",
          to: "/help/teacher",
          showFor: [
            "Teacher",
            "Support Substitute teacher",
            "Support educator",
            "Substitute teacher",
            "Admin",
            "Super admin",
          ],
        },
        {
          title: "support_educator",
          to: "/help/educator",
          showFor: [
            "Support Substitute teacher",
            "Support educator",
            "Admin",
            "Super admin",
          ],
        },
        {
          title: "substitute_teacher",
          to: "/help/substituteteacher",
          showFor: [
            "Support Substitute teacher",
            "Substitute teacher",
            "Admin",
            "Super admin",
          ],
        },
        {
          title: "admin",
          to: "/help/admin",
          showFor: ["Admin", "Super admin"],
        },
        {
          title: "orphaned_courses",
          to: "/help/orphanedcourses",
          showFor: ["Admin", "Super admin"],
        },
        {
          title: "manage",
          to: "/help/manage",
          showFor: ["Super admin"],
        },
        {
          title: "settings",
          to: "/help/settings",
          showFor: ["Super admin"],
        },
      ],
    };
  },
};
</script>

<style scoped>
.font-size-30 {
  font-size: 30px;
}
.tile-container > * {
  margin-left: 40px;
}
.tile-container :first-child {
  margin-left: 0px;
}

.tile {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 155px !important;
  background-color: rgba(196, 196, 196, 0.25) !important;
  border-radius: 5px;
  text-align: center;
}
.wrap-col {
  min-width: 500px;
}

.text-h5,
.text-h6 {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 400 !important;
}
</style>
